import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Usuarios } from 'app/interfaces/usuarios';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  localStorageUser = this.getUser();

  constructor(
      private http: HttpClient,
      private router: Router,
      private matSnackBar: MatSnackBar,
      private apiService: ApiService,
      private _matSnackBar: MatSnackBar,
  ) { }

    // check user token valido, bloqueado
        check(): any {
            const token = this.getToken();
            return this.getUserApi().then((data) => {
                if (data) {
                    if (token != null) {
                        if (data.success) {
                            if (data.data[0].StatusUsuario === 'B') {
                                return 'B';
                            }
                            if (data.data[0].TokenLogin === token) {
                                return true;
                            } else {
                                return 'logout';
                            }
                        } else {
                        return false;
                        }
                    } else {
                        return false;
                    }
                }
            })
            .catch(() => {
                return false;
            });
        }
    //
    // check user ao efetuar login
        checkLogin(EmailUsuario, SenhaUsuario): Promise<boolean> | any {
            const user = this.getUser();
            if (user) {
                return ((EmailUsuario === user['SenhaUsuario']) && (SenhaUsuario === user['SenhaUsuario'])) ;
            } else {
                return false;
            }
        }
    //
    // envia dados de login para api
        login(credentials: {EmailUsuario: string,  SenhaUsuario: string, Usuario: string, Senha: string}): Observable<boolean> | any {
            if(credentials.Usuario.length <= 10){

                return this.http.post<any>(`${environment.api_url}${environment.auth_enter}`, credentials, { headers: environment.api_headers })
                .toPromise()
                .then(async data => {
                    localStorage.clear();
                    if (data.success) {
                        if (data.data[0].hasOwnProperty('StatusPasswordExpired') && data.data[0].StatusPasswordExpired == 1) {
                            await this.matSnackBar.open('Senha Expirada!', '', {
                                duration: 5000,
                            });

                            this.redirectChangePassword({ CpfCnpj: data.data[0].CpfCnpj, EmailUsuario: data.data[0].EmailUsuario, DataNascimento: data.data[0].DataNascimento });
                        } else if (data.data[0].hasOwnProperty('PasswordInvalid') && data.data[0].PasswordInvalid == 1) {
                            await this.matSnackBar.open('Senha Inválida!', '', {
                                duration: 5000,
                            });

                            return data;
                        } else if (data.data[0].hasOwnProperty('Expire_Password') && data.data[0].Expire_Password != null) {
                            var d1 = new Date();
                            var d2 = new Date(data.data[0].Expire_Password);
                            
                            if (d1.getTime() >= d2.getTime()) {
                                await this.matSnackBar.open('Senha Expirada!', '', {
                                    duration: 5000,
                                });

                                this.redirectChangePassword({ CpfCnpj: data.data[0].CpfCnpj, EmailUsuario: data.data[0].EmailUsuario, DataNascimento: data.data[0].DataNascimento });
                            } else {
                                await this.salvarLocalStorage(data);
                                return true;
                            }
                        } else {
                            // await localStorage.setItem('Login', btoa('1'));
                            // await this.router.navigate(['/']);
                            await this.salvarLocalStorage(data);
                            return true;
                        }
                    } else {
                        this.matSnackBar.open('Usuário/Senha Inválidos!', '', {
                            duration: 5000,
                        });
                        return false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    return false;
                });

            }
            else {

                return this.http.post<any>(`${environment.api_url}${environment.auth_enter_Consorciado}`, credentials, { headers: environment.api_headers })
                .toPromise()
                .then(async data => {
                    await localStorage.clear();
                    if (data.success) {
                        await localStorage.setItem('token', btoa(data.data[0]['TokenLogin']));
                        await localStorage.setItem('user', btoa(JSON.stringify(data.data[0])));
                        this.localStorageUser = await this.getUser();
                        await this.carregaMenu();
                        return true;
                    } else if (data.code == "52ae4ce7-fb1b-4599-ac59-cc32f5f8924d") { // details = 'Senha Expirada!'
                        // caso a senha esteja expirada, o usuário é redirecionado para a tela change-password
                        this.redirectChangePasswordConsorciado(credentials.Usuario, credentials.Senha);
                    } else {
                        this.matSnackBar.open('CPF/CNPJ ou Senha Inválidos!', '', {
                            duration: 5000,
                        });
                        return false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    return false;
                });

            }
            
        }
    //

    // salvar dados no storage
        async salvarLocalStorage(data: any) {
            localStorage.setItem('token', btoa(data.data[0]['TokenLogin']));
            localStorage.setItem('user', btoa(JSON.stringify(data.data[0])));
            this.localStorageUser = this.getUser();
            await this.carregaMenu();
        }
    //

    // envia dados de logout para api e redireciona para o login
        logout(): Observable<boolean> | any {
            const lengthUsuario = this.getUser()['Usuario'].toString().length;
            const TipoAcesso = this.getUser()['TipoAcesso'];

            return this.http.put<any>(`${environment.api_url}${environment.auth_out}${this.getUser().IdUsuario}`, '',
            { headers: environment.api_headers })
            .toPromise()
            .then(async(data) => {
                if (data.success) {
                    if (lengthUsuario < 11) {
                        this.redirectLogin();
                    } else {
                        this.redirectLoginConsorciado();
                    }
                    return {logout: true, userAcess: TipoAcesso};
                } else {
                    return {logout: false};
                }
            })
            .catch((err) => {
                console.log(err);
                return {logout: false};
            });
        }
    //
    // recuperação de senha
        recovery(credentials: {EmailUsuario: string,  CpfCnpj: string, TipoUsuario: string}): Observable<boolean> | any {
            return this.http.post<any>(`${environment.api_url}${environment.auth_recovery}`, credentials, { headers: environment.api_headers })
            .toPromise()
            .then(data => {
                if (data.success) {
                    this.router.navigate(['/pages/auth/login']);
                    return true;
                } else if(data['code'] == 'fafdb315-31dc-4fbe-a1b0-3addacd7be00'){
                    this.matSnackBar.open('Este Usuário não possui um e-mail vinculado para que esta operação seja realizada!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '9833de5b-59f4-4153-801f-23423689d789'){
                    this.matSnackBar.open('Não foi encontrado nenhum usuário com o email informado!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '877c4b9ca-7615-44f0-a877-526c41bfb3c3'){
                    this.matSnackBar.open('Usuário não encontrado!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '69791006-4fda-4843-9a85-6347e4b99f53'){
                    this.matSnackBar.open('Não foi encontrado nenhum usuário com o CPF/CNPJ informado!', '', {
                        duration: 5000,
                    });
                    return false;
                } else {
                    this.matSnackBar.open('Não foi possível realizar o reset de sua senha tente novamente!', '', {
                        duration: 5000,
                    });
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
        }

        consultarSolicitacaoRecuperacao(parametros: {usuario: string,  sequencia: number, codigoAcesso: string, validaEmail: number, validadeDias: number}): Observable<boolean> | any {
            return this.http.post<any>(`${environment.api_url}${environment.auth_get_solicita_recuperar_senha}`, parametros, { headers: environment.api_headers })
            .toPromise()
            .then(data => {
                if (data.data[0].hasOwnProperty('Status_Recuperacao')) {
                    if (data.data[0].Status_Recuperacao == 'Renovada') {
                        this.matSnackBar.open("Solicitação de renovação de senha já utilizada! Para uma nova senha, inicie o processo de recuperação novamente.", '', {
                            duration: 10000,
                        });
                        this.router.navigate(['/login']);
                    } else if (data.data[0].Status_Recuperacao == 'Expirada') {
                        this.matSnackBar.open("Solicitação de renovação de senha já expirado! Para uma nova senha, inicie o processo de recuperação novamente.", '', {
                            duration: 10000,
                        });
                        this.router.navigate(['/login']);
                    } else {
                        return data.data[0];
                    }
                } else {
                    this.matSnackBar.open("Não foi possível identificar uma solicitação para a recuperação de senha!", '', {
                        duration: 10000,
                    });
                    this.router.navigate(['/login']);
                }

                return false;
            })
            .catch((err) => {
                this.matSnackBar.open("Não foi possível identificar uma solicitação para a recuperação de senha!", '', {
                    duration: 10000,
                });
                this.router.navigate(['/login']);
                
                console.log(err);
                return false;
            });
        }

        alterarSenha(
            parametros: {
                Usuario: string, 
                CodigoUsuario: string,  
                Sequencia: number, 
                CodigoAcesso: string, 
                Valida_Email_Recuperacao_Senha: number, 
                Dias_Expira_Senha: number,
                NovaSenha: string
            }
        ): Observable<boolean> | any {
            return this.http.post<any>(`${environment.api_url}${environment.auth_alterar_senha}`, parametros, { headers: environment.api_headers })
            .toPromise()
            .then(data => {
                return data;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
        }
        
        recoveryConsorciado(credentials: {EmailUsuario: string,  CpfCnpj: string, TipoUsuario: string}): Observable<boolean> | any {
            return this.http.post<any>(`${environment.api_url}${environment.auth_consorciado}`, credentials, { headers: environment.api_headers })
            .toPromise()
            .then(data => {
                if (data.success) {
                    this.router.navigate(['/pages/auth/login-consorciado']);
                    return true;
                } else if(data['code'] == 'fafdb315-31dc-4fbe-a1b0-3addacd7be00'){
                    this.matSnackBar.open('Este Usuário não possui um e-mail vinculado para que esta operação seja realizada!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '9833de5b-59f4-4153-801f-23423689d789'){
                    this.matSnackBar.open('Não foi encontrado nenhum usuário com o email informado!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '877c4b9ca-7615-44f0-a877-526c41bfb3c3'){
                    this.matSnackBar.open('Usuário não encontrado!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '69791006-4fda-4843-9a85-6347e4b99f53'){
                    this.matSnackBar.open('Não foi encontrado nenhum usuário com o CPF/CNPJ informado!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '84cad5ea-009e-4a97-8424-8a2290ad96c0'){
                    this.matSnackBar.open('Usuário não possui Data de Nascimento/Fundação Empresa. Entre em contato com o Administrador!', '', {
                        duration: 5000,
                    });
                    return false;
                } else if(data['code'] == '84370f6b-ba7c-4e14-adda-0a961707391d'){
                    this.matSnackBar.open(data['details'], '', {
                        duration: 5000,
                    });
                    return false;
                }else {
                    this.matSnackBar.open('Não foi possível realizar o reset de sua senha tente novamente!', '', {
                        duration: 5000,
                    });
                    return false;
                }
            })
            .catch((err) => {
                this.matSnackBar.open('Não foi possível realizar o reset da sua senha. Entre em contato com o Administrador!', '', {
                    duration: 5000,
                });
                console.log(err);
                return false;
            });
        }
    //

    // obtem o token armazenado no localstorage
        getToken(): any {
            let token;
            try {
                token = localStorage.getItem('token') != null ? atob(localStorage.getItem('token')) : false;
                environment.api_headers.TokenLogin = token ? token : "";
            } catch (e) {
                this.logout();
            }
            return token;
        }
    //
    // obtem o usuário armazenado no localstorage
        getUser(): Usuarios {
            let user;
            try {
                user = localStorage.getItem('user') != null ? JSON.parse(atob(localStorage.getItem('user'))) : false;
            } catch (e) {
                user = false;
            }
            return user;
        }
    //
    // obtem o usuário na api
        getUserApi(): Promise<any> {
            const user = localStorage.getItem('user') != null ? JSON.parse(atob(localStorage.getItem('user'))) : 0;
            let IdUsuario;
            if (user === 0) {
                return Promise.resolve(false);
            } else {
                IdUsuario = user.IdUsuario;
            }

            return this.http.get<any>(`${environment.api_url}${environment.usuarios_plataforma_get}${IdUsuario}`,
            { headers: environment.api_headers }).toPromise()
            .then((data) => {
                if (data.success) {
                    return data;
                } else {
                    return false;
                }
            })
            .catch(() => {
                return false;
            });
        }
    //
    // obtem o usuário na api
      getLocalStorageUser() {
        return this.localStorageUser;
      }
    //
    // obtem ip de quem está acessando
        getIp(): Promise<boolean> | any {
            return this.http.get('https://api.ipify.org?format=json')
            .toPromise()
            .then((resp) => {
                return resp;
            });
        }
    // 
    // verifica se usuario esta logado se não estiver joga para o login
        async userLogado(): Promise<any> {
            let user;
            let lengthUsuario;
            try {
                user = await this.getUser();
                lengthUsuario = await this.getUser()['Usuario'].toString().length;
            } catch (e) {
                return this.redirectLogin();
            }
            if (user) {
                await this.check().then( (data) => {
                if (data === 'B') {
                    this.matSnackBar.open('Seu acesso foi bloqueado pelo administrador!', '', {
                        duration: 5000,
                    });

                    if (lengthUsuario > 10) {
                        this.redirectLoginConsorciado();
                    } else {
                        this.redirectLogin();
                    }
                } else if (data === 'logout') {
                    this.logout();
                    return false;
                } else if (!data) {
                    this.matSnackBar.open('Não foi possível verificar o seu acesso efetue o login novamente!', '', {
                        duration: 5000,
                    });
                    return this.redirectLogin();
                }
            });
            } else  {
                return this.redirectLogin();
            }

            return true;
        }
    // 
    // verifica se o usuario possui privilegio para acessar uma pagina
        async userPrivilege(rota): Promise<any> {
            // let user;
            // try {
            //     user = await this.getUser();
            // } catch (e) {
            //     return this.redirectLogin();
            // }
            // if (user) {
            //     await this.getPrivilegeUserCurrent().then( (data) => {
            //         for (const newData of data) {
            //             if (newData.DescricaoPrivilegio.match('Permitir Visualizar') !== null) {
            //                 if (newData.Url === rota && newData.Permitido === 'S') {
            //                     return true;
            //                 }
            //             }
            //         }

            //         this.msg('Você não possui privilégios para acessar essa tela!', 5000);
            //         this.redirectHome();
            //         return false;
            //     });
            // } else  {
            //     return this.redirectLogin();
            // }

            return true;
        }
    // 
    // busca os privilegios do usuario logado
        getPrivilegeUserCurrent(): Promise<any>
        {
            const data = {
                IdUsuario: this.localStorageUser['IdUsuario']
            };

            return new Promise((resolve, reject) => {
                this.apiService.BuscarComFiltroPorEmpresa(`${environment.privileges_list}`, data, this.localStorageUser['IdEmpresa'])
                    .then((response: any) => {
                        if (response.success) {
                            resolve(response.data[0]);
                        }
                    }, reject);
            });
        }
    // 
    // Carrega os modulos do menu
        async carregaMenu(): Promise<any> {
            // verifica se o usuário está logado para poder carregar os módulos do munu
            let logado;
            await this.check()
            .then((data) => {
                if (data === true) {
                    logado = true;
                } else {
                    logado = false;
                }
            }).catch(() => {
                logado = false;
            });

            // Carrega Menu
            if (logado) {
                const user = this.getUser();
                const IdUsuario = user['IdUsuario'];
                const IdEmpresa = user['IdEmpresa'];
                const navAux: Array<any> = [];

                if(user['TipoAcesso'] === "CON"){
                    await this.apiService.BuscarTodosPorEmpresaEIdUsuario(`${environment.menu_plataforma_list_consorciado}`, IdEmpresa, IdUsuario)
                    .then((data) => {
                        let i = 0;
                        if (data.success === undefined) {
                            for(const dataMenu of data){
                                const children = [];

                                if (dataMenu.Tipo === 'group') {
                                    for (const newData of data) {
                                        if ((newData.Children === 'S') && (newData.IdModuloPai === dataMenu.IdModulo) && (newData.hasSubMenu != 'S')) {
                                            const rota = this.router.url.match('/apps/');
                                            
                                            children.push({
                                                id: newData.IdNomeModulo,
                                                title: newData.NomeModulo,
                                                type: newData.Tipo,
                                                icon: newData.Icone,
                                                url: newData.Url,
                                                badge: newData.Badge
                                            });
                                        }
                                    }
                                } else if (dataMenu.Tipo === 'collapsable') {
                                    for (const newData of data) {
                                        const rota = this.router.url.match('/apps/');

                                        // if (rota !== null) {
                                        //     if (rota['input'] !== '/apps/home') {
                                        //         this.userPrivilege(this.router.url);
                                        //     }
                                        // }
                                        
                                        if ((newData.Children === 'S') && (newData.IdModuloPai === dataMenu.IdModulo)) {
                                            children.push({
                                                id: newData.IdNomeModulo,
                                                title: newData.NomeModulo,
                                                type: newData.Tipo,
                                                icon: newData.Icone,
                                                url: newData.Url,
                                            });
                                        }
                                    }
                                }
                                if(!(dataMenu.IdModuloPai > 1)){
                                    navAux.push({
                                        id: dataMenu.IdNomeModulo,
                                        title: dataMenu.NomeModulo,
                                        type: dataMenu.Tipo,
                                        icon: dataMenu.Icone,
                                        children: children
                                    });
                                }
                            }

                            localStorage.removeItem('navigation');
                            localStorage.setItem('navigation', JSON.stringify(navAux));
                        } else {
                            localStorage.removeItem('navigation');
                            localStorage.setItem('navigation', JSON.stringify(navAux));
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    await this.apiService.BuscarTodosPorEmpresaEIdUsuario(`${environment.menu_plataforma_list}`, IdEmpresa, IdUsuario)
                    .then((data) => {
                        let i = 0;
                        if (data.success === undefined) {
                            for(const dataMenu of data){
                                const children = [];

                                if (dataMenu.Tipo === 'group') {
                                    for (const newData of data) {
                                        if ((newData.Children === 'S') && (newData.IdModuloPai === dataMenu.IdModulo) && (newData.hasSubMenu != 'S')) {
                                            if(user['TipoAcesso'] != "ADM"){
                                                if(newData.IdNomeModulo != 'usuarios'){
                                                    const rota = this.router.url.match('/apps/');
                                            
                                                    children.push({
                                                        id: newData.IdNomeModulo,
                                                        title: newData.NomeModulo,
                                                        type: newData.Tipo,
                                                        icon: newData.Icone,
                                                        url: newData.Url,
                                                        badge: newData.Badge
                                                    });
                                                }
                                            }
                                            else{
                                                const rota = this.router.url.match('/apps/');
                                            
                                                children.push({
                                                    id: newData.IdNomeModulo,
                                                    title: newData.NomeModulo,
                                                    type: newData.Tipo,
                                                    icon: newData.Icone,
                                                    url: newData.Url,
                                                    badge: newData.Badge
                                                });
                                            }
                                        }
                                    }
                                } else if (dataMenu.Tipo === 'collapsable') {
                                    for (const newData of data) {
                                        const rota = this.router.url.match('/apps/');                                        
                                        if ((newData.Children === 'S') && (newData.IdModuloPai === dataMenu.IdModulo)) {
                                            children.push({
                                                id: newData.IdNomeModulo,
                                                title: newData.NomeModulo,
                                                type: newData.Tipo,
                                                icon: newData.Icone,
                                                url: newData.Url,
                                            });
                                        }
                                    }
                                }
                                if(!(dataMenu.IdModuloPai > 1)){
                                    if(user['TipoAcesso'] != "ADM"){
                                        if(dataMenu.IdNomeModulo != "relatorios" && dataMenu.IdNomeModulo != "configuracoes"){
                                            navAux.push({
                                                id: dataMenu.IdNomeModulo,
                                                title: dataMenu.NomeModulo,
                                                type: dataMenu.Tipo,
                                                icon: dataMenu.Icone,
                                                children: children
                                            });
                                        }
                                    }
                                    else{
                                        navAux.push({
                                            id: dataMenu.IdNomeModulo,
                                            title: dataMenu.NomeModulo,
                                            type: dataMenu.Tipo,
                                            icon: dataMenu.Icone,
                                            children: children
                                        });
                                    }
                                }
                            }

                            localStorage.removeItem('navigation');
                            localStorage.setItem('navigation', JSON.stringify(navAux));
                        } else {
                            localStorage.removeItem('navigation');
                            localStorage.setItem('navigation', JSON.stringify(navAux));
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }
        }
    // 
    // limpa o localstorage e redireciona para o login
        redirectLogin(): boolean {
            localStorage.clear();
            this.router.navigate(['/pages/auth/login']);
            return false;
        }

        redirectLoginConsorciado(): boolean {
            localStorage.clear();
            location.replace('/pages/auth/login-consorciado');
            return false;
        }
    // 
    // se já estiver logado redireciona para a home
        redirectHome(): void {
            this.check()
            .then((data) => {
                if (data === true) {
                    this.router.navigate(['/apps/home']);
                }
            });
        }
    // 
    // se a senha estiver expirada, redireciona para change-password
        redirectChangePassword(recovery: {CpfCnpj: string,  EmailUsuario: string, DataNascimento: string}){
            this.router.navigateByUrl('/pages/auth/change-password', { state: { recovery } });
        }
        redirectChangePasswordConsorciado(usuario, senhaUsuario){
            this.router.navigateByUrl('/pages/auth/change-password-consorciado', { state: { Usuario: usuario,  SenhaUsuario: senhaUsuario} });
        }
    // 
    // mostra toast 
        msg(msg, temp): void {
            this._matSnackBar.open(msg, 'OK', {
                verticalPosition: 'bottom',
                duration        : temp,
                panelClass: ['style-success']
            });
        }
    // 
    // loginChatBot
    loginChatBot(credentials: {CpfCnpj: string,  Senha: string}): Observable<boolean> | any {
        return this.http.post<any>(`${environment.api_url}${environment.auth_chatbot_acesso_cpfcnpj}`, credentials, { headers: environment.api_headers })
        .toPromise()
        .then(async data => {
            return data;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
    }
    //
    // recoveryChatBot 
    recoveryChatBot(credentials: {CpfCnpj: string}): Observable<boolean> | any {
        return this.http.post<any>(`${environment.api_url}${environment.auth_chatbot_recovery}`, credentials, { headers: environment.api_headers })
        .toPromise()
        .then(async data => {
            return data;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
    }
    //
    // changePassword 
    changePassword(credentials: {SenhaAtual: string, NovaSenha: string}, idUsuario): Observable<boolean> | any {
        return this.http.post<any>(`${environment.api_url}${environment.auth_change_password}`, credentials, { headers: environment.api_headers })
        .toPromise()
        .then(async data => {
            return data;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
    }
    //
}
