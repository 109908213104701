<mat-card fxLayout="column" fxLayoutAlign="space-around start">
    <div class="content customHistoricoContent">
        <ul>
            <h1 class="sem-resultado" *ngIf="semResultado">Nenhum dado encontrado</h1>

            <div class="card-line customHistorico" *ngFor="let item of listHistorico"> 
                <li>
                    <div fxLayout="row"  fxLayoutAlign="start start">
                        <div [class]="localStorageUser.TipoAcesso != 'CON' ? 'personal-avatar-analista' : 'personal-avatar-consorciado'" fxLayout="column" fxFlex="20" *ngIf="item.TipoAcesso != null;else  default">
                            <img style="object-fit: cover;" [src]="item.UrlImagemUsuario" alt="avatar">
                        </div>
                        <ng-template #default >
                            <div [class]="localStorageUser.TipoAcesso != 'CON' ? 'personal-avatar-analista' : 'personal-avatar-consorciado'" fxLayout="column" fxFlex="20">
                                <img style="object-fit: cover;" src="/assets/images/logos/logo-padrao-quadrado.png" alt="avatar">
                            </div>
                        </ng-template>

                        <div fxLayout="column" fxFlex="80">
                            <ng-container *ngIf="localStorageUser.TipoAcesso == 'CON'">

                                <span *ngIf="item.TipoAcesso == 'Analista'" id="NomeUsuario">{{item.NomeUsuario}}</span>
                                <span *ngIf="item.TipoAcesso == 'Consorciado'" id="NomeUsuarioCon">{{item.NomeUsuario | lowercase}}</span>
                                <span *ngIf="item.TipoAcesso != 'Consorciado' && item.TipoAcesso != 'Analista'" id="NomeUsuario">{{item.NomeUsuario}}</span>

                            </ng-container>
                            <ng-container *ngIf="localStorageUser.TipoAcesso != 'CON'">

                                <span *ngIf="item.TipoAcesso != 'Consorciado' && item.TipoAcesso != 'Analista'" id="NomeUsuario">{{item.NomeUsuario}}</span>
                                <span *ngIf="item.TipoAcesso == 'Consorciado' || item.TipoAcesso == 'Analista'" id="NomeUsuarioCon">{{item.NomeUsuario | lowercase}}</span>

                            </ng-container>
                            <span id="TipoAcesso">{{item.TipoAcesso}}</span>
                            <div fxLayout="row" fxLayoutAlign="start start" >
                                <div fxLayout="column" fxFlex="100">
                                    <span id="DescricaoHistorico">{{item.DescricaoHistorico}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div fxLayout="row" [style]="localStorageUser.TipoAcesso != 'CON' ? 'margin-top: -35px;' : 'margin-top: -20px;'"  fxLayoutAlign="end end">
                            <h5>{{item.DataHistorico | date: 'dd/MM/yyyy'}}{{item.DataHistorico.substring(10,13)}}h{{item.DataHistorico | date: 'mm'}}m</h5>
                    </div>

                </li>
            </div>
        </ul>
    </div>
</mat-card>