<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/logo.png">
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">

            <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div>

            <div class="title">FAÇA LOGIN NA SUA CONTA</div>

            <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>CPF/CNPJ</mat-label>
                    <input matInput formControlName="Usuario" [minlength]="isCPF() ? 11 : 14" [mask]="getCpfCnpjMask()" 
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <mat-icon matSuffix class="secondary-text">payment</mat-icon>
                    <mat-error *ngIf="loginForm.controls.Usuario.hasError('required')">
                        CPF/CNPJ é obrigatório!
                    </mat-error>
                    <mat-error *ngIf="!loginForm.controls.Usuario.hasError('required')">
                        CPF/CNPJ inválido!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput (keyup)="limpaEspaco()" (focusout)="limpaEspaco()" [type]="type" formControlName="Senha">
                    <mat-icon matSuffix class="secondary-text"(click)="isVisibility()">{{visibility}}</mat-icon>
                    <mat-error *ngIf="loginForm.controls.Senha.hasError('required')">
                        Senha é obrigatória!
                    </mat-error>
                    <mat-error *ngIf="!loginForm.controls.Senha.hasError('required')">
                        Senha inválida!
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row"
                    fxLayoutAlign="space-between center">
                     <a class="forgot-password" [routerLink]="'/pages/auth/forgot-password-consorciado'">
                        Esqueceu a senha?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" 
                    [disabled]="loginForm.invalid || disabled">
                    {{ button }}
                </button>

            </form>
        </div>
    </div>
</div>
