import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth.service';
import { Subject } from 'rxjs';
import { CotasHistoricoService } from './cotasHistorico.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { ParametrosService } from '../../configuracoes/parametros/parametros.service';

@Component({
    selector     : 'cota-historico',
    templateUrl  : './cota-historico.component.html',
    styleUrls    : ['./cota-historico.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CotaHistoricoComponent implements OnInit, OnDestroy
{
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    @Input() CodigoCota : string;

    displayedColumns = ['checkbox', 'avatar', 'name', 'email', 'phone', 'jobTitle', 'buttons'];
    checkboxes: {};
    selectedContacts: any[];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    nomeParametros: any = '';

    semResultado : boolean = true;
    listHistorico: any = [];
    enableBtn: boolean = false;
    localStorageUser = this.authService.getUser();
    IdEmpresa = this.localStorageUser['IdEmpresa'];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CotasHistoricoService} _cotasHistoricoService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _cotasHistoricoService: CotasHistoricoService,
        private _parametrosService: ParametrosService,
        private authService: AuthService,
        public _matDialog: MatDialog,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    async ngOnInit() {

        await this._parametrosService.getParametros().then((result) => {
            this.nomeParametros = result[0].NomeInstitucional;
        }).catch((err) => {
            console.log(err);
        });
        if (this.localStorageUser.TipoAcesso == 'CON') {
            this._cotasHistoricoService.getCotasHistorico(this.CodigoCota).then((data) => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].TipoHistorico == 'Situacao') {
                            this.semResultado = false;
                            this.enableBtn = true;
                            var historico = {
                                NomeUsuario: data[i].TipoAcesso != 'CON' ? this.nomeParametros : data[i].NomeUsuario,
                                TipoAcesso: data[i].TipoAcesso == 'CON' ? 'Consorciado' : (data[i].TipoAcesso == 'USR' || data[i].TipoAcesso == 'ADM' ? 'Analista' : null),
                                DescricaoHistorico: data[i].DescricaoHistorico,
                                DataHistorico: data[i].DataHistorico,
                                UrlImagemUsuario: data[i].TipoAcesso != 'CON' ? '../../../../assets/images/logos/logo-padrao-quadrado.png' : (data[i].UrlImagemUsuario != '' ? data[i].UrlImagemUsuario : 'assets/images/avatars/profile.jpg'),
                            }
                            this.listHistorico.push(historico);
                        }
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            this._cotasHistoricoService.getCotasHistorico(this.CodigoCota)
            .then((data) => {
                if (data.length > 0) {
                    this.semResultado = false;
                    this.enableBtn = true;

                    for (var i = 0; i < data.length; i++) {
                        var historico = {
                            NomeUsuario: data[i].NomeUsuario,
                            TipoAcesso: data[i].TipoAcesso == 'CON' ? 'Consorciado' : (data[i].TipoAcesso == 'USR' || data[i].TipoAcesso == 'ADM'  ? 'Analista' : null),
                            DescricaoHistorico: data[i].DescricaoHistorico,
                            DataHistorico: data[i].DataHistorico,
                            UrlImagemUsuario: data[i].UrlImagemUsuario != '' && null ? data[i].UrlImagemUsuario : 'assets/images/avatars/profile.jpg',
                        }
                        this.listHistorico.push(historico);
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        }


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
