import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FuncoesService {

  constructor(private http: HttpClient) { }

  ipClient(): any {
    return this.http.get('https://api.ipify.org?format=json').subscribe(resp => {
      return resp;
    });
  }

  browserName(): any {
    const objAgent = navigator.userAgent;
    let objbrowserName = navigator.appName;
    let objOffsetName;
    let objOffsetVersion;

    if ((objOffsetVersion = objAgent.indexOf('Chrome')) !== -1) {
      objbrowserName = 'Chrome';
    } else if ((objOffsetVersion = objAgent.indexOf('MSIE')) !== -1) {
      objbrowserName = 'Microsoft Internet Explorer';
    } else if ((objOffsetVersion = objAgent.indexOf('Firefox')) !== -1) {
      objbrowserName = 'Firefox';
    } else if ((objOffsetVersion = objAgent.indexOf('Safari')) !== -1) {
      objbrowserName = 'Safari';
    } else if ( (objOffsetName = objAgent.lastIndexOf(' ') + 1) < (objOffsetVersion = objAgent.lastIndexOf('/')) ) {
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      if (objbrowserName.toLowerCase() === objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }

    return objbrowserName;
  }

  static isDateValid(DATE: FormControl){
    let stringDate = DATE.value.toString();
    var day = stringDate.substring(0, 2);
    var month = stringDate.substring(2, 4);
    var dateComplet = stringDate.substring(0, 8);

    var dateEstFull = dateComplet.split("/").join("");

    if (dateEstFull.length < 8 ||
        (day > 31 || day <= "00") ||
        (month > 12 || month <= "00") 
      ) {
        return ({DATE: false});
    }

}
  
  static validEspaco(DESCRICAO: FormControl) {
    if(DESCRICAO.value != "" && DESCRICAO.value != null) {
      let stringDescricao = DESCRICAO.value.trim();
  
      if(stringDescricao == "" || stringDescricao == null){
        return ({DESCRICAO: false});
      }
    }
    
  }

  static ValidaCPFCNPJ(CPFCNPJ: FormControl) {
    if(CPFCNPJ.value == null){
      return;
    }
    
    var Soma;
    var Resto;
    let stringCPFCNPJ = CPFCNPJ.value.toString();
    
    Soma = 0;

    if (stringCPFCNPJ.length == 0) {
      return null;
    } else if (stringCPFCNPJ.length <= 11 ){
      //Verificacao do CPF 
      if (stringCPFCNPJ == "00000000000" ||
          stringCPFCNPJ == "11111111111" ||
          stringCPFCNPJ == "22222222222" ||
          stringCPFCNPJ == "33333333333" ||
          stringCPFCNPJ == "44444444444" ||
          stringCPFCNPJ == "55555555555" ||
          stringCPFCNPJ == "66666666666" ||
          stringCPFCNPJ == "77777777777" ||
          stringCPFCNPJ == "88888888888" ||
          stringCPFCNPJ == "99999999999" ) {
          return ({CPFCNPJ: false});
      }
      // Valida 1o digito  
      let add = 0, rev;  
      for (let i=1; i<=9; i++) Soma = Soma + parseInt(stringCPFCNPJ.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(stringCPFCNPJ.substring(9, 10)) ) 
          return ({CPFCNPJ: false});
      Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(stringCPFCNPJ.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(stringCPFCNPJ.substring(10, 11) ) ) 
          return ({CPFCNPJ: false});
      return null;
    }
    else{
      //Verificacao do CNPJ 
      // Elimina CNPJs invalidos conhecidos
      if (stringCPFCNPJ == "00000000000000" || 
          stringCPFCNPJ == "11111111111111" || 
          stringCPFCNPJ == "22222222222222" || 
          stringCPFCNPJ == "33333333333333" || 
          stringCPFCNPJ == "44444444444444" || 
          stringCPFCNPJ == "55555555555555" || 
          stringCPFCNPJ == "66666666666666" || 
          stringCPFCNPJ == "77777777777777" || 
          stringCPFCNPJ == "88888888888888" || 
          stringCPFCNPJ == "99999999999999"){
          return ({CPFCNPJ: false});
      }

      // Valida DVs
      let tamanho = stringCPFCNPJ.length - 2;
      let numeros = stringCPFCNPJ.substring(0,tamanho);
      let digitos = stringCPFCNPJ.substring(tamanho);
      let soma = 0, resultado;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)){
        return ({CPFCNPJ: false});
      }
      tamanho = tamanho + 1;
      numeros = stringCPFCNPJ.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)){
        return ({CPFCNPJ: false});
      }
      return null;
      }
  }

  formatDataBD(data): any {
    if (data.indexOf('-') === -1) {
      const dia = data.slice(0, 2);
      const mes = data.slice(2, 4);
      const ano = data.slice(4, 8);
      return ano + '-' + mes + '-' + dia;
    }
    return data;
  }

  formataHora(hora, minuto, segundo): any {
    if (hora < 10){
      hora = 0 + hora.toString();
    }
    if (minuto < 10)
    {
      minuto = 0 + minuto.toString();
    }
    if (segundo < 10)
    {
     segundo = 0 + segundo.toString();
    }
    return hora + ':' + minuto + ':' + segundo;
  }

  formataMinSec(minuto, segundo): any {
    if (minuto <  10){
      minuto = 0 + minuto.toString();
    }
    if (segundo < 10 )
    {
      segundo = 0 + segundo.toString();
    }
    return minuto + ':' + segundo;
  }

  slugify(str) {
  
    // Converte o texto para caixa baixa:
    str = str.toLowerCase();
    
    // Remove qualquer caractere em branco do final do texto:
    str = str.replace(/^\s+|\s+$/g, '');
  
    // Lista de caracteres especiais que serão substituídos:
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    
    // Lista de caracteres que serão adicionados em relação aos anteriores:
    const to   = "aaaaaeeeeeiiiiooooouuuunc------";
    
    // Substitui todos os caracteres especiais:
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
  
    // Remove qualquer caractere inválido que possa ter sobrado no texto:
    str = str.replace(/[^a-z0-9 -]/g, '');
    
    // Substitui os espaços em branco por hífen:
    str = str.replace(/\s+/g, '-');
  
    return str;
  };

  // Validação da data
  static isValidDate(DATA: FormControl) {
      let stringData = DATA.value.toString();
      const dia = stringData.substring(0, 2);
      const mes = stringData.substring(2, 4);
      const ano = stringData.substring(4, 8 );
      const dataComp = stringData.substring(0,8);
      
  
      var partesDataEstFull = dataComp.split("/").join("");

      if (partesDataEstFull.length < 8 ||
          (dia > 31 || dia <= "00") || 
          (mes > 12 || mes <= "00") ||
          (ano == "0000")
          ) {
          return ({DATA: false});
      }

  }

  static isValidDate2(DATA: FormControl) {
    if(DATA.value != null) {
      let stringData = DATA.value.toString();
      const dia = stringData.substring(0, 2);
      const mes = stringData.substring(2, 4);
      const ano = stringData.substring(4, 8 );
      const dataComp = stringData.substring(0,8);
      
      var partesDataEstFull = dataComp.split("/").join("");
  
      if (partesDataEstFull.length < 8 ||
          (dia > 31 || dia <= "00") || 
          (mes > 12 || mes <= "00") ||
          (ano == "0000")
          ) {
          return ({DATA: false});
      }
    }
  }

  static phoneValidation(formControl: FormControl){

    if(!formControl.value){
      return;
    }

    let stringCel: string = formControl.value.toString();
    stringCel = stringCel.replace(/[^0-9]/g, '');
    
    let prefix = parseInt(stringCel.substring(0,2))

    //Verifica prifixos inválidos 
    if(
        prefix < 11  || prefix > 99  || prefix == 20 || prefix == 23 || prefix == 25 ||
        prefix == 26 || prefix == 26 || prefix == 29 || prefix == 30 || prefix == 36 ||
        prefix == 39 || prefix == 40 || prefix == 50 || prefix == 52 || prefix == 55 ||
        prefix == 56 || prefix == 57 || prefix == 58 || prefix == 59 || prefix == 60 ||
        prefix == 70 || prefix == 72 || prefix == 76 || prefix == 78 || prefix == 80 ||
        prefix == 90
        ){

            //--- Prefixo inválido
            return {phoneValidation: false}

        }else if (stringCel.length == 11 && (parseInt(stringCel.substring(2,3)) == 9 && parseInt(stringCel.substring(3,4)) > 5)) {

          let phoneNumber = stringCel.substring(stringCel.length - 9)

            if(
                phoneNumber == '000000000' ||
                phoneNumber == '900000000' ||
                phoneNumber == '111111111' ||
                phoneNumber == '911111111' ||
                phoneNumber == '222222222' ||
                phoneNumber == '922222222' ||
                phoneNumber == '333333333' ||
                phoneNumber == '933333333' ||
                phoneNumber == '444444444' ||
                phoneNumber == '944444444' ||
                phoneNumber == '555555555' ||
                phoneNumber == '955555555' ||
                phoneNumber == '666666666' ||
                phoneNumber == '966666666' ||
                phoneNumber == '777777777' ||
                phoneNumber == '888888888' ||
                phoneNumber == '999999999' 
                ){
                
                //--- Celular inválido
                return {phoneValidation: false}
                
            }else {
              
                //--- Celular válido
                return null
            }
            
        }else if(stringCel.length == 10){

            let phoneNumber = stringCel.substring(stringCel.length - 8)

            if(
              phoneNumber == '00000000' ||
              phoneNumber == '11111111' ||
              phoneNumber == '22222222' ||
              phoneNumber == '33333333' ||
              phoneNumber == '44444444' ||
              phoneNumber == '55555555' ||
              phoneNumber == '66666666' ||
              phoneNumber == '77777777' ||
              phoneNumber == '88888888' ||
              phoneNumber == '99999999' 
              ){
            
            //--- Telefone-Fixo inválido
            return {phoneValidation: false}
            
        }else {
          
            //--- Telefone-Fixo válido
            return null
        }

        }else{
            //--- telefone inválido
            return {phoneValidation: false}
        }
    
  }

  

}//
