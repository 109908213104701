<div class="body-custom">
    <div id="login" fxLayout="column">
        <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
            <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

                <span id="custom-portal">Formulário do Fiador</span>
                <div style="height: 20px;"></div>
                <div class="load" *ngIf="!loading">
                    <mat-spinner></mat-spinner>
                </div>

                <div *ngIf="loading">

                    <div *ngIf="formValid">

                        <mat-tab-group mat-stretch-tabs animationDuration="0ms">

                            <!-- ############################################################ -->
                            <!-- ###################### IDENTIFICAÇÃO ####################### -->
                            <!-- ############################################################ -->
                            <mat-tab label="Identificação" class="tabHeader">
                                <div class="form">
                                    <form class='identificacao' [formGroup]="form">

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayout.md="row wap" fxLayout.xl="row" fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="100" fxFlex.xl="50">
                                                <mat-label>Nome</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Nome" formControlName="NomePessoa" matInput
                                                    required>
                                                <mat-error>Nome é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex.xl="25">
                                                <mat-label>Data de Nascimento</mat-label>

                                                <input matInput required formControlName="DataNascimento" type="text"
                                                    mask="00/00/0000" maxlength="10" name="dataNascimento"
                                                    autocomplete="off" (change)="pickDate($event.target.value,'nscto')"
                                                    disabled />

                                                <input type="hidden" [matDatepicker]="pickerIdentiy"
                                                    name="dataNascimentoHidden"
                                                    (dateChange)="pickDate($event.targetElement.value,'nscto')" />

                                                <mat-datepicker-toggle matSuffix [for]="pickerIdentiy">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerIdentiy></mat-datepicker>
                                                <mat-error>Data de Nascimento é obrigatório!</mat-error>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex.xl="25">
                                                <mat-label>CPF</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CPF" formControlName="Cpf" matInput [mask]="maskCpf"
                                                    required>
                                                <mat-error>CPF é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout.md="row wap"
                                            fxLayout.xl="row" fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex.xl="25">
                                                <mat-label>RG</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="20" name="RG" formControlName="RG" matInput required>
                                                <mat-error>RG é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex.xl="20">
                                                <mat-label>Órgão Emissor</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="20" name="Orgao" formControlName="OrgaoEmissor"
                                                    matInput required>
                                                <mat-error>Orgão Emissor é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex.xl="25">
                                                <mat-label>Estado Civil</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="EstadoCivil" formControlName="EstadoCivil"
                                                    (selectionChange)="verifyEstadoCivil($event.value)" matNativeControl
                                                    required>
                                                    <mat-option value="S">Solteiro(a)</mat-option>
                                                    <mat-option value="C">Casado(a)</mat-option>
                                                    <mat-option value="E">União Estável</mat-option>
                                                    <mat-option value="D">Divorciado(a)</mat-option>
                                                    <mat-option value="V">Viúvo(a)</mat-option>
                                                    <mat-option value="O">Outro</mat-option>
                                                </mat-select>
                                                <mat-error>Estado Civil é obrigatório! </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex.xl="25">
                                                <mat-label>Sexo</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="Sexo" formControlName="Sexo" matNativeControl
                                                    required>
                                                    <mat-option value="M">Masculino</mat-option>
                                                    <mat-option value="F">Feminino</mat-option>
                                                </mat-select>
                                                <mat-error>Sexo é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout.md="row wap"
                                            fxLayout.xl="row" fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex="50">
                                                <mat-label>Nacionalidade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="40" name="Nacionalidade"
                                                    formControlName="Nacionalidade" matInput required>
                                                <mat-error>Nacionalidade é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex="50">
                                                <mat-label>Naturalidade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="40" name="Naturalidade" formControlName="Naturalidade"
                                                    matInput required>
                                                <mat-error>Naturalidade é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout.md="row wap"
                                            fxLayout.xl="row" fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex="50">
                                                <mat-label>Nome do Pai</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Pai" formControlName="NomePai" matInput
                                                    required>
                                                <mat-error>Nome do Pai é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="48" fxFlex="50">
                                                <mat-label>Nome da Mãe</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Mae" formControlName="NomeMae" matInput
                                                    required>
                                                <mat-error>Nome da Mãe é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>
                                        <!-- ########################################################## -->
                                        <!-- ####################### DEPENDENTES ####################### -->
                                        <!-- ########################################################## -->
                                        <div class="" fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout.md="row wap"
                                            fxLayout.lg="row wap" fxLayout.xl="row "
                                            fxLayoutAlign="space-between center" fxLayoutGap="20px">

                                            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="65" fxLayout="column">
                                                <h3 class="depList ">Dependentes:</h3>

                                                <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxShow
                                                    fxHide.xs="true" fxHide.sm="true" fxFlex="100">
                                                    <mat-table #table [dataSource]="dataSourceDependentes"
                                                        style="width: 100%;" fxFlex="100">

                                                        <!--Id Column-->
                                                        <ng-container matColumnDef="id">
                                                            <mat-header-cell *matHeaderCellDef fxFlex="20"
                                                                fxFlex.md="20">
                                                                Id </mat-header-cell>
                                                            <mat-cell *matCellDef="let element"
                                                                [matTooltip]=element.IdDependentes fxFlex="20"
                                                                fxFlex.md="20">
                                                                <p class="text-truncate font-weight-600">
                                                                    {{element.IdDependentes}} </p>
                                                            </mat-cell>
                                                        </ng-container>

                                                        <!--Nome Column-->
                                                        <ng-container matColumnDef="nome">
                                                            <mat-header-cell *matHeaderCellDef fxFlex="60"
                                                                fxFlex.md="40">
                                                                Nome </mat-header-cell>
                                                            <mat-cell *matCellDef="let element"
                                                                [matTooltip]=element.Nome fxFlex="60" fxFlex.md="40">
                                                                <p class="text-truncate font-weight-600">
                                                                    {{element.Nome}}
                                                                </p>
                                                            </mat-cell>
                                                        </ng-container>

                                                        <!--Remove Column-->
                                                        <ng-container matColumnDef="remove">
                                                            <mat-header-cell *matHeaderCellDef fxFlex="20"
                                                                fxFlex.md="40" fxLayoutAlign="center center"> Opções
                                                            </mat-header-cell>
                                                            <mat-cell *matCellDef="let element"
                                                                fxLayoutAlign="center center" fxFlex="20"
                                                                fxFlex.md="40">

                                                                <button mat-button matTooltip="Atualizar" form="none"
                                                                    (click)="createModal('dependentes','update', element)"
                                                                    color="primary">
                                                                    <mat-icon>edit</mat-icon>
                                                                </button>

                                                                <button mat-button matTooltip="Excluir" form="none"
                                                                    (click)="createModal('dependentes','delete', element)"
                                                                    color="primary">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>

                                                            </mat-cell>

                                                        </ng-container>

                                                        <mat-header-row *matHeaderRowDef="displayedColumnsDependentes">
                                                        </mat-header-row>

                                                        <mat-row
                                                            *matRowDef="let element; columns: displayedColumnsDependentes;"
                                                            class="element">
                                                        </mat-row>

                                                    </mat-table>
                                                </div>
                                                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                                    fxShow.xs="true" fxShow.sm="true" fxHide="true"
                                                    fxLayoutAlign="center start">
                                                    <!-- CARDS -->
                                                    <mat-card class="card-custom-r"
                                                        *ngFor="let element of dataSourceDependentes" fxFlex.xs="95"
                                                        fxFlex.sm="95">
                                                        <mat-card-content>
                                                            <p class="text-card-r"><span>ID:</span>
                                                                {{element.IdDependentes}} </p>
                                                            <p class="text-card-r"><span>Nome:</span> {{element.Nome}}
                                                            </p>
                                                            <div fxLayout="row" fxLayoutAlign="end end">
                                                                <button mat-button matTooltip="Atualizar" form="none"
                                                                    (click)="createModal('dependentes','update', element)"
                                                                    color="primary">
                                                                    <mat-icon>edit</mat-icon>
                                                                </button>
                                                                <button mat-button matTooltip="Excluir" form="none"
                                                                    (click)="createModal('dependentes','delete', element)"
                                                                    color="primary">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>
                                                            </div>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>

                                                <div class="linha" fxLayout="row" fxLayoutAlign.xl="end end"
                                                    fxLayoutAlign="center start" fxFlex="100">
                                                    <button fxFlex.xs="100" fxFlex.sm="100" class="addItem"
                                                        mat-raised-button
                                                        (click)="createModal('dependentes','create', IdPessoaFisica)"
                                                        color="primary" style="margin:10px" form="none">
                                                        Adicionar Novo
                                                    </button>
                                                </div>

                                            </div>

                                            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="25" fxLayout="row"
                                                fxLayoutAlign="end start">

                                                <div class="modal-info">
                                                    <button type="button" (click)="infoBiometria()" mat-icon-button
                                                        color="primary" class="btnInfoCampoBiometria"
                                                        matTooltip="Clique aqui para obter mais informações dos campos: Biometria Facial"
                                                        matTooltipPosition="above">
                                                        <mat-icon>info</mat-icon>
                                                    </button>
                                                </div>

                                                <mat-card class="mr-8">
                                                    <!-- <div class="feedback-image">
                                                        <span class="material-symbols-outlined"  matTooltipPosition="right" [matTooltip]="feedback" >
                                                            feedback
                                                        </span>
                                                    </div> -->
                                                    <div class="foto-avalista" fxLayout="column">
                                                        <label class="fotoDesc" for="myInputFile"
                                                            (click)="fotoMensagem()">
                                                            <img [src]="UrlImagem"
                                                                class="avatar contact-avatar huge m-0 pointer"
                                                                style="object-fit: cover;">
                                                            <label class="icon-updete-photo pointer" for="myInputFile">
                                                                Alterar Foto<mat-icon style="margin-left: 3px;">
                                                                    camera_alt
                                                                </mat-icon></label>
                                                            <input type="file" name="myInputFile" id="myInputFile"
                                                                (change)="fileProgress($event)" style="display:none;"
                                                                accept=".png, .jpg, .jpeg"
                                                                [disabled]="form.controls.Cpf.valid == false" />
                                                        </label>
                                                        <mat-error
                                                            *ngIf="mensagem && form.controls.Cpf.touched == false">Cpf é
                                                            obrigatório!</mat-error>
                                                        <mat-error
                                                            *ngIf="form.controls.Cpf.valid == false && form.controls.Cpf.touched == true">
                                                            Cpf inválido!</mat-error>
                                                        <mat-error>{{erroDV}}</mat-error>
                                                    </div>
                                                </mat-card>
                                                <!-- <div fxFlex>
                                                        
                                                </div> -->

                                            </div>


                                        </div>



                                    </form>
                                </div>
                            </mat-tab>

                            <!-- ############################################################# -->
                            <!-- ##########################  ENDEREÇO  ####################### -->
                            <!-- ############################################################# -->

                            <mat-tab label="Endereço" class="tabHeader">

                                <div class="form">


                                    <form class="endereco" [formGroup]="form">

                                        <h3>Endereço Principal</h3>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex="70" fxFlex.xs="100">
                                                <mat-label>Endereço</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Endereco" matInput
                                                    formControlName="Endereco" required>
                                                <mat-error>Endereço é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex="30">
                                                <mat-label>Número</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="10" name="Numero" matInput formControlName="Numero"
                                                    required>
                                                <mat-error>Número é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Bairro</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Bairro" formControlName="Bairro" matInput
                                                    required>
                                                <mat-error>Bairro é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Complemento</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Complemento" formControlName="Complemento"
                                                    matInput>
                                                <mat-error>Complemento é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayoutAlign="start start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="30">
                                                <mat-label>CEP</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CEP" [mask]="maskCEP" formControlName="Cep"
                                                    (blur)="changeCEP(form.controls.Cep.value, 'endereco')" matInput
                                                    required>
                                                <mat-error *ngIf="form.controls.Cep.hasError('required')">
                                                    CEP é obrigatório!
                                                </mat-error>
                                                <mat-error *ngIf="!form.controls.Cep.hasError('required')">
                                                    CEP é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="40">
                                                <mat-label>Cidade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="20" disabled="true" name="Cidade"
                                                    formControlName="Cidade" matInput required>
                                                <mat-error>Cidade é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="30">
                                                <mat-label>UF</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="UF" formControlName="UF" disabled="true"
                                                    matNativeControl required>
                                                    <mat-option *ngFor="let item of estados" value={{item}}>{{item}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error>UF é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>
                                        <h3>Residência Atual</h3>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayoutAlign="start start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Posse:</mat-label>
                                                <mat-select formControlName="ResidenciaAtual"
                                                    (selectionChange)="setValidatorsResidenciaAtual(form.controls.ResidenciaAtual.value)"
                                                    required>
                                                    <mat-option value="P">Própria</mat-option>
                                                    <mat-option value="A">Alugada</mat-option>
                                                    <mat-option value="F">Financiada</mat-option>
                                                    <mat-option value="MP">Mora com os pais</mat-option>
                                                    <mat-option value="O">Outro</mat-option>
                                                </mat-select>
                                                <mat-error>Posse é obrigatório!</mat-error>

                                            </mat-form-field>

                                            <mat-form-field *ngIf="form.controls.ResidenciaAtual.value == 'A' ||
                                                            form.controls.ResidenciaAtual.value == 'F'"
                                                appearance="outline" fxFlex.xs="100" fxFlex.sm="100" fxFlex="50">
                                                <mat-label>Valor do Aluguel/Prestação</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="Aluguel" matInput formControlName="ValorAluguel" required
                                                    currencyMask autocomplete="off" min="0.01"
                                                    [options]="{ align: 'letft', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                    autocomplete="off">
                                                <mat-error>Valor do Aluguel/Prestação é obrigatório!</mat-error>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Tempo de Residência (em anos)</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="10" min="0" name="TempoResidencia"
                                                    formControlName="TempoResidencia" matInput
                                                    (change)="setValidatorsEndereco(form.controls.TempoResidencia.value)"
                                                    required>
                                                <mat-error>Tempo de Residência é obrigatório!</mat-error>

                                            </mat-form-field>

                                        </div>

                                        <div class="Adressdesc" fxLayout="row" fxLayout.xs="row wap"
                                            fxLayoutAlign="start start">

                                            <h3>Endereço Complementar</h3>

                                            <mat-icon class="help"
                                                matTooltip="Obrigatório! quando os Correios não realizam entrega no 
                                                                        endereço acima, ou for Zona Rural ou Caixa Postal">
                                                error_outline
                                            </mat-icon>

                                        </div>


                                        <div fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout.xl="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="60">
                                                <mat-label>Endereço</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Endereco"
                                                    formControlName="EnderecoComplementar" matInput>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="40">
                                                <mat-label>Número</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="10" name="Numero" formControlName="NumeroComplementar"
                                                    matInput>
                                                <mat-error
                                                    *ngIf="form.controls.NumeroComplementar.hasError('required')">
                                                    Número é obrigatório!</mat-error>
                                                <mat-error
                                                    *ngIf="!form.controls.NumeroComplementar.hasError('required')">
                                                    Número inválido!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Bairro</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Bairro" formControlName="BairroComplementar"
                                                    matInput>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Complemento</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Complemento"
                                                    formControlName="ComplementoComplementar" matInput>

                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayoutAlign="start start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="30">
                                                <mat-label>CEP</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CEP2" [mask]="maskCEP" formControlName="CepComplementar"
                                                    (blur)="changeCEP(form.controls.CepComplementar.value, 'complementar')"
                                                    matInput>
                                                <mat-error *ngIf="form.controls.CepComplementar.hasError('required')">
                                                    CEP é obrigatório!
                                                </mat-error>
                                                <mat-error *ngIf="!form.controls.CepComplementar.hasError('required')">
                                                    CEP é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="40">
                                                <mat-label>Cidade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="CidadeComplementar"
                                                    formControlName="CidadeComplementar" matInput>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="30">
                                                <mat-label>UF</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="UF" matNativeControl formControlName="UFComplementar">
                                                    <mat-option *ngFor="let item of estados" value={{item}}>{{item}}
                                                    </mat-option>
                                                </mat-select>

                                            </mat-form-field>

                                        </div>



                                        <div class='Adressdesc' fxLayout="row" fxLayoutAlign="start start"
                                            *ngIf="form.controls.TempoResidencia.value.includes('0,') &&
                                                                                        form.controls.TempoResidencia.value != '' ">

                                            <h3>Residência Anterior </h3>

                                            <mat-icon class="help" matTooltip="Preencher se a residência atual for inferior 
                                                                        a 12 meses">
                                                error_outline
                                            </mat-icon>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayout.md="row" fxLayoutAlign="space-between start"
                                            *ngIf="form.controls.TempoResidencia.value.includes('0,') &&
                                                                                                form.controls.TempoResidencia.value != ''">

                                            <mat-form-field appearance="outline" fxFlex="80" fxFlex.md="50"
                                                fxFlex.sm="100" fxFlex.xs="100">
                                                <mat-label>Endereço</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Endereco" matInput
                                                    formControlName="EnderecoAnterior" required>
                                                <mat-error>Endereço é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.md="50"
                                                fxFlex.sm="100" fxFlex.xs="100">
                                                <mat-label>Número</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="10" name="NumeroAnterior" matInput
                                                    formControlName="NumeroAnterior" required>
                                                <mat-error *ngIf="form.controls.NumeroAnterior.hasError('required')">
                                                    Número
                                                    é obrigatório!</mat-error>
                                                <mat-error *ngIf="!form.controls.NumeroAnterior.hasError('required')">
                                                    Número
                                                    inválido!</mat-error>

                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayout.md="row" fxLayoutAlign="space-between start"
                                            *ngIf="form.controls.TempoResidencia.value.includes('0,') &&
                                                                                                form.controls.TempoResidencia.value != ''">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="50" fxFlex="50">
                                                <mat-label>Bairro</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Bairro" matInput
                                                    formControlName="BairroAnterior" required>
                                                <mat-error>Bairro é obrigatório!</mat-error>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.md="50"
                                                fxFlex.sm="100" fxFlex="50">
                                                <mat-label>Complemento</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Complemento" matInput
                                                    formControlName="ComplementoAnterior">


                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap"
                                            fxLayout.md="row wap" fxLayoutAlign="space-between start"
                                            *ngIf="form.controls.TempoResidencia.value.includes('0,') &&
                                                                                            form.controls.TempoResidencia.value != ''">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="35" fxFlex="20">
                                                <mat-label>CEP</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CEP3" [mask]="maskCEP" matInput
                                                    formControlName="CepAnterior" required
                                                    (blur)="changeCEP(form.controls.CepAnterior.value, 'anterior')">
                                                <mat-error *ngIf="form.controls.CepAnterior.hasError('required')">
                                                    CEP é obrigatório!
                                                </mat-error>
                                                <mat-error *ngIf="!form.controls.CepAnterior.hasError('required')">
                                                    CEP é inválido!
                                                </mat-error>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="62" fxFlex="30">
                                                <mat-label>Cidade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="Cidade3" matInput formControlName="CidadeAnterior"
                                                    required>
                                                <mat-error>Cidade é obrigatório!</mat-error>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="35" fxFlex="15">
                                                <mat-label>UF</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="UF" formControlName="UFAnterior" matNativeControl
                                                    required>
                                                    <mat-option *ngFor="let item of estados" value={{item}}>{{item}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error>UF é obrigatório!</mat-error>

                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex.md="62" fxFlex="35">
                                                <mat-label>Tempo de Residência (em anos)</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="10" min="1" name="TempoResidenciaAnterior" matInput
                                                    formControlName="TempoResidenciaAnterior" required>
                                                <mat-error>Tempo de Residência é obrigatório!</mat-error>

                                            </mat-form-field>

                                        </div>

                                    </form>

                                </div>

                            </mat-tab>



                            <!-- ############################################################# -->
                            <!-- ##########################  Contatos  ####################### -->
                            <!-- ############################################################# -->

                            <mat-tab label="Contato" class="tabHeader">

                                <div class="form">

                                    <form class="contato" [formGroup]="form">

                                        <div fxLayout="row" fxLayout.sm="row wap" fxLayout.xs="row wap"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>E-mail</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" matInput formControlName="Email" required>
                                                <mat-error *ngIf="!form.controls.Email.hasError('required')">
                                                    E-mail é inválido!
                                                </mat-error>
                                                <mat-error *ngIf="form.controls.Email.hasError('required')">
                                                    E-mail é obrigatório!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Telefone/Celular</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="Telefone" formControlName="Telefone"
                                                    [minlength]="isFixo(null) ? 10 : 11 " matInput
                                                    [mask]="getTelefoneMask(null)"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required>
                                                <mat-error *ngIf="form.controls.Telefone.hasError('required')">
                                                    Telefone/Celular é obrigatório!</mat-error>
                                                <mat-error *ngIf="!form.controls.Telefone.hasError('required')">
                                                    Telefone/Celular é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                        </div>

                                    </form>

                                </div>

                            </mat-tab>


                            <!-- ############################################################# -->
                            <!-- ##########################    RENDA   ####################### -->
                            <!-- ############################################################# -->

                            <mat-tab label="Renda" class="tabHeader">

                                <div class="form">

                                    <form class="renda" [formGroup]="form">

                                        <div fxLayout.xs="row wap" fxLayout.sm="row" fxLayout="row" fxLayout.md="row"
                                            fxLayoutAlign="start start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50"
                                                fxFlex.md="30" fxFlex="30">
                                                <mat-label>É Sócio ou Proprietário</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="socioProprietario"
                                                    (selectionChange)="setValidatorsRenda(form.controls.RendaSocioProprietario.value)"
                                                    formControlName="RendaSocioProprietario" matNativeControl required>
                                                    <mat-option value="S">Sim</mat-option>
                                                    <mat-option value="N">Não</mat-option>
                                                </mat-select>
                                                <mat-error>É Sócio ou Proprietário obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50"
                                                fxFlex.md="70" fxFlex="70">
                                                <mat-label>Empresa</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Empresa" formControlName="RendaEmpresa"
                                                    matInput required>
                                                <mat-error>Empresa é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form.controls.RendaSocioProprietario.value == 'S'"
                                                appearance="outline" fxFlex.xs="100" fxFlex.sm="48" fxFlex="25">
                                                <mat-label>CNPJ</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CNPJ" formControlName="RendaCNPJ" [mask]="maskCnpj"
                                                    matInput required>
                                                <mat-error *ngIf="form.controls.RendaCNPJ.hasError('required')">CNPJ é
                                                    obrigatório!</mat-error>
                                                <mat-error *ngIf="!form.controls.RendaCNPJ.hasError('required')">
                                                    CNPJ é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100"
                                                fxFlex="50">
                                                <mat-label>Endereço Comercial</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="EnderecoComercial"
                                                    formControlName="RendaEnderecoComercial" matInput required>
                                                <mat-error>Endereço Comercial é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="45"
                                                fxFlex="20">
                                                <mat-label>Número</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="10" name="Numero" formControlName="RendaNumero"
                                                    matInput required>
                                                <mat-error>Número é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm=""
                                                fxFlex="30">
                                                <mat-label>Bairro Comercial</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Bairro" formControlName="RendaBairro"
                                                    matInput required>
                                                <mat-error>Bairro Comercial é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row " fxLayout="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="40"
                                                fxFlex="30">
                                                <mat-label>Complemento</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Complemento"
                                                    formControlName="RendaComplemento" matInput>
                                                <mat-error>Complemento é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="55"
                                                fxFlex="20">
                                                <mat-label>CEP</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CEP4" [mask]="maskCEP" matInput
                                                    (blur)="changeCEP(form.controls.RendaCEP.value, 'renda')"
                                                    formControlName="RendaCEP" required>
                                                <mat-error *ngIf="form.controls.RendaCEP.hasError('required')">
                                                    CEP é obrigatório!
                                                </mat-error>
                                                <mat-error *ngIf="!form.controls.RendaCEP.hasError('required')">
                                                    CEP é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="65"
                                                fxFlex="30">
                                                <mat-label>Cidade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="Cidade" maxlength="20" formControlName="RendaCidade"
                                                    matInput required>
                                                <mat-error>Cidade é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="30"
                                                fxFlex="20">
                                                <mat-label>UF</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="UF" formControlName="RendaUF" matNativeControl
                                                    required>
                                                    <mat-option *ngFor="let item of estados" value={{item}}>{{item}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error>UF é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row" fxLayout.md="row" fxLayout="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50"
                                                fxFlex.md="50" fxFlex="50">
                                                <mat-label>Telefone Comercial</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="Telefone" formControlName="RendaTelefoneFixo"
                                                    [minlength]="isFixoRenda(null) ? 10 : 11 " matInput
                                                    [mask]="getRendaTelefoneMask(null)"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required maxlength="14">
                                                <mat-error *ngIf="form.controls.RendaTelefoneFixo.hasError('required')">
                                                    Telefone Comercial é obrigatório!</mat-error>
                                                <mat-error
                                                    *ngIf="!form.controls.RendaTelefoneFixo.hasError('required')">
                                                    Telefone Comercial é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50"
                                                fxFlex.md="50" fxFlex="50">
                                                <mat-label>Cargo</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Cargo" formControlName="RendaCargo" matInput
                                                    required>
                                                <mat-error>Cargo é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout.xs="row wap" fxLayout.sm="row" fxLayout.md="row" fxLayout="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="48"
                                                fxFlex.md="50" fxFlex="50">
                                                <mat-label>Data de Admissão</mat-label>

                                                <input matInput type="text" mask="00/00/0000" maxlength="10"
                                                    name="RendaDataAdmissao" autocomplete="off"
                                                    (change)="pickDate($event.target.value,'admissao')"
                                                    formControlName="RendaDataAdmissao" required />

                                                <input type="hidden" [matDatepicker]="picker" name="DataAdmissaoHidden"
                                                    (dateChange)="pickDate($event.targetElement.value,'admissao')" />

                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error>Data de Admissão é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="48"
                                                fxFlex.md="50" fxFlex="50">
                                                <mat-label>Renda Mensal (R$)</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="RendaMensal" currencyMask min='0.01' autocomplete="off"
                                                    [options]="{ align: 'letft', prefix: 'R$ ', thousands: '.', decimal: ','}"
                                                    autocomplete="off" formControlName="RendaMensal" matInput required>
                                                <mat-error>Renda Mensal (R$) é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>
                                        <div fxLayout="row wap" fxLayout.md="row" fxLayout.xl="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.md="50"
                                                fxFlex.xl="50">
                                                <mat-label>PPE (Pessoa Politicamente Exposta):</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="ppe" formControlName="PPE" matNativeControl required>
                                                    <mat-option value="S">Sim</mat-option>
                                                    <mat-option value="N">Não</mat-option>
                                                </mat-select>
                                                <mat-error>PPE é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.md="50"
                                                fxFlex.xl="50">
                                                <mat-label>Situação Empregatícia</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="SituacaoEmpregaticia"
                                                    (selectionChange)="pesquisaDocumentos()"
                                                    formControlName="SituacaoEmpregaticia" matNativeControl required>
                                                    <mat-option *ngFor="let element of situacoesEmpregaticias"
                                                        value="{{element.id}}">
                                                        {{element.nome}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error> Situação Empregatícia é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                    </form>


                                </div>

                                <!-- ############################################################# -->
                                <!-- ####################   OUTROS RENDIMENTOS   ################# -->
                                <!-- ############################################################# -->
                                <h3 fxLayoutAlign="start start">Outros Rendimentos:</h3>

                                <div class="linha " fxLayout="column" fxLayout="row" fxLayout.xs="row"
                                    fxLayoutAlign="space-between end">

                                    <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxShow fxHide.xs="true"
                                        fxHide.sm="true" fxFlex="100">
                                        <mat-table #table [dataSource]="dataSourceOutrosRendimentos" fxFlex="100">

                                            <!--Nome Column-->
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef fxFlex="10"> Id </mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxFlex="10">
                                                    <p class="text-truncate font-weight-600">
                                                        {{element.IdOutros_rendimentos}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Nome Column-->
                                            <ng-container matColumnDef="origem">
                                                <mat-header-cell *matHeaderCellDef fxFlex="35"> Origem
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxFlex="35">
                                                    <p class="text-truncate font-weight-600"> {{element.Origem}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Cargo Column-->
                                            <ng-container matColumnDef="valor">
                                                <mat-header-cell *matHeaderCellDef fxFlex="35"> Valor </mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxFlex="35">
                                                    <p class="text-truncate font-weight-600">
                                                        {{modifyCurrency(element.Valor)}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Remove Column-->
                                            <ng-container matColumnDef="remove">
                                                <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"
                                                    fxFlex="20"> Opções </mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxLayoutAlign="center center"
                                                    fxFlex="20">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('outrosRendimentos','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('outrosRendimentos','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-cell>

                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumnsOutrosRendimentos">
                                            </mat-header-row>

                                            <mat-row
                                                *matRowDef="let element; columns: displayedColumnsOutrosRendimentos;"
                                                class="element">
                                            </mat-row>
                                        </mat-table>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="row wap" fxLayoutGap="20" fxLayout.sm="row wap"
                                        fxShow.xs="true" fxShow.sm="true" fxHide="true" fxLayoutAlign="center start"
                                        fxFlex="100">
                                        <!-- CARDS -->
                                        <mat-card class="card-custom-r"
                                            *ngFor="let element of dataSourceOutrosRendimentos" fxFlex.xs="95"
                                            fxFlex.sm="95">
                                            <mat-card-content>
                                                <p class="text-card-r"><span>ID:</span> {{element.IdOutros_rendimentos}}
                                                </p>
                                                <p class="text-card-r"><span>Origem: </span> {{element.Origem}} </p>
                                                <p class="text-card-r"><span>Valor: </span>
                                                    {{modifyCurrency(element.Valor)}} </p>


                                                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="row"
                                                    fxLayoutAlign="end end">

                                                    <button fxFlex.xs="10" fxFlex.sm="10" mat-button
                                                        matTooltip="Atualizar"
                                                        (click)="createModal('outrosRendimentos','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('outrosRendimentos','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>

                                <div class="linha" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="end end"
                                    fxLayoutAlign.xs="center center">
                                    <button mat-raised-button class="addItem" color="primary" form="none"
                                        (click)="createModal('outrosRendimentos','create', IdRenda)"
                                        style="margin:10px">
                                        Adicionar Novo
                                    </button>
                                </div>

                            </mat-tab>


                            <!-- ############################################################# -->
                            <!-- #########################   CONJUGE   ####################### -->
                            <!-- ############################################################# -->


                            <mat-tab label="Cônjuge" class="tabHeader " *ngIf="form.controls.EstadoCivil.value == 'C'">
                                <!-- <mat-tab label="Cônjuge" class="tabHeader " >  -->

                                <div class="form">

                                    <form class="conjuge" [formGroup]="form">

                                        <div fxLayout="row" fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label>Nome</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="Nome" formControlName="NomeConjuge" matInput
                                                    required>
                                                <mat-error>Nome é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48">
                                                <mat-label>CPF</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CPF" formControlName="CpfConjuge" [mask]="maskCpf" matInput
                                                    required>
                                                <mat-error *ngIf="form.controls.CpfConjuge.hasError('required')">
                                                    CPF é obrigatório!
                                                </mat-error>
                                                <mat-error *ngIf="!form.controls.CpfConjuge.hasError('required')">
                                                    CPF é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48">
                                                <mat-label>RG</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="20" name="RG" formControlName="RgConjuge" matInput
                                                    required>
                                                <mat-error>RG é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row"
                                            fxLayoutAlign="start start">

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48">
                                                <mat-label>Possui Renda?</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="PossuiRenda"
                                                    (selectionChange)="setValidatorsRendaConjuge(form.controls.PossuiRenda.value)"
                                                    formControlName="PossuiRenda" matNativeControl required>
                                                    <mat-option value="S">Sim</mat-option>
                                                    <mat-option value="N">Não</mat-option>
                                                </mat-select>
                                                <mat-error>Possui Renda é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48" *ngIf="form.controls.PossuiRenda.value == 'S'">
                                                <mat-label>Tipo de Vínculo</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="Vinculo"
                                                    (selectionChange)="setValidatorsVinculoConjuge(form.controls.TipoVinculo.value)"
                                                    formControlName="TipoVinculo" matNativeControl required>
                                                    <mat-option value="C">Colaborador(a)</mat-option>
                                                    <mat-option value="A">Autônomo(a)</mat-option>
                                                </mat-select>
                                                <mat-error>Tipo de Vínculo é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row" *ngIf="form.controls.PossuiRenda.value == 'S' &&
                                                                form.controls.TipoVinculo.value == 'C'"
                                            fxLayoutAlign="start start">

                                            <mat-form-field appearance="outline" fxFlex="30" fxFlex.xs="100"
                                                fxFlex.sm="48">
                                                <mat-label>É Sócio ou Proprietário?</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <mat-select name="socioProprietario"
                                                    (selectionChange)="setValidatorsConjuge(form.controls.SocioProprietario.value)"
                                                    formControlName="SocioProprietario" matNativeControl required>
                                                    <mat-option value="S">Sim</mat-option>
                                                    <mat-option value="N">Não</mat-option>
                                                </mat-select>
                                                <mat-error> É Sócio ou Proprietário é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.xs="100"
                                                fxFlex.sm="48">
                                                <mat-label>Empresa Onde Trabalha</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input maxlength="50" name="trabalho" formControlName="EmpresaConjuge"
                                                    matInput required>
                                                <mat-error>Empresa Onde Trabalha é obrigatório!</mat-error>
                                            </mat-form-field>


                                            <mat-form-field *ngIf="form.controls.SocioProprietario.value == 'S' &&
                                                                form.controls.TipoVinculo.value == 'C'"
                                                appearance="outline" fxFlex="25" fxFlex.xs="100" fxFlex.sm="48">
                                                <mat-label>CNPJ</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="CNPJ" formControlName="CnpjConjuge" [mask]="maskCnpj"
                                                    matInput required>
                                                <mat-error *ngIf="form.controls.CnpjConjuge.hasError('required')">CNPJ é
                                                    obrigatório!</mat-error>
                                                <mat-error *ngIf="!form.controls.CnpjConjuge.hasError('required')">CNPJ
                                                    é
                                                    inválido!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.xs="100"
                                                fxFlex.sm="48" *ngIf="form.controls.PossuiRenda.value == 'S'">
                                                <mat-label>Cargo/Atividade</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="Cargo" matInput maxlength="50"
                                                    formControlName="CargoConjuge" required>
                                                <mat-error>Cargo/Atividade é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48" *ngIf="form.controls.PossuiRenda.value == 'S' &&
                                                                form.controls.TipoVinculo.value == 'C'">
                                                <mat-label>Data de Admissão</mat-label>

                                                <input matInput type="text" mask="00/00/0000" maxlength="10"
                                                    name="DataAdmissao" autocomplete="off"
                                                    (change)="pickDate($event.target.value,'admissaoConjuge')"
                                                    formControlName="DataAdmissao" required />

                                                <input type="hidden" [matDatepicker]="picker" name="DataAdmissaoHidden"
                                                    (dateChange)="pickDate($event.targetElement.value,'admissaoConjuge')" />

                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error>Data de Admissão é obrigatório!</mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48" *ngIf="form.controls.PossuiRenda.value == 'S' &&
                                                                form.controls.TipoVinculo.value == 'A'">
                                                <mat-label>Data de Início</mat-label>

                                                <input matInput type="text" mask="00/00/0000" maxlength="10"
                                                    name="DataAdmissao" autocomplete="off"
                                                    (change)="pickDate($event.target.value,'admissaoConjuge')"
                                                    formControlName="DataAdmissao" required />

                                                <input type="hidden" [matDatepicker]="picker" name="DataAdmissaoHidden"
                                                    (dateChange)="pickDate($event.targetElement.value,'admissaoConjuge')" />

                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error>Data de Início é obrigatório!</mat-error>
                                            </mat-form-field>

                                        </div>

                                        <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row"
                                            fxLayoutAlign="space-between start">

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48" *ngIf="form.controls.PossuiRenda.value == 'S'">
                                                <mat-label>Renda Mensal (R$)</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="RendaConjuge" matInput required
                                                    formControlName="RendaMensalConjuge" currencyMask min="0.01"
                                                    autocomplete="off"
                                                    [options]="{ align: 'letft', prefix: 'R$ ', thousands: '.', decimal: ',' }">
                                                <mat-error>Renda Mensal (R$) é obrigatório!</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100"
                                                fxFlex.sm="48" *ngIf="form.controls.PossuiRenda.value == 'S'">
                                                <mat-label>Telefone Comercial</mat-label>
                                                <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                <input name="TelefoneComConjuge" formControlName="TelefoneComercial"
                                                    [minlength]="isFixoConjuge() ? 10 : 11 " matInput
                                                    [mask]="getConjugeTelefoneMask()"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required maxlength="14">
                                                <mat-error *ngIf="form.controls.TelefoneComercial.hasError('required')">
                                                    Telefone Comercial é obrigatório!</mat-error>
                                                <mat-error
                                                    *ngIf="!form.controls.TelefoneComercial.hasError('required')">
                                                    Telefone Comercial é inválido!
                                                </mat-error>
                                            </mat-form-field>

                                        </div>

                                    </form>

                                </div>

                            </mat-tab>


                            <!-- ############################################################# -->
                            <!-- #######################   Patrimonio   ###################### -->
                            <!-- ############################################################# -->

                            <mat-tab label="Patrimônio" class="tabHeader">

                                <div class="form patrimonio">

                                    <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">

                                        <h2>Patrimônio que possui: Bem(ns), Imóvel(eis) e Veículo(s)</h2>

                                    </div>

                                    <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxShow fxHide.xs="true"
                                        fxHide.sm="true" fxLayoutAlign="space-around start" fxFlex="100">
                                        <!-- TABELA -->
                                        <mat-table #table [dataSource]="dataSourcePatrimonio" style="width: 100%;">

                                            <!--Id Column-->
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef fxFlex="10"> Id </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.IdPatrimonio)" fxFlex="10">
                                                    <p class="text-truncate font-weight-600"> {{element.IdPatrimonio}}
                                                    </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Descrição Column-->
                                            <ng-container matColumnDef="categoria">
                                                <mat-header-cell *matHeaderCellDef fxFlex="30"> Categoria
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Categoria)" fxFlex="30">
                                                    <p class="text-truncate font-weight-600"
                                                        *ngIf="element.Categoria == 'I'">Imóvel</p>
                                                    <p class="text-truncate font-weight-600"
                                                        *ngIf="element.Categoria == 'V'">Veículo</p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Valor Column-->
                                            <ng-container matColumnDef="valor">
                                                <mat-header-cell *matHeaderCellDef fxFlex="30"> Valor Atual
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="modifyCurrency(element.ValorAtual)" fxFlex="30">
                                                    <p class="text-truncate font-weight-600">
                                                        {{modifyCurrency(element.ValorAtual)}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Ônus Column-->
                                            <ng-container matColumnDef="onus">
                                                <mat-header-cell *matHeaderCellDef fxFlex="10"> Ônus </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Onus)" fxFlex="10">
                                                    <p class="text-truncate font-weight-600"
                                                        *ngIf="element.Onus == 'S'">
                                                        Sim </p>
                                                    <p class="text-truncate font-weight-600"
                                                        *ngIf="element.Onus == 'N'">
                                                        Não </p>
                                                    <p class="text-truncate font-weight-600" *ngIf="element.Onus == ''">
                                                        -
                                                    </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Remove Column-->
                                            <ng-container matColumnDef="remove">
                                                <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"
                                                    fxFlex="20"> Opções </mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxLayoutAlign="center center"
                                                    fxFlex="20">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('patrimonio','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('patrimonio','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </mat-cell>

                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumnsPatrimonio">
                                            </mat-header-row>

                                            <mat-row *matRowDef="let element; columns: displayedColumnsPatrimonio;"
                                                class="element">
                                            </mat-row>

                                        </mat-table>

                                    </div>
                                    <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap" fxShow.xs="true"
                                        fxShow.sm="true" fxHide="true" fxLayoutAlign="center start">
                                        <!-- CARDS -->
                                        <mat-card class="card-custom-r" *ngFor="let element of dataSourcePatrimonio"
                                            fxFlex.xs="95" fxFlex.sm="95">
                                            <mat-card-content>
                                                <p class="text-card-r"><span>ID:</span> {{element.IdPatrimonio}} </p>
                                                <p class="text-card-r" *ngIf="element.Categoria == 'I'">
                                                    <span>Categoria:</span> Imóvel</p>
                                                <p class="text-card-r" *ngIf="element.Categoria == 'V'">
                                                    <span>Categoria:</span> Veículo</p>
                                                <p class="text-card-r"><span>Valor Atual:</span>
                                                    {{modifyCurrency(element.ValorAtual)}} </p>
                                                <p class="text-card-r" *ngIf="element.Onus == 'S'"><span>Ônus:</span>
                                                    Sim
                                                </p>
                                                <p class="text-card-r" *ngIf="element.Onus == 'N'"><span>Ônus:</span>
                                                    Não
                                                </p>
                                                <p class="text-card-r" *ngIf="element.Onus == ''"><span>Ônus:</span> -
                                                </p>

                                                <div fxLayout="row" fxLayoutAlign="end end">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('patrimonio','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('patrimonio','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>

                                    <div fxLayout="row" fxLayout.xl="row" fxLayoutAlign="end end"
                                        fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
                                        <button mat-raised-button color="primary"
                                            (click)="createModal('patrimonio','create', IdPessoaFisica)"
                                            style="margin:10px">
                                            Adicionar Novo
                                        </button>
                                    </div>

                                </div>

                            </mat-tab>


                            <!-- ############################################################# -->
                            <!-- #######################   Referencias  ###################### -->
                            <!-- ############################################################# -->

                            <mat-tab label="Referências" class="tabHeader">

                                <div class="form">

                                    <div class="linha" fxLayout="row" fxLayoutAlign="space-between start">

                                        <h2> Referências Bancárias </h2>

                                    </div>


                                    <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxShow fxHide.xs="true"
                                        fxHide.sm="true">
                                        <mat-table #table [dataSource]="dataSourceContaBancaria" matSort fxFlex="100">

                                            <!--Id Column-->
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef fxFlex="10"> Id </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.IdReferenciaBancaria)"
                                                    fxFlex="10">
                                                    <p class="text-truncate font-weight-600">
                                                        {{element.IdReferenciaBancaria}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Banco Column-->
                                            <ng-container matColumnDef="banco">
                                                <mat-header-cell *matHeaderCellDef fxFlex="25"> Banco </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Banco)" fxFlex="25">
                                                    <p class="text-truncate font-weight-600"> {{element.Banco}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Agencia Column-->
                                            <ng-container matColumnDef="agencia">
                                                <mat-header-cell *matHeaderCellDef fxFlex="20"> Agência
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Agencia)" fxFlex="20">
                                                    <p class="text-truncate font-weight-600"> {{element.Agencia}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Conta Column-->
                                            <ng-container matColumnDef="numero">
                                                <mat-header-cell *matHeaderCellDef fxFlex="25"> Número da Conta
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Numero)" fxFlex="25">
                                                    <p class="text-truncate font-weight-600">
                                                        {{formatTooltip(element.Numero)}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Tipo da Conta Column-->
                                            <ng-container matColumnDef="conta">
                                                <mat-header-cell *matHeaderCellDef fxFlex="25"> Tipo da Conta
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Conta)" fxFlex="25">
                                                    <p class="text-truncate font-weight-600">
                                                        {{formatTooltip(element.Conta)}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Remove Column-->
                                            <ng-container matColumnDef="remove">
                                                <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"
                                                    fxFlex="20"> Opções </mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxLayoutAlign="center center"
                                                    fxFlex="20">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('referenciasBancarias','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('referenciasBancarias','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </mat-cell>

                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumnsContas"></mat-header-row>

                                            <mat-row *matRowDef="let element; columns: displayedColumnsContas;"
                                                class="element">
                                            </mat-row>

                                        </mat-table>

                                    </div>
                                    <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap" fxShow.xs="true"
                                        fxShow.sm="true" fxHide="true" fxLayoutAlign="center start">
                                        <!-- CARDS -->
                                        <mat-card class="card-custom-r" *ngFor="let element of dataSourceContaBancaria"
                                            fxFlex.xs="95" fxFlex.sm="95">
                                            <mat-card-content>
                                                <p class="text-card-r"><span>ID:</span> {{element.IdReferenciaBancaria}}
                                                </p>
                                                <p class="text-card-r"><span>Banco:</span> {{element.Banco}} </p>
                                                <p class="text-card-r"><span>Nº Agência:</span> {{element.Agencia}} </p>
                                                <p class="text-card-r"><span>Nº Conta:</span>
                                                    {{formatTooltip(element.Numero)}} </p>
                                                <p class="text-card-r"><span>Tipo da Conta:</span>
                                                    {{formatTooltip(element.Conta)}} </p>

                                                <div fxLayout="row" fxLayoutAlign="end end">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('referenciasBancarias','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('referenciasBancarias','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>

                                    <div fxLayout="row" fxLayoutAlign="end start">
                                        <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxLayoutAlign="end end"
                                            fxLayoutAlign.xs="center end" fxLayoutAlign.sm="center end" fxFlex="100">
                                            <button mat-raised-button color="primary"
                                                (click)="createModal('referenciasBancarias','create', IdPessoaFisica)"
                                                style="margin:10px">
                                                Adicionar Novo
                                            </button>
                                        </div>
                                    </div>

                                    <!--REFERENCIAS PESSOAIS E BOTAO ATULIZAR-->
                                    <div class="linha" fxLayout="row" fxLayoutAlign="space-between start">

                                        <h2> Referências Pessoais </h2>

                                    </div>

                                    <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxShow fxHide.xs="true"
                                        fxHide.sm="true">
                                        <mat-table #table [dataSource]="dataSourceReferenciasPessoais" matSort
                                            style="width: 100%;">

                                            <!--Id Column-->
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef fxFlex="10"> Id </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.IdReferenciaPessoal)"
                                                    fxFlex="10">
                                                    <p class="text-truncate font-weight-600">
                                                        {{element.IdReferenciaPessoal}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Nome Column-->
                                            <ng-container matColumnDef="nome">
                                                <mat-header-cell *matHeaderCellDef fxFlex="25"> Nome </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Nome)" fxFlex="25">
                                                    <p class="text-truncate font-weight-600"> {{element.Nome}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Endereco Column-->
                                            <ng-container matColumnDef="endereco">
                                                <mat-header-cell *matHeaderCellDef fxFlex="25"> Endereço
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Endereco)" fxFlex="25">
                                                    <p class="text-truncate font-weight-600"> {{element.Endereco}} </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!--Telefone/Celular Column-->
                                            <ng-container matColumnDef="telefone">
                                                <mat-header-cell *matHeaderCellDef fxFlex="20"> Telefone/Celular
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element"
                                                    [matTooltip]="formatTooltip(element.Telefone)" fxFlex="20">
                                                    <p class="text-truncate font-weight-600">
                                                        {{modifyTelefone(element.Telefone)}} </p>

                                                </mat-cell>
                                            </ng-container>

                                            <!--Remove Column-->
                                            <ng-container matColumnDef="remove">
                                                <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"
                                                    fxFlex="20"> Opções</mat-header-cell>
                                                <mat-cell *matCellDef="let element" fxLayoutAlign="center center"
                                                    fxFlex="20">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('referenciasPessoais','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('referenciasPessoais','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </mat-cell>

                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumnsPessoais">
                                            </mat-header-row>

                                            <mat-row *matRowDef="let element; columns: displayedColumnsPessoais;"
                                                class="element">
                                            </mat-row>

                                        </mat-table>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap" fxShow.xs="true"
                                        fxShow.sm="true" fxHide="true" fxLayoutAlign="center start">
                                        <!-- CARDS -->
                                        <mat-card class="card-custom-r"
                                            *ngFor="let element of dataSourceReferenciasPessoais" fxFlex.xs="95"
                                            fxFlex.sm="95">
                                            <mat-card-content>
                                                <p class="text-card-r"><span>Id:</span> {{element.IdReferenciaPessoal}}
                                                </p>
                                                <p class="text-card-r"><span>Name:</span> {{element.Nome}} </p>
                                                <p class="text-card-r"><span>Endereço:</span> {{element.Endereco}} </p>
                                                <p class="text-card-r"><span>Telefone/Celular: </span>
                                                    {{modifyTelefone(element.Telefone)}} </p>

                                                <div fxLayout="row" fxLayoutAlign="end end">

                                                    <button mat-button matTooltip="Atualizar"
                                                        (click)="createModal('referenciasPessoais','update', element)"
                                                        color="primary">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>

                                                    <button mat-button matTooltip="Excluir"
                                                        (click)="createModal('referenciasPessoais','delete', element)"
                                                        color="primary">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>


                                    <div class="linha" fxLayout="row" fxLayoutAlign="end start">

                                        <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxLayoutAlign="end end"
                                            fxLayoutAlign.xs="center end" fxLayoutAlign.sm="center end" fxFlex="100">
                                            <button mat-raised-button color="primary"
                                                (click)="createModal('referenciasPessoais','create', IdPessoaFisica)"
                                                style="margin:10px">
                                                Adicionar Novo
                                            </button>
                                        </div>

                                    </div>

                                </div>

                                <!--REFERENCIAS COMERCIAIS E BOTAO ATULIZAR-->
                                <div class="linha" fxLayout="row" fxLayoutAlign="space-between start">

                                    <h2> Referências Comerciais </h2>

                                </div>

                                <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxShow fxHide.xs="true"
                                    fxHide.sm="true">

                                    <mat-table #table [dataSource]="dataSourceReferenciasComerciais" matSort
                                        style="width: 100%;">

                                        <!--Id Column-->
                                        <ng-container matColumnDef="id">
                                            <mat-header-cell *matHeaderCellDef fxFlex="10"> Id </mat-header-cell>
                                            <mat-cell *matCellDef="let element"
                                                [matTooltip]="formatTooltip(element.IdReferenciaComercial)" fxFlex="10">
                                                <p class="text-truncate font-weight-600">
                                                    {{element.IdReferenciaComercial}}
                                                </p>
                                            </mat-cell>
                                        </ng-container>

                                        <!--Empresa Column-->
                                        <ng-container matColumnDef="empresa">
                                            <mat-header-cell *matHeaderCellDef fxFlex="25"> Empresa </mat-header-cell>
                                            <mat-cell *matCellDef="let element"
                                                [matTooltip]="formatTooltip(element.Empresa)" fxFlex="25">
                                                <p class="text-truncate font-weight-600"> {{element.Empresa}} </p>
                                            </mat-cell>
                                        </ng-container>

                                        <!--Contato Column-->
                                        <ng-container matColumnDef="contato">
                                            <mat-header-cell *matHeaderCellDef fxFlex="25"> Contato/Setor
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"
                                                [matTooltip]="formatTooltip(element.ContatoSetor)" fxFlex="25">
                                                <p class="text-truncate font-weight-600"> {{element.ContatoSetor}} </p>
                                            </mat-cell>
                                        </ng-container>

                                        <!--Telefone/Celular Column-->
                                        <ng-container matColumnDef="telefone">
                                            <mat-header-cell *matHeaderCellDef fxFlex="20"> Telefone/Celular
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"
                                                [matTooltip]="formatTooltip(element.Telefone)" fxFlex="20">
                                                <p class="text-truncate font-weight-600">
                                                    {{modifyTelefone(element.Telefone)}} </p>
                                            </mat-cell>
                                        </ng-container>

                                        <!--Remove Column-->
                                        <ng-container matColumnDef="remove">
                                            <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"
                                                fxFlex="20">
                                                Opções </mat-header-cell>
                                            <mat-cell *matCellDef="let element" fxLayoutAlign="center center"
                                                fxFlex="20">

                                                <button mat-button matTooltip="Atualizar"
                                                    (click)="createModal('referenciasComerciais','update', element)"
                                                    color="primary">
                                                    <mat-icon>edit</mat-icon>
                                                </button>

                                                <button mat-button matTooltip="Excluir"
                                                    (click)="createModal('referenciasComerciais','delete', element)"
                                                    color="primary">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                            </mat-cell>

                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="displayedColumnsComerciais"></mat-header-row>

                                        <mat-row *matRowDef="let element; columns: displayedColumnsComerciais;"
                                            class="element">
                                        </mat-row>

                                    </mat-table>
                                </div>
                                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap" fxShow.xs="true"
                                    fxShow.sm="true" fxHide="true" fxLayoutAlign="center start">
                                    <!-- CARDS -->
                                    <mat-card class="card-custom-r"
                                        *ngFor="let element of dataSourceReferenciasComerciais" fxFlex.xs="95"
                                        fxFlex.sm="95">
                                        <mat-card-content>
                                            <p class="text-card-r"><span>Id:</span> {{element.IdReferenciaComercial}}
                                            </p>
                                            <p class="text-card-r"><span>Empresa:</span> {{element.Empresa}} </p>
                                            <p class="text-card-r"><span>Contato/Setor</span> {{element.ContatoSetor}}
                                            </p>
                                            <p class="text-card-r"><span>Telefone/Celular</span>
                                                {{modifyTelefone(element.Telefone)}} </p>

                                            <div fxLayout="row" fxLayoutAlign="end end">

                                                <button mat-button matTooltip="Atualizar"
                                                    (click)="createModal('referenciasComerciais','update', element)"
                                                    color="primary">
                                                    <mat-icon>edit</mat-icon>
                                                </button>

                                                <button mat-button matTooltip="Excluir"
                                                    (click)="createModal('referenciasComerciais','delete', element)"
                                                    color="primary">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>

                                <div class="linha" fxLayout="row" fxLayoutAlign="end start">

                                    <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" fxLayoutAlign="end end"
                                        fxLayoutAlign.xs="center end" fxLayoutAlign.sm="center end" fxFlex="100">
                                        <button mat-raised-button color="primary"
                                            (click)="createModal('referenciasComerciais','create', IdPessoaFisica)"
                                            style="margin:10px">
                                            Adicionar Novo
                                        </button>
                                    </div>

                                </div>

                            </mat-tab>

                        </mat-tab-group>

                        <mat-divider></mat-divider>


                        <!-- ############################################################# -->
                        <!-- ########################  DOCUMENTOS  ####################### -->
                        <!-- ############################################################# -->

                        <!-- <h2 style="margin-top: 10px;">Documentos</h2> -->

                        <div class="box-documentos-h" *ngIf="documentos.length > 0 ">


                            <div colo="primary" class="load" *ngIf="!isReady">
                                <mat-spinner></mat-spinner>
                            </div>

                            <div *ngIf="isReady && documentos.length > 0">

                                <h2>Documentos</h2>

                                <!-- Documentos Obrigatórios do Fiador/Avalista -->
                                <app-fiador-documentos [DocumentosObrigatorios]="documentos"
                                    [ArquivosAnexados]="documentosAnexo" [getFolderSelected]="folderSelected"
                                    [Editar]="editar" (inputFile)="inputFileChange($event.event, $event.documento)"
                                    (deleteFile)="inativaArquivo($event.idFile)"
                                    (folderSelected)="getFolderSelected($event)">
                                </app-fiador-documentos>
                            </div>
                        </div>

                        <button mat-raised-button class="submit-button" form="form" [disabled]="form.invalid"
                            color="primary" (click)="save()" style="margin-top: 20px;">
                            ENVIAR
                        </button>
                    </div>

                    <div class="loaded" *ngIf="formInvalid">

                        <h1>
                            Link de formulário inválido ou expirado.
                        </h1>

                    </div>

                    <div class="loaded" *ngIf="formSended">

                        <h1>
                            Formulário enviado com sucesso!
                        </h1>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>