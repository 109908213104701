<section id="app-cotas-documentos">


    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree" >

        <mat-tree-node *matTreeNodeDef="let documento" matTreeNodeToggle>
            <!-- {{documento.UrlDocumento}} -->
            <li class="Documentos">
                <div (click)="openFolder(documento.NomeDocumento, estado)" class="titulo-documentos">
                    <div>
                        <span id="{{documento.NomeDocumento+estado}}" 
                        class="material-symbols-rounded"> {{_lastFolderOpened == documento.NomeDocumento+estado ? 'folder_open': 'folder'}} </span>
                    </div>
                    <span [ngClass]="documento.UrlDocumento != '' ? '' : 'isAtencao-docs' ">
                        <span class="material-symbols-rounded" [style.display]="documento.UrlDocumento != '' ? 'none' : 'block'">warning</span>
                        {{documento.NomeDocumento}}  
                    </span>
                </div>

               
                <div id="documento-{{documento.NomeDocumento+estado}}" 
                class="documentos-content" [ngClass]="_lastFolderOpened == documento.NomeDocumento+estado ? 'documentos-content-block' : 'documentos-content-none'">

                    <ng-container *ngIf="documento.UrlDocumento != ''">

                        <div class="hasDocumento" *ngFor="let doc of _DataAnexos">
                            
                            <div class="documento-items" *ngIf="doc.IdTipoDocumento === documento.IdTipoDocumento" >

                                <img *ngIf="doc.UrlDocumento != ''"
                                    [src]="imagePath+doc.UrlDocumento.split('.')[1]+'.png'" 
                                    onError="this.src = '/assets/icons/icons/default.png'"
                                    alt="Icon">
                                <span>
                                    <a [href]="_host+doc.UrlDocumento"
                                        target="_blank" title="{{doc.NomeOriginal}}">
                                        {{doc.NomeOriginal | slice: 0 : 30}}
                                        {{doc.NomeOriginal?.length > 30 ? '...' : ''}}
                                    </a>
                                </span>
                                <div *ngIf="Editar">
                                    <button mat-icon-buttom *ngIf="doc.UrlDocumento != '' && doc.UrlDocumento"
                                        matTooltip="Remover Anexo" (click)="deletarFile(doc.IdDocumentoPessoa, documento, estado)">
                                        <mat-icon>
                                            delete
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    

                    </ng-container>
                    <ng-container *ngIf=" documento.IdTipoDocumento && documento.UrlDocumento == '' ">
                        <div class="hasDocumento no-document">
                            <span>Nenhum arquivo selecionado </span>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="documento.IdTipoDocumento && Editar && !_isAvalista">  
                            <label  for="file-input-{{documento.IdTipoDocumento}}">
                                <span class="material-symbols-rounded">
                                    attach_file_add
                                    </span>
                                <span>
                                    Anexar Arquivo
                                </span>
                            </label>
                            <input mat-icon-button type="file" name="" id="file-input-{{documento.IdTipoDocumento}}"
                            (change)="inputFileChange($event, documento, estado)">
                            
                            <ng-container *ngIf="documento.Url_Modelo != null ">
                                <div class="modelo" matTooltip="Baixar Modelo" matTooltipPosition="above">
                                    <span class="material-symbols-rounded" (click)="onOpenFile(_host+documento.Url_Modelo)">
                                        cloud_download
                                    </span>
                                    <span>
                                        <a [href]="_host+documento.Url_Modelo " target="_blank" download>Modelo Sugerido</a>
                                    </span>
                                </div>
                            </ng-container>
                    </ng-container>
                </div>    

            </li>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li>
                <div class="mat-tree-node">
                    <button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <span class="material-symbols-rounded">
                            {{treeControl.isExpanded(node) ? 'folder': 'folder_open' }}
                        </span>
                        {{node.name}}
                    </button>
                </div>
                <ul [class.example-tree-invisible]="treeControl.isExpanded(node)"  role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>

</section>


