import { Injectable, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Parametros } from './parametros.model';
import { ApiService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/services/auth.service';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ParametrosService implements Resolve<any>
{
    parametros: Parametros[];
    formParametros: BehaviorSubject<any>;

    localStorageUser = this.authService.getUser();

    onParametrosChanged: BehaviorSubject<any>;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private apiService: ApiService,
        private authService: AuthService
    )
    {

        this.formParametros = new BehaviorSubject([]);
        this.onParametrosChanged = new BehaviorSubject([]);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
            ]).then(
                ([files]) => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get parametros
     *
     * @returns {Promise<any>}
     */
    getParametros(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(
                `${environment.parametros_get}`, this.localStorageUser['IdEmpresa']
            ).then((response: any) => {                
                if(response.success){
                    this.parametros = response.data[0];

                    this.parametros = this.parametros.map(parametros => {
                        return new Parametros(parametros);
                    });

                    this.onParametrosChanged.next(this.parametros);
                } else {
                    this.parametros = [];
                    this.onParametrosChanged.next(this.parametros);
                }

                resolve(this.parametros);
            }, reject);
        });
    }
}
