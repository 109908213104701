<div class="confirm-dialog-modal ">
    <mat-toolbar matDialogTitle class="accent-600 m-0">
                <span class="title  ml-8" style="padding-left: 1%;">{{aviso}}</span>
            <button mat-icon-button (click)="dialogRef.close(false)" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
    </mat-toolbar>

    <div class="mat-dialog-content-class">{{confirmMessage}}</div>
    <div mat-dialog-actions class="pt-24 confirm-dialog">
        <button (click)="dialogRef.close(false)" class="mr-8" mat-raised-button color="basic"> Cancelar </button>
        <button mat-raised-button class="mr-16" color="primary" (click)="dialogRef.close(confirm)">{{buttonMessage}}</button>
    </div>
</div>

