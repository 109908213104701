<div  class="navbar-top accent" [ngClass]="">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/logo.png">
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon style="color:var(--color-primary);">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon style="color:var(--color-primary);">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div  class="navbar-scroll-container accent" [ngClass]=""
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user accent " fxLayout="column" [ngClass]="">

        <div class="h3 username" style="text-align: center !important; margin: 0 5px;">{{ nomePessoa }}</div>
        <div class="h5 email hint-text mt-8">{{ emailUsuario }}</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.accentBackground">
            <img style="object-fit: cover;" class="avatar" [src]="urlImagem">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
