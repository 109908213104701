<div class="dialog-content-wrapper" class="modal-{{action}}">
    <mat-toolbar matDialogTitle class="accent-600 m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title-xs dialog-title ml-8">
                {{dialogTitle}}
                </span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div *ngIf="action == 'create' || action == 'update'" mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar
        class="modalMsg">

        <!--PESSOA FISICA-->

        <div *ngIf="mainData == 'dependentes'">

            <form class="dependentes" [formGroup]="form">

                <div fxLayout="row" fxLayoutAlign="space-between start">

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Nome:</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" type="nome" name="nome" formControlName="Nome" matInput required>
                        <mat-error>Nome é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" [disabled]="form.invalid" class="bt"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

        <div *ngIf="mainData == 'outrosRendimentos'">

            <form class="outrosRendimentos" [formGroup]="form">

                <div fxLayout="row" fxLayout.xs="row wap" fxLayoutAlign="space-between start">

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100">
                        <mat-label>Origem</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Origem" formControlName="Origem" matInput required>
                        <mat-error>Origem é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100">
                        <mat-label>Valor</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input name="Valor" formControlName="Valor" currencyMask min="0.01" autocomplete="off"
                            [options]="{ align: 'letft', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off" matInput required>
                        <mat-error>Valor é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" class="bt" [disabled]="form.invalid"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

        <div *ngIf="mainData == 'patrimonio'">

            <form class="patrimonio" [formGroup]="form" fxLayout="column">

                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row" fxLayoutAlign="start start" fxFlex="100">

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Categoria</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <mat-select name="financiado"
                            (selectionChange)="setValidatorsPatrimonio(form.controls.Categoria.value)"
                            formControlName="Categoria" matNativeControl required>
                            <mat-option value="I">Imóvel</mat-option>
                            <mat-option value="V">Veículo</mat-option>
                        </mat-select>
                        <mat-error>Categoria é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="form.controls.Categoria.value == 'I'" appearance="outline" fxFlex="60"
                        fxFlex.xs="100" fxFlex.sm="50">
                        <mat-label>Descrição</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="descricaoPatrimonio" formControlName="Descricao" matInput required>
                        <mat-error>Descrição é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="form.controls.Categoria.value == 'V'" appearance="outline" fxFlex="20"
                        fxFlex.xs="100" fxFlex.sm="50">
                        <mat-label>Marca</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="descricaoMarca" formControlName="Marca" matInput required>
                        <mat-error>Marca é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="form.controls.Categoria.value == 'V'" appearance="outline" fxFlex="20"
                        fxFlex.xs="100" fxFlex.sm="50">
                        <mat-label>Modelo</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="descricaoModelo" formControlName="Modelo" matInput required>
                        <mat-error>Modelo é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="form.controls.Categoria.value == 'V'" appearance="outline" fxFlex="20"
                        fxFlex.xs="100" fxFlex.sm="50">
                        <mat-label>Ano</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input mask="0000" name="descricaoAno" formControlName="Ano" matInput required>
                        <mat-error>Ano é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="row wap" fxLayoutAlign="space-around start"
                    fxFlex="100">

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100" fxFlex.sm="50">
                        <mat-label>Valor Atual (R$)</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input name="ValorAtual" formControlName="ValorAtual" min="0.01" currencyMask matInput required
                            autocomplete="off"
                            [options]="{ align: 'letft', prefix: 'R$ ', thousands: '.', decimal: ',' }">
                        <mat-error>Valor Atual é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.xs="100" fxFlex.sm="50">
                        <mat-label>Ônus (Financiado)</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <mat-select name="financiado" formControlName="Onus" matNativeControl required>
                            <mat-option value="S">Sim</mat-option>
                            <mat-option value="N">Não</mat-option>
                        </mat-select>
                        <mat-error>Ônus (Financiado) é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" [disabled]="form.invalid" class="bt"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

        <!--REFERÊNCIAS-->

        <div *ngIf="mainData == 'referenciasBancarias'">

            <form class="referenciasBancarias" [formGroup]="form">

                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row wap" fxLayout.md="row wap"
                    fxLayoutAlign="space-between start">

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="45" fxFlex.md="48" fxFlex="20">
                        <mat-label>Banco</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="20" name="Banco" formControlName="Banco" matInput required>
                        <mat-error>Banco é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="45" fxFlex.md="48" fxFlex="20">
                        <mat-label>Agência</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="20" name="Agência" formControlName="Agencia" pattern="[0-9]+" matInput
                            required>
                        <mat-error>Agência é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="45" fxFlex.md="48" fxFlex="40">
                        <mat-label>Número da Conta</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Numero" formControlName="Numero" matInput required>
                        <mat-error>Número da Conta é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="45" fxFlex.md="48" fxFlex="20">
                        <mat-label>Tipo da Conta</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <mat-select name="Conta" formControlName="Conta" matNativeControl required>
                            <mat-option value="C">Corrente</mat-option>
                            <mat-option value="P">Poupança</mat-option>
                        </mat-select>
                        <mat-error>Tipo da Conta é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" class="bt" [disabled]="form.invalid"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

        <div *ngIf="mainData == 'referenciasComerciais'">

            <form class="referenciasComerciais" [formGroup]="form">

                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row" fxLayout="row"
                    fxLayoutAlign="space-between start">

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="100" fxFlex="100">
                        <mat-label>Empresa</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="30" name="Empresa" matInput formControlName="Empresa" required>
                        <mat-error>Empresa é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row" fxLayout="row" fxLayoutAlign="start start">

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50" fxFlex="40">
                        <mat-label>Contato/Setor</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="30" name="ContatoSetor" matInput formControlName="ContatoSetor" required>
                        <mat-error>Contato/Setor é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50" fxFlex="30">
                        <mat-label>Cidade</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="30" name="Cidade" matInput formControlName="Cidade" required>
                        <mat-error>Cidade é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50" fxFlex="40">
                        <mat-label>Telefone/Celular</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input name="Telefone" formControlName="Telefone" [minlength]="isFixo() ? 10 : 11 " matInput
                            [mask]="getTelefoneMask()" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            required>
                        <mat-error *ngIf="form.controls.Telefone.hasError('required')">Telefone/Celular é obrigatório!
                        </mat-error>
                        <mat-error *ngIf="!form.controls.Telefone.hasError('required')">
                            Telefone/Celular é inválido!
                        </mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" [disabled]="form.invalid" class="bt"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

        <div *ngIf="mainData == 'referenciasPessoais'">

            <form class="referenciasPessoais" [formGroup]="form">

                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row" fxLayoutAlign="space-between start">

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Nome</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Nome" formControlName="Nome" matInput required>
                        <mat-error>Nome é obrigatório!</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.xs="row wap" fxLayout.sm="row" fxLayoutAlign="space-between start">

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50" fxFlex="60">
                        <mat-label>Endereço</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Endereço" formControlName="Endereco" matInput required>
                        <mat-error>Endereço é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="50" fxFlex="40">
                        <mat-label>Telefone/Celular</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input name="Telefone" formControlName="Telefone" [minlength]="isFixo() ? 10 : 11 " matInput
                            [mask]="getTelefoneMask()" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            required>
                        <mat-error *ngIf="form.controls.Telefone.hasError('required')">Telefone/Celular é obrigatório!
                        </mat-error>
                        <mat-error *ngIf="!form.controls.Telefone.hasError('required')">
                            Telefone/Celular é inválido!
                        </mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" class="bt" [disabled]="form.invalid"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

        <!--------------------------->

        <div *ngIf="mainData == 'pretensao'">

            <form class="pretensao" [formGroup]="form">

                <div fxLayout="row" fxLayoutAlign="start start">

                    <mat-form-field appearance="outline" fxFlex="30">
                        <mat-label>Categoria</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <mat-select name="Categoria" formControlName="Categoria"
                            (selectionChange)="setValidatorsPretensaoCNPJ(form.controls.Categoria.value)"
                            matNativeControl required>
                            <mat-option value="Moveis">Móveis</mat-option>
                            <!--Carros, Motos, Etc-->
                            <mat-option value="Servicos">Serviços</mat-option>
                            <mat-option value="Maquinas">Máquinas e Equipamentos</mat-option>
                            <mat-option value="BMM">Bens Móveis Múltiplos</mat-option>
                            <!--Sofá, Geladeira, Televisão-->
                            <mat-option value="Energia">Kit de Energia Fotovoltaica</mat-option>
                        </mat-select>
                        <mat-error>Categoria é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Descrição</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Descricao" matInput required formControlName="Descricao">
                        <mat-error>Descrição é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="25">
                        <mat-label>Valor</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input name="Valor" matInput required currencyMask min="0.01" autocomplete="off"
                            [options]="{ align: 'letft', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            formControlName="Valor">
                        <mat-error>Valor é obrigatório!</mat-error>
                    </mat-form-field>



                </div>

                <div fxLayout="row" fxLayoutAlign="start start">

                    <mat-form-field appearance="outline" fxFlex="100"
                        *ngIf="form.controls.Categoria.value == 'Maquinas' ||
                                                                                            form.controls.Categoria.value == 'Moveis' ||
                                                                                            form.controls.Categoria.value == 'BMM'">
                        <mat-label>Estado</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <mat-select name="estado" formControlName="Estado" required matNativeControl>
                            <mat-option value="N">Novo</mat-option>
                            <mat-option value="U">Usado</mat-option>
                        </mat-select>
                        <mat-error>Estado é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="form.controls.Categoria.value == 'Moveis' || 
                                                                                form.controls.Categoria.value == 'BMM'"
                        fxFlex="25">
                        <mat-label>Marca</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Marca" formControlName="Marca" required matInput>
                        <mat-error>Marca é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="form.controls.Categoria.value == 'Moveis' ||
                                                                                form.controls.Categoria.value == 'BMM'"
                        fxFlex="25">
                        <mat-label>Modelo</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input maxlength="50" name="Modelo" formControlName="Modelo" required matInput>
                        <mat-error>Modelo é obrigatório!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="form.controls.Categoria.value == 'Moveis'" fxFlex="25">
                        <mat-label>Ano</mat-label>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                        <input name="Ano" mask="0000" matInput formControlName="Ano" required>
                        <mat-error>Ano é obrigatório!</mat-error>
                    </mat-form-field>


                </div>

                <div fxLayout="row" fxLayoutAlign="end center">

                    <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                    <button mat-raised-button color="primary" [disabled]="form.invalid" class="bt"
                        (click)="save(form)">Salvar</button>

                </div>

            </form>

        </div>

    </div>

    <div *ngIf="action == 'delete'" mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar
        class="modalMsgDelete">

        <form [formGroup]="form">

            <input type="hidden" formControlName="id">

            <div fxLayout="row" style="place-content:center !important; padding-top: 10px; padding-bottom: 10px;">

                <h2>Deseja excluir esse item?</h2>

            </div>

            <div fxLayout="row" fxLayoutAlign="end center">

                <button mat-raised-button color="basic" class="bt" (click)="cancel()">Cancelar</button>
                <button mat-raised-button color="primary" class="bt" (click)="delete(form)">Excluir</button>

            </div>

        </form>

    </div>

    <div *ngIf="action == 'question'" mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar
        class="modalQuestion">

        <div class="cabecalho">
            <h2>Confirmar todos os dados preenchidos e enviar?</h2>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">

            <button mat-raised-button color="classic" class="bt" (click)="cancel()">Cancelar</button>
            <button mat-raised-button color="primary" class="bt" (click)="save(true)">Confirmar</button>

        </div>

    </div>

    <div *ngIf="action == 'invalid'" mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar
        class="modalQuestion">

        <div class="cabecalho">
            <h2>Dados Insuficientes!</h2>

        </div>

        <div class="cabecalho" *ngIf="exibeCampos">
            <h3>Por favor, conferir os seguintes itens:</h3>
        </div>

        <div class="grid">
            <ng-container class="items" *ngFor="let element of source">
                <div>
                    {{element}}
                </div>
            </ng-container>
        </div>

    </div>

    <div *ngIf="action == 'valid'" mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar class="modalQuestion">

        <div class="cabecalho">
            <h2>Dados Enviados para Análise!</h2>
            <h3>Por favor aguarde a notificação do analista</h3>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">

            <button mat-raised-button color="primary" class="bt" (click)="cancel()">Ok</button>

        </div>

    </div>

</div>