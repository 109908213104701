<div id="change-password" fxLayout="column">

    <div id="change-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="change-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div>

            <div class="title">ALTERAR SENHA</div>

            <form name="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" novalidate
                class="ls-form row">

                <mat-form-field appearance="outline">
                    <mat-label>Nova senha*</mat-label>
                    <input matInput cdkFocusInitial type="password" formControlName="NovaSenha">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="changePasswordForm.controls.NovaSenha.hasError('required')">
                        Senha incorreta!                   
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.controls.NovaSenha.hasError('minlength')">
                        A senha não é longa o suficiente, com no mínimo 6 caracteres!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="inputConfirmaSenha">
                    <mat-label>Repita a nova senha*</mat-label>
                    <input matInput type="password" formControlName="ConfirmaSenha">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="changePasswordForm.controls.ConfirmaSenha.hasError('required')">
                        Senha incorreta!                   
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.controls.ConfirmaSenha.hasError('minlength')">
                        A senha não é longa o suficiente, com no mínimo 6 caracteres!
                    </mat-error>
                </mat-form-field>



                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="changePasswordForm.invalid || disabled">
                    {{ button }}
                </button>

            </form>

        </div>

    </div>

</div>