import { Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FuseUtils } from '@fuse/utils';
import { ApiService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { FormGroup } from '@angular/forms';
import { CotasHistorico } from './cotasHistorico.model';
import { AuthService } from 'app/services/auth.service';
// import { CotasConsorciadoSelectedBarComponent } from './selected-bar/selected-bar.component';

@Injectable()
export class CotasHistoricoService implements Resolve<any>
{
    @ViewChild(MatSort) sortCotas: MatSort;
    onCotasConsorciadoChanged: BehaviorSubject<any>;
    onSelectedCotasConsorciadoChanged: BehaviorSubject<any>;
    onSelectedFiltroChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onPaginatorCotasLoad: Subject<any>;

    cotas: CotasHistorico[];
    situacoes: any[] = [];
    cotasBruto: any[] = [];
    cotasHistorico: CotasHistorico[];
    user: any;
    selectedCotasConsorciado: string[] = [];
    selectedFiltro: string[] = [];

    searchText: string;
    filterBy: FormGroup;
    filterData: any;

    localStorageUser = this.authService.getUser();

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private apiService: ApiService,
        private authService: AuthService
    ) {
        // Set the defaults
        this.onCotasConsorciadoChanged = new BehaviorSubject([]);
        this.onSelectedCotasConsorciadoChanged = new BehaviorSubject([]);
        this.onSelectedFiltroChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onPaginatorCotasLoad = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCotasHistorico([]),
            ]).then(
                ([files]) => {
                    resolve([]);
                },
                reject
            );
        });
    }

    /**
     * Get cotasConsorciado
     *
     * @returns {Promise<any>}
     */
    getCotasHistorico(CodigoCota): Promise<any>
    {
        this.filterData = {
            CodigoCota: parseInt(CodigoCota),
        };
        
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroPorEmpresa(`${environment.listHistorico}`, this.filterData, this.localStorageUser['IdEmpresa']).then((response: any) => {
                    if (response.success) {
                        this.cotasHistorico = response.data[0];
                        this.cotasHistorico = this.cotasHistorico.map(cotasHistorico => {
                            return new CotasHistorico(cotasHistorico);
                        });
                    } else {
                        this.cotasHistorico = [];
                    }

                    resolve(this.cotasHistorico);
                }, reject);
            }
        );
    }

    /**
     * addCotasHistorico
     * @returns {Promise<any>}
     */
    addCotasHistorico(data): Promise<any> | any {
        return new Promise((resolve) => {
            this.apiService.Gravar(`${environment.cotasHistorico_add}`, data)
            .then(async response => {
                if (response.success && data.UrlImagem.name !== undefined){
                    await this.apiService.uploadImagem(`${environment.historico_addimagem}`, response.data[0].IdHistorico, response.data[0].UrlImagem)
                    .then(() => {});
                }
                
                // this.getCotasHistorico([CodigoCota]);
                resolve(response);
            });
        });
    }

    /**
     * listSituacaoConsorciado
     *
     */
    public listSituacaoConsorciado() {
        this.apiService.BuscarComFiltroPorEmpresa(`${environment.situacao_list}`, {}, this.localStorageUser['IdEmpresa']).then((response: any) => {
            this.situacoes = response.data[0];
            return this.situacoes;
        });
    }

    /**
     * getSituacaoConsorciado
     */
     public getSituacaoConsorciado() {
        return this.situacoes;
    }

    /**
     * Get user data
     *
     * @returns {Promise<any>}
     */
    getUserData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.usuarios_plataforma_get}`, this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    this.user = response;
                    this.onUserDataChanged.next(this.user);
                    resolve(this.user);
                }, reject);
        });
    }

    /**
     * Toggle selected cotasConsorciado by id
     *
     * @param id
     */
    toggleSelectedCotasConsorciado(id): void {
        // First, check if we already have that cotasConsorciado as selected...
        if (this.selectedCotasConsorciado.length > 0) {
            const index = this.selectedCotasConsorciado.indexOf(id);

            if (index !== -1) {
                this.selectedCotasConsorciado.splice(index, 1);

                // Trigger the next event
                this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedCotasConsorciado.push(id);

        // Trigger the next event
        this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);
    }

    /**
     * Toggle selected cotasConsorciado by id
     *
     * @param id
     */
    toggleSelectedFiltro(id): void {
        // First, check if we already have that cotasConsorciado as selected...
        if (this.selectedFiltro.length > 0) {
            const index = this.selectedFiltro.indexOf(id);

            if (index !== -1) {
                this.selectedFiltro.splice(index, 1);

                // Trigger the next event
                this.onSelectedFiltroChanged.next(this.selectedFiltro);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedFiltro.push(id);

        // Trigger the next event
        this.onSelectedFiltroChanged.next(this.selectedFiltro);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedCotasConsorciado.length > 0) {
            this.deselectCotasConsorciado();
        }
        else {
            this.selectCotasConsorciado();
        }
    }

    /**
     * Select cotasConsorciado
     *
     * @param filterParameter
     * @param filterValue
     */
    selectCotasConsorciado(filterParameter?, filterValue?): void {
        this.selectedCotasConsorciado = [];

        // If there is no filter, select all cotasConsorciado
        if (filterParameter === undefined || filterValue === undefined) {
            // this.selectedCotasConsorciado = [];
            // this.cotasConsorciado.map(cotasConsorciado => {
            //     this.selectedCotasConsorciado.push(cotasConsorciado.CodigoCota.toString());
            // });
        }

        // Trigger the next event
        this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);
    }

    /**
     * Deselect cotasConsorciado
     */
    deselectCotasConsorciado(): void {
        this.selectedCotasConsorciado = [];
        this.selectedFiltro = [];

        // Trigger the next event
        this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);
        this.onSelectedFiltroChanged.next(this.selectedFiltro);
    }

    /**
     * Delete cotasConsorciado
     *
     * @param cotasConsorciado
     */
    deleteCotasConsorciado(cotasConsorciado): void {
        // this.apiService.Excluir(`${environment.cotasConsorciado_delete}`, cotasConsorciado.IdCotasConsorciado)
        // .then((response) => {
        //     if (response) {
        //         const cotasConsorciadoIndex = this.cotasConsorciado.indexOf(cotasConsorciado);
        //         this.cotasConsorciado.splice(cotasConsorciadoIndex, 1);
        //         this.onCotasConsorciadoChanged.next(this.cotasConsorciado);
        //     }
        // });
    }

    /**
     * Delete selected cotasConsorciado
     */
    deleteSelectedCotasConsorciado(): void {
        // for (const cotasConsorciadoId of this.selectedCotasConsorciado) {
        //     const cotasConsorciado = this.cotasConsorciado.find(_cotasConsorciado => {
        //         return _cotasConsorciado.CodigoCota.toString() === cotasConsorciadoId;
        //     });
        //     const cotasConsorciadoIndex = this.cotasConsorciado.indexOf(cotasConsorciado);
        //     this.cotasConsorciado.splice(cotasConsorciadoIndex, 1);
        // }
        // this.onCotasConsorciadoChanged.next(this.cotasConsorciado);
        // this.deselectCotasConsorciado();
    }
}
