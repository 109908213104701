// moment.js locale configuration
// locale : Spanish [es]

import * as momentNs from 'moment-timezone';
const moment = momentNs;

const monthsRegex = /^(janeiro|fevereiro|março|abril|maio|junho|julho|agosto|setembro|outubro|novembro|dezembro)/i;
const monthsShortRegex = /^(jan\.?|fev\.?|mar\.?|abr\.?|mai\.?|jun\.?|jul\.?|ago\.?|set\.?|out\.?|nov\.?|dez\.?)/i;
const monthsParse = [
  /^jan/i,
  /^fev/i,
  /^mar/i,
  /^abr/i,
  /^mai/i,
  /^jun/i,
  /^jul/i,
  /^ago/i,
  /^set/i,
  /^out/i,
  /^nov/i,
  /^dez/i
];

const es = {
  months: 'Janeiro Fevereiro Março Abril Maio Junho Julho Agosto Setembro Outubro Novembro Dezembro'.split(
    ' '
  ),
  monthsShort: 'Jan Fev Mar Abr Mai Jun Jul Ago Set Out Nov Dez'.split(' '),
  monthsRegex: new RegExp(monthsRegex.source + monthsShortRegex.source, 'i'),
  monthsShortRegex: monthsRegex,
  monthsStrictRegex: monthsRegex,
  monthsShortStrictRegex: monthsShortRegex,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: 'Domingo Segunda Terça Quarta Quinta Sexta Sábado'.split(' '),
  weekdaysShort: 'Dom Seg Ter Qua Qui Sex Sab'.split(' '),
  weekdaysMin: 'D S T Q Q S S'.split(' '),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'h:mm a',
    LTS: 'h:mm:ss a',
    L: 'DD/MM/YYYY',
    l: 'D/M/YYYY',
    LL: 'MMMM D, YYYY',
    ll: 'MMM D, YYYY',
    LLL: 'MMMM D [de] YYYY, [a las] h:mm a',
    lll: 'MMM D [de] YYYY [a las] h:mm a',
    LLLL: 'dddd, MMMM D [de] YYYY [a las] h:mm a',
    llll: 'ddd, MMM D [de] YYYY [a las] h:mm a'
  },
};

// ('es') IMPORTANT means spanish
moment.defineLocale('pt-BR', es);
moment.locale('pt-BR');