<div class="dialog-content-wrapper" class="modal-info">
  <mat-toolbar matDialogTitle class="accent-600 m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title ml-8">
        Informações - Campos Biometria Facial
      </span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog" style="margin-block: auto !important;">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div  mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar class="modalMsgDelete">

    <div fxLayout="row" style="place-content:center !important; padding-top: 10px; padding-bottom: 10px;">

      <div class="body">

        <div fxLayout="row wrap">
      
          <div fxFlex="100" fxFlex.md="100" fxLayoutGap="5px">
            <p class="pTxtInfoCampo">
              Nesta opção deverá ser selecionado a imagem desejada para realizar a consulta.
            </p>
          </div>

          <div fxFlex="100" fxFlex.md="100" fxLayoutGap="5px">
            <p class="pTxtInfoCampo">
              De acordo com a <b>documentação oficial do DataValid</b>, a imagem não deve estar borrada, danificada e com uma boa iluminação, além da resolução do rosto ser de no mínimo 250x250 pixels.
            </p>
          </div>

          <div fxFlex="100" fxFlex.md="100" fxLayoutGap="5px">
            <p class="pTxtInfoCampo">
              Para mais informações, basta clicar <a href="https://apidocs.datavalidp.estaleiro.serpro.gov.br/requisitos_imagens/" target="_blank"> neste link!</a> 
            </p>
          </div>

          <div fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxLayoutGap="5px" style="margin-top: 25px;">
            <p class="pTxtInfoCampo">
              <b>OBSERVAÇÕES:</b> Lembrando que, só será possível realizar consultas com imagem nos seguintes formatos: <b>.png e .jpg</b>.
            </p>
          </div>

        </div>  

      </div>

    </div>

  </div>

</div>
