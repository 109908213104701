import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router'; 
import { MatSnackBar } from '@angular/material/snack-bar';

import { Md5 } from 'md5-typescript';

import { AuthService } from 'app/services/auth.service';
import { FuncoesService } from 'app/services/funcoes.service';
import { CotasConsorciadoService } from 'app/main/apps/cotas/cotas-consorciado/cotasConsorciado.service';

@Component({
    selector     : 'login-consorciado',
    templateUrl  : './login-consorciado.component.html',
    styleUrls    : ['./login-consorciado.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginConsorciadoComponent implements OnInit
{
    loginForm: FormGroup;
    button = 'ACESSAR';
    disabled = false;
    visibility = 'visibility';
    type='password';
    data: any;
    statusEmailContemplacao: any = '';
    // mask
    maskCpfCnpj = '';

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public _cotasConsorciadoService: CotasConsorciadoService,
        private _formBuilder: FormBuilder,
        private auth: AuthService,
        private router: Router,
        private matSnackBar: MatSnackBar,
        private funcoes: FuncoesService,
        private authService: AuthService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        var urlAtual = window.location.href;
        var urlClass = new URL(urlAtual);

        this.data = {
            "Cota": urlClass.searchParams.get("cota"),
            "Grupo": urlClass.searchParams.get("grupo"),
            "Status": "C"
        }

        if (this.data.Cota != null) {
            this._cotasConsorciadoService.getEmailContemplacao(this.data).then(resp => {
                this.statusEmailContemplacao = resp;
                if (resp != "L") {
                    this._cotasConsorciadoService.atualizaEmailContemplacao(this.data).then(response => {
                    });
                }
            });
        }

        this.loginForm = this._formBuilder.group({
            Usuario   : ['', [Validators.required, Validators.minLength(11), FuncoesService.ValidaCPFCNPJ]],
            Senha: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(32)]],
            Ip: [''],
            Browser: [''],
            Acesso: ['']
        });
    }

    onSubmit(): void {
        if (this.loginForm.valid) {
            this.disabled = true;
            this.button = 'AGUARDE...';
            const login = this.auth.checkLogin(
                this.loginForm.value.Usuario,
                Md5.init(this.loginForm.value.Senha)
            );
            if (login) {
                if (login) {
                    this.router.navigate(['/']);
                } else {
                    this.button = 'ACESSAR';
                    this.disabled = false;
                    this.matSnackBar.open('CPF/CNPJ ou Senha Inválidos!', '', {
                        duration: 5000,
                    });
                }
            } else {
                this.dadosCliente();
            }
        }
    }

    isCPF(): boolean{
       return this.loginForm.value.Usuario == null ? true : this.loginForm.value.Usuario.length < 12 ? true : false;
    }

    getCpfCnpjMask(): string{
       return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }
    
    dadosCliente(): any {
        this.auth.getIp()
        .then((result) => {
            this.loginForm.value.Ip = result['ip'];
            this.loginForm.value.Browser = this.funcoes.browserName();
            this.gravar();
            if (this.data.Cota != null) {
                this.data.Status = "L"
                this._cotasConsorciadoService.atualizaEmailContemplacao(this.data);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    
    gravar(): any {
        this.loginForm.value.Acesso = 'P';
        this.authService.login(this.loginForm.value)
        .then((data) => {
            if (data) {
                this.button = 'ACESSAR';
                this.router.navigate(['/apps/cotas']);
            } else {
                this.button = 'ACESSAR';
                this.disabled = false;
            }
        });
    }

    isVisibility(){
        if (this.visibility === 'visibility') {
            this.visibility = 'visibility_off'
            this.type='text'
        } else {
            this.visibility = 'visibility'
            this.type='password'
        }
    }

    limpaEspaco(){
        this.loginForm.controls.Senha.setValue(this.loginForm.value.Senha.trim());        
    }

    
}//END_CLASS
