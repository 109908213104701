import { Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { FormGroup } from '@angular/forms';
import { CotasConsorciado } from './cotasConsorciado.model';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class CotasConsorciadoService implements Resolve<any>
{
    @ViewChild(MatSort) sortCotas: MatSort;
    onCotasConsorciadoChanged: BehaviorSubject<any>;
    onSelectedCotasConsorciadoChanged: BehaviorSubject<any>;
    onSelectedFiltroChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onPaginatorCotasLoad: Subject<any>;
    onSelectedSituacao: BehaviorSubject<any>;
    onPaginatorCotasAnalistaLoadData;
    cotas: CotasConsorciado[] = [];
    situacoes: any[] = [];
    cotasBruto: any[] = [];
    cotasConsorciado: CotasConsorciado[] = [];
    user: any;
    selectedCotasConsorciado: string[] = [];
    selectedFiltro: string[] = [];
    selectedSituacao: string[] = [];

    searchText: string;
    filterBy: FormGroup;
    pageIndex: BehaviorSubject<any>;
    filterData: any;

    localStorageUser = this.authService.getUser();


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private authService: AuthService
    ) {
        // Set the defaults
        this.onCotasConsorciadoChanged = new BehaviorSubject([]);
        this.onSelectedCotasConsorciadoChanged = new BehaviorSubject([]);
        this.onSelectedFiltroChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onPaginatorCotasLoad = new Subject();
        this.onSelectedSituacao = new BehaviorSubject([]);
        this.pageIndex = new BehaviorSubject([0]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCotasConsorciado(),
                this.listSituacaoConsorciado(),
                this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getCotasConsorciado();
                        this.tableScrollTop();
                    });

                    this.onFilterChanged.subscribe(async (filter: FormGroup) => {
                        // Se por acaso o filterBy não estiver vazio e o obj vindo não vier como undefined, concatena a varivavel
                        if ((typeof this.filterBy != 'undefined' && this.filterBy != null) && typeof filter != 'undefined') {
                            // Se não vier vazio as chaves da pesquisa, add dentro do filterBy
                            if (Object.keys(filter).length > 0) {
                                for (const key in filter) {

                                    this.filterBy[key] = filter[key];
                                }
                            }
                        }
                        // se não
                        else {
                            this.filterBy = filter;
                        }

                        await this.getCotasConsorciado();
                    }
                    ); // -- FIM, this.onFilterChanged.subscribe


                    this.onPaginatorCotasLoad.subscribe(async (filter: FormGroup) => {
                        // Se por acaso o filterBy não estiver vazio e o obj vindo não vier como undefined, concatena a varivavel
                        if ((typeof this.filterBy != 'undefined' && this.filterBy != null) && typeof filter != 'undefined') {
                            // Se não vier vazio as chaves da pesquisa, add dentro do filterBy
                            if (Object.keys(filter).length > 0) {
                                for (const key in filter) {

                                    this.filterBy[key] = filter[key];
                                }
                            }
                        }
                        // se não
                        else {
                            this.filterBy = filter;
                        }

                        await this.getCotasConsorciado();

                        // this.getCotasConsorciado();

                    });

                    resolve([]);

                },
                reject
            );
        });
    }

    editPessoaFisica(pessoa, files): Promise<any> | any {
        // Atualizar
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_editPessoaFisica}`, pessoa.IdUsuario, pessoa)
                .then(response => {
                    this.apiService.msg('Dados salvos com sucesso!', 5000);
                    resolve(response);
                });
            if ((files !== undefined && files !== null)) {
                this.apiService.uploadImagem(`${environment.usuarios_plataforma_addImagem}`, pessoa.IdUsuario, files)
                    .then((result) => {
                    });
            }
        });
    }

    getDocumentos(data) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.tipos_documentos_Consorciado}`, data)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosFromSituacao(idSituacao) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltro(`${environment.tipos_documentos_getBySituacao}`, idSituacao, this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosFromEmprego(idEmprego) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.get_situacao_empregaticia_docs}`, idEmprego)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosObrigatorios(filtro) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.pessoas_getDocumentos}`, filtro)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosAnalista(filtro) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.pessoas_getDocumentosAnalista}`, filtro)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addArquivo(filtro) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.pessoas_add_Documentos}`, filtro)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    inativaAnexo(IdDocumentoPessoa) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast2(`${environment.pessoas_inativar_Documento}`, IdDocumentoPessoa, {})
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    setDocumentos(data) {
        return new Promise((resolve, reject) => {
            this.apiService.GravarSemToast(`${environment.pessoas_set_Documentos}`, data)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getCotaByIdCanopus(idCanopus) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltro(`${environment.get_cota}`, idCanopus, this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosFromCotas(idCota) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltro(`${environment.tipos_documentos_getByCota}`, idCota, this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    uploadArquivo(data, idDoc) {
        // Gravar        
        return new Promise((resolve, reject) => {
            this.apiService.GravarAnexo(`${environment.pessoas_addFile}`, data, idDoc)
                .then(response => {
                    if (data.UrlDocumento != null) {
                        this.apiService.uploadImagemSemToast(
                            `${environment.arquivos_addFile}`,
                            response.data[0].IdAnaliseDadosPessoais,
                            data.UrlDocumento
                        )
                    }
                    resolve(response);
                });
        });
    }

    limparAnexo(IdDocumentoPessoa) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast2(`${environment.pessoas_limparAnexo}`, IdDocumentoPessoa, [])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
    * Table Scroll Top
    *
    * @returns {Promise<any>}
    */
    private tableScrollTop() {
        document.getElementById('tableCotas').scrollIntoView();
    }


    /**
     * Get cotasConsorciado
     *
     * @returns {Promise<any>}
     */
    getCotasConsorciado(): Promise<any> {
        const user = this.authService.getLocalStorageUser();

        this.filterData = {
            IdUsuario: user['CpfCnpj'],
            Usuario: user['SenhaSemCrip']
        };
       

        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.listCotas}`, this.filterData)
                .then((response: any) => {

                    this.cotasConsorciado = [];
                    this.cotasBruto = [];
                    if (response.success) {

                        response.data[0].forEach(element => {
                            if ((element.DataContemplacao !== '' && element.DataContemplacao !== null) && (element.DataEntregaBem === '' || element.DataEntregaBem == null) && ((element.TipoContemplacao == 'S' && element.ParcelasPagas > 0) || (element.TipoContemplacao == 'L' && element.LancePago == 'S'))) {
                                this.cotasConsorciado.push(element);
                                this.cotasBruto.push(element);
                            }
                        });

                        // this.cotasConsorciado = response.data[0];
                        // this.cotasBruto = response.data[0];
                        if (this.cotasConsorciado.length > 0) {
                            if (this.searchText && this.searchText !== '') {
                                this.cotasConsorciado = this.cotasConsorciado.filter
                                    (
                                        name => (
                                            name.NomeProduto.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
                                            (name.Situacao.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
                                            (name.CodigoGrupo.toString().toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
                                            (name.CodigoCota.toString().toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
                                            (name.CreditoAtualizado?.toString().toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
                                            )
                                    );
                            }

                            this.cotasConsorciado = this.cotasConsorciado.map(cotasConsorciado => {
                                return new CotasConsorciado(cotasConsorciado);
                            });

                            this.onCotasConsorciadoChanged.next(this.cotasConsorciado);
                        }

                    } else {
                        this.cotasConsorciado = [];
                        this.onCotasConsorciadoChanged.next(this.cotasConsorciado);
                    }

                    resolve(this.cotasConsorciado);
                }, reject);
        }
        );
    }

    /**
     * filter CotasConsorciado
     *
     */
    filterCotasConsorciado(dadosFiltro) {
        let cotasFiltro: any = this.cotasConsorciado;
        let cotasFiltradas = [];

        let DadosFiltrados = cotasFiltro.filter(cota =>
            (dadosFiltro.Grupo && dadosFiltro.Grupo !== '' ? Number(cota.CodigoGrupo) === Number(dadosFiltro.Grupo) : true) &&
            (dadosFiltro.Cota && dadosFiltro.Cota !== '' ? Number(cota.CodigoCota) === Number(dadosFiltro.Cota) : true) &&
            (dadosFiltro.Situacao && dadosFiltro.Situacao !== '' ? Number(cota.IdSituacao) === Number(dadosFiltro.Situacao) : true)
        );

        if (DadosFiltrados.length > 0) {
            cotasFiltradas = DadosFiltrados
        } else {
            cotasFiltradas = []
        }

        // Retirando itens repetidos
        cotasFiltradas = cotasFiltradas.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        });

        // FIM, Filtragem dos dados
        this.onCotasConsorciadoChanged.next(cotasFiltradas);
    }

    /**
     * listSituacaoConsorciado
     *
     */
    public listSituacaoConsorciado() {
        this.apiService.BuscarComFiltroPorEmpresa(`${environment.situacao_list}`, {}, this.localStorageUser['IdEmpresa']).then((response: any) => {
            this.situacoes = response.data[0];
            return this.situacoes;
        });
    }

    /**
     * getSituacaoConsorciado
     */
    public getSituacaoConsorciado() {
        return this.situacoes;
    }

    /**
     * Get user data
     *
     * @returns {Promise<any>}
     */
    getUserData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.usuarios_plataforma_get}`, this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    this.user = response;
                    this.onUserDataChanged.next(this.user);
                    resolve(this.user);
                }, reject);
        });
    }

    getPessoaBycpfCNPJ() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltro(`${environment.pessoas_getByCpfCnpj}`, this.localStorageUser['CpfCnpj'] + '/' + this.localStorageUser['IdEmpresa'], this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarDadosConsorciado(dados) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroPorUsuario(`${environment.pessoas_dadosConsorciado}`, dados, '')
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getEnderecoByPessoa(pessoa) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltro(`${environment.pessoas_get_Endereco}`, pessoa, this.localStorageUser['IdUsuario'])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    editIdentificacaoPessoaFisica(pessoa, files): Promise<any> | any {
        return new Promise((resolve, reject) => {

            if (files == null && pessoa.UrlImagem != 'assets/images/avatars/profile.jpg') {
                this.apiService.AtualizarSemToast(`${environment.pessoas_editPessoaFisica}`, pessoa.IdPessoaFisica, pessoa).then(response => {
                    resolve(response);

                }).catch(error => {
                    resolve(error);
                });
            } else {
                this.apiService.uploadImagemDataValid(`${environment.pessoas_addImagem}`, pessoa.IdPessoaFisica, files).then(async (result) => {
                    this.apiService.AtualizarSemToast(`${environment.pessoas_editPessoaFisica}`, pessoa.IdPessoaFisica, pessoa).then(response => { });

                    resolve(result);

                }).catch(error => {
                    var erroDV = error.error.details.exception.split('Exception: ')[1].split(' in ')[0];
                    resolve(erroDV);
                });
            }
        });

    }

    editCota(idCota, cota): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.edit_cota}`, idCota, cota)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editContato(pessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_contato}`, pessoa.IdPessoa, pessoa)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    editContatoPJ(pessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_contatoPJ}`, pessoa.IdPessoa, pessoa)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    editContatoPF(pessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_contatoPF}`, pessoa.IdPessoa, pessoa)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    editIdentificacaoPessoaJuridica(pessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_editPessoaJuridica}`, pessoa.IdPessoaJuridica, pessoa)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    editEndereco(endereco): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_Endereco}`, endereco.IdEndereco, endereco)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });
    }

    editPpeCnpj(ppe): Promise<any> | any {
        console.log(ppe);
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_PpeCNPJ}`, ppe.IdPpe, ppe)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    editPpeCpf(ppe): Promise<any> | any {
        console.log(ppe);
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_PpeCPF}`, ppe.IdPpe, ppe)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    editRenda(renda): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_Renda}`, renda.IdRenda, renda)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });

    }

    getOutrosRendimentos(idRenda): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_OutrosRendimentos}`, idRenda)
                .then(response => {
                    resolve(response);
                });
        });

    }

    getSituacaoEmpregaticia(SituacaoEmpregaticia): Promise<any> | any {
        return new Promise((resolve, reject) => {
            if (SituacaoEmpregaticia == null || SituacaoEmpregaticia == '') {
                resolve('-');
            }
            else {
                this.apiService.GetSituacoesEmpregaticiasById(`${environment.get_situacao_empregaticia_byId}`, SituacaoEmpregaticia)
                    .then((response: any) => {
                        resolve(response[0][0].nome);
                    }, reject)
            }
        });
    }

    enviaFormulario(idLink): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_enviaFormulario}`, idLink)
                .then(response => {
                    resolve(response);
                });
        });

    }

    addOutrosRendimentos(OutroRendimento): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_OutrosRendimentos}`, OutroRendimento)
                .then(response => {
                    resolve(response);
                });
        });

    }

    editOutrosRendimentos(OutroRendimento): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_OutrosRendimentos}`,
                OutroRendimento.IdOutros_rendimentos, OutroRendimento)
                .then(response => {

                    resolve(response);
                });
        });

    }

    deleteOutrosRendimentos(OutroRendimento): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_OutrosRendimentos}`, OutroRendimento)
                .then(response => {
                    resolve(response);
                });
        });

    }

    deleteOutrosRendimentosByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_OutrosRendimentosByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });

    }

    //------------------------------------------------------

    editConjuge(conjuge): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_Conjuge}`, conjuge.IdConjuge, conjuge)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });
    }

    getConjuge(idRenda): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_OutrosRendimentos}`, idRenda)
                .then(response => {
                    resolve(response);
                });
        });

    }

    addConjuge(OutroRendimento): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_OutrosRendimentos}`, OutroRendimento)
                .then(response => {
                    resolve(response);
                });
        });

    }



    deleteConjuge(OutroRendimento): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_OutrosRendimentos}`, OutroRendimento)
                .then(response => {
                    resolve(response);
                });
        });

    }

    //--------------------------------------------

    editPatrimonio(patrimonio): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_Patrimonio}`, patrimonio.IdPatrimonio, patrimonio)
                .then(response => {
                    // this.getPessoaBycpfCNPJ();
                    resolve(response);
                });
        });
    }

    getPatrimonio(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_Patrimonio}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });

    }

    addPatrimonio(Patrimonio): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_Patrimonio}`, Patrimonio)
                .then(response => {
                    resolve(response);
                });
        });

    }

    deletePatrimonio(Patrimonio): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_Patrimonio}`, Patrimonio)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteDocumentos(filtro): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.pessoas_delete_Documentos}`, filtro)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deletePatrimonioByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_PatrimonioByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });

    }

    deleteDocumentoByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_DocumentoByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });

    }

    //------------------------------------------------------------------

    getParenteByPpe(idPpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_Parentes}`, idPpe)
                .then(response => {
                    resolve(response);
                });
        });

    }

    addParente(ParentePpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_Parentes}`, ParentePpe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editParente(ParentePpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_Parentes}`, ParentePpe.Idparenteppe, ParentePpe)
                .then(response => {

                    resolve(response);
                });
        });
    }

    deleteParente(idparenteppe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_Parente}`, idparenteppe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getSociosByPpe(idPpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_SociosByPpe}`, idPpe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    addSocios(SocioPpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_Socios}`, SocioPpe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteSocios(idsocio): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_Socios}`, idsocio)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editSociosByPpe(SocioPpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_SocioPpe}`, SocioPpe.IdsociosPPE, SocioPpe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteSociosByPpe(idPpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_SociosByPpe}`, idPpe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getSociosCotistas(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_SociosCotistas}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addSociosCotistas(SocioCotistas): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_SociosCotistas}`, SocioCotistas)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editSociosCotistas(SocioCotista): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_SociosCotistas}`, SocioCotista.IdSocio, SocioCotista)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteSociosCotistas(idPpe): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_SociosCotistas}`, idPpe)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getEmpresasColigadas(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_EmpresasColigadas}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addEmpresasColigadas(EmpresaColigada): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_EmpresasColigadas}`, EmpresaColigada)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editEmpresasColigadas(EmpresaColigada): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_EmpresasColigadas}`, EmpresaColigada.IdEmpresaColigada, EmpresaColigada)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteEmpresasColigadas(IdEmpresaColigada): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_EmpresasColigadas}`, IdEmpresaColigada)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getProdutosServicos(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_ProdutosServicos}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addProdutosServicos(ProdutoServico): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ProdutosServicos}`, ProdutoServico)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editProdutosServicos(ProdutoServico): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_ProdutoServico}`, ProdutoServico.IdProdutoServico, ProdutoServico)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteProdutosServicos(IdProdutoServico): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_ProdutosServicos}`, IdProdutoServico)
                .then(response => {
                    resolve(response);
                });
        });
    }


    getContasBancarias(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_ContasBancarias}`, idPessoa)
                .then((response: any) => {
                    if (response.hasOwnProperty('success') && response.success) {
                        resolve(response.data[0]);
                    } else {
                        resolve(null);
                    }
                }, reject);
        });
    }

    addContasBancarias(ContaBancaria, cpfCNPJ): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ContasBancarias}` + cpfCNPJ.replace(".", ""), ContaBancaria)
                .then(response => {
                    resolve(response);
                });
        });
    }

    addContasBancariasAvalista(ContaBancaria): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ContasBancarias_Avalista}`, ContaBancaria)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editContasBancarias(ContaBancaria): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_ContasBancarias}`, ContaBancaria.IdReferenciaBancaria, ContaBancaria)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteContasBancarias(IdContaBancaria): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_ContasBancarias}`, IdContaBancaria)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteContasBancariasByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_ContasBancariasByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getReferenciasPessoais(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_ReferenciasPessoais}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addReferenciasPessoais(referenciaPessoal, cpfCNPJ): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ReferenciasPessoais}` + cpfCNPJ.replace(".", ""), referenciaPessoal)
                .then(response => {
                    resolve(response);
                });
        });
    }

    addReferenciasPessoaisAvalista(referenciaPessoal): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ReferenciasPessoais_Avalista}`, referenciaPessoal)
                .then(response => {
                    resolve(response);
                });
        });
    }


    editReferenciasPessoais(referenciaPessoal): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_ReferenciasPessoais}`, referenciaPessoal.IdReferenciaPessoal, referenciaPessoal)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteReferenciasPessoais(IdReferenciaPessoal): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_ReferenciasPessoais}`, IdReferenciaPessoal)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteReferenciasPessoaisByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_ReferenciasPessoaisByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getReferenciasComerciais(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_ReferenciasComerciais}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addReferenciasComerciais(referenciaComercial, cpfCNPJ): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ReferenciasComerciais}` + cpfCNPJ.replace(".", ""), referenciaComercial)
                .then(response => {
                    resolve(response);
                });
        });
    }

    uploadFotoAvalista(IdPessoaFisica, files, form): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.GravarSemToast(`${environment.pessoas_save_avalista}`, form).then(response => {
                if (response.success) {
                    this.apiService.uploadImagemDataValid(`${environment.pessoas_addImagem}`, IdPessoaFisica, files)
                        .then((result) => {
                            resolve(result)
                        }).catch(error => {
                            var erroDV = error.error.details.exception.split('Exception: ')[1].split(' in ')[0];
                            resolve(erroDV);
                        });
                }
            })
        })
    }

    saveAvalista(form): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_save_avalista}`, form).then(response => {
                resolve(response);
            })
        })
    }

    addReferenciasComerciaisAvalista(referenciaComercial): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_ReferenciasComerciais_Avalista}`, referenciaComercial)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editReferenciasComerciais(referenciaComercial): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_ReferenciasComerciais}`, referenciaComercial.IdReferenciaComercial, referenciaComercial)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteReferenciasComerciais(IdReferenciaComercial): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_ReferenciasComerciais}`, IdReferenciaComercial)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteReferenciasComerciaisByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_ReferenciasComerciaisByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getPretensaoCompra(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_PretensaoCompra}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addPretensaoCompra(PretensaoCompra, cpfCNPJ): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_PretensaoCompra}` + cpfCNPJ.replace(".", ""), PretensaoCompra)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editPretensaoCompra(PretensaoCompra): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_PretensaoCompra}`, PretensaoCompra.IdPretencaoCompra, PretensaoCompra)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deletePretensaoCompra(IdPretensaoCompra): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_PretensaoCompra}`, IdPretensaoCompra)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getBensImoveis(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_BensImoveis}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addBensImoveis(BemImovel, cpfCNPJ): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_BensImoveis}` + cpfCNPJ.replace(".", ""), BemImovel)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editBensImoveis(BemImovel): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_BensImoveis}`, BemImovel.IdBemImovel, BemImovel)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteBensImoveis(IdBemImovel): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_BensImoveis}`, IdBemImovel)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getBensMoveis(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_BensMoveis}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addBensMoveis(BemMovel): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_BensMoveis}`, BemMovel)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editBensMoveis(BemMovel): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_BensMoveis}`, BemMovel.IdBemMovel, BemMovel)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getDependentes(idPessoa): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarPorId(`${environment.pessoas_get_Dependentes}`, idPessoa)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addDependentes(Dependentes): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.pessoas_add_Dependentes}`, Dependentes)
                .then(response => {
                    resolve(response);
                });
        });
    }

    editDependentes(Dependentes): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Atualizar(`${environment.pessoas_edit_Dependentes}`, Dependentes.IdDependentes, Dependentes)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteDependentes(IdDependentes): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_Dependentes}`, IdDependentes)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteDependentesByIdPessoa(IdPessoaFisica): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.ExcluirSemToast(`${environment.pessoas_delete_DependentesByIdPessoa}`, IdPessoaFisica)
                .then(response => {
                    resolve(response);
                });
        });
    }

    deleteBensMoveis(IdBemMovel): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.Excluir(`${environment.pessoas_delete_BensMoveis}`, IdBemMovel)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getRamos() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarTodos(`${environment.pessoas_getRamos}`)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Toggle selected cotasConsorciado by id
     *
     * @param id
     */
    toggleSelectedCotasConsorciado(id): void {
        // First, check if we already have that cotasConsorciado as selected...
        if (this.selectedCotasConsorciado.length > 0) {
            const index = this.selectedCotasConsorciado.indexOf(id);

            if (index !== -1) {
                this.selectedCotasConsorciado.splice(index, 1);

                // Trigger the next event
                this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedCotasConsorciado.push(id);

        // Trigger the next event
        this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);
    }

    /**
     * Toggle selected cotasConsorciado by id
     *
     * @param id
     */
    toggleSelectedFiltro(id): void {
        // First, check if we already have that cotasConsorciado as selected...
        if (this.selectedFiltro.length > 0) {
            const index = this.selectedFiltro.indexOf(id);

            if (index !== -1) {
                this.selectedFiltro.splice(index, 1);

                // Trigger the next event
                this.onSelectedFiltroChanged.next(this.selectedFiltro);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedFiltro.push(id);

        // Trigger the next event
        this.onSelectedFiltroChanged.next(this.selectedFiltro);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedCotasConsorciado.length > 0) {
            this.deselectCotasConsorciado();
        }
        else {
            this.selectCotasConsorciado();
        }
    }

    /**
     * Select cotasConsorciado
     *
     * @param filterParameter
     * @param filterValue
     */
    selectCotasConsorciado(filterParameter?, filterValue?): void {
        this.selectedCotasConsorciado = [];

        // If there is no filter, select all cotasConsorciado
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedCotasConsorciado = [];
            this.cotasConsorciado.map(cotasConsorciado => {
                this.selectedCotasConsorciado.push(cotasConsorciado.CodigoCota.toString());
            });
        }

        // Trigger the next event
        this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);
    }

    editarConclusao(idCota, dados): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.edit_conclusao}`, idCota, dados)
                .then(response => {
                    resolve(response);
                });
        });
    }

    /**
     * Update cotasConsorciado
     *
     * @param cotasConsorciado
     * @returns {Promise<any>}
     */
    updateCotasConsorciado(cotasConsorciado): Promise<any> | any {
        // if (cotasConsorciado.IdCotasConsorciado == null) {
        //     // Adicionar
        //     return new Promise((resolve, reject) => {
        //         this.apiService.Gravar(`${environment.cotasConsorciado_add}`, cotasConsorciado)
        //         .then(response => {
        //             this.getCotasConsorciado();
        //             resolve(response);
        //         });
        //     });
        // } else {
        //     // Atualizar
        //     return new Promise((resolve, reject) => {
        //         this.apiService.Atualizar(`${environment.cotasConsorciado_edit}`, cotasConsorciado.IdCotasConsorciado, cotasConsorciado)
        //         .then(response => {
        //             this.getCotasConsorciado();
        //             resolve(response);
        //         });
        //     });
        // }
    }

    /**
     * Deselect cotasConsorciado
     */
    deselectCotasConsorciado(): void {
        this.selectedCotasConsorciado = [];
        this.selectedFiltro = [];

        // Trigger the next event
        this.onSelectedCotasConsorciadoChanged.next(this.selectedCotasConsorciado);
        this.onSelectedFiltroChanged.next(this.selectedFiltro);
    }

    /**
     * Delete cotasConsorciado
     *
     * @param cotasConsorciado
     */
    deleteCotasConsorciado(cotasConsorciado): void {
        // this.apiService.Excluir(`${environment.cotasConsorciado_delete}`, cotasConsorciado.IdCotasConsorciado)
        // .then((response) => {
        //     if (response) {
        //         const cotasConsorciadoIndex = this.cotasConsorciado.indexOf(cotasConsorciado);
        //         this.cotasConsorciado.splice(cotasConsorciadoIndex, 1);
        //         this.onCotasConsorciadoChanged.next(this.cotasConsorciado);
        //     }
        // });
    }

    /**
     * Delete selected cotasConsorciado
     */
    deleteSelectedCotasConsorciado(): void {
        for (const cotasConsorciadoId of this.selectedCotasConsorciado) {
            const cotasConsorciado = this.cotasConsorciado.find(_cotasConsorciado => {
                return _cotasConsorciado.CodigoCota.toString() === cotasConsorciadoId;
            });
            const cotasConsorciadoIndex = this.cotasConsorciado.indexOf(cotasConsorciado);
            this.cotasConsorciado.splice(cotasConsorciadoIndex, 1);
        }
        this.onCotasConsorciadoChanged.next(this.cotasConsorciado);
        this.deselectCotasConsorciado();
    }

    async sortTable() {
        await this.getCotasConsorciado();

        if (this.cotasBruto.length == 0) {
            return this.cotas;
        }

        let data = this.cotasBruto.slice();

        let dadosTabela: any[] = data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this.sortCotas.active) {
                case 'grupo':
                    [propertyA, propertyB] = [a.Grupo, b.Grupo];
                    break;
                case 'cota':
                    [propertyA, propertyB] = [a.Cota, b.Cota];
                    break;
                case 'produto':
                    [propertyA, propertyB] = [a.Produto, b.Produto];
                    break;
                case 'creditoAtualizado':
                    [propertyA, propertyB] = [a.CreditoAtualizado, b.CreditoAtualizado];
                    break;
                case 'situacao':
                    [propertyA, propertyB] = [a.Situacao, b.Situacao];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this.sortCotas.direction === 'asc' ? 1 : -1);
        });

        return dadosTabela.map(cotas => {
            return new CotasConsorciado(cotas);
        });
    }

    atualizaEmailContemplacao(data): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.EmailContemplacao(`${environment.email_contemplacao}`, data)
                .then(response => {
                    resolve(response);
                });
        });
    }

    getEmailContemplacao(data): Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.apiService.EmailContemplacao(`${environment.get_email_contemplacao}`, data)
                .then(response => {
                    resolve(response[0].EmailContemplacao);
                });
        });
    }

    inativaTodosAnexos(IdPessoaFisica) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.pessoas_inativar_Todos_Documento}`, IdPessoaFisica, {})
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }


    // BENS PAGAMENTOS

    consultarBensPagamentos(dados: { CpfCnpj: string, Grupo: number, Cota: number, Versao: number }) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_list_by_cota}`, dados)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarBem(dados: { codigo_Bem: number, codigo_Grupo: number, codigo_Cota: number, versao: number }) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_get}`, dados)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarFabricantes() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_list_fabricantes}`, [])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarCombustiveis() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_list_combustiveis}`, [])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarCidades() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_list_cidades}`, [])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarTiposPagamentos() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_list_tipos_pagamentos}`, [])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarFornecedores() {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bens_list_fornecedores}`, [])
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    consultarAquisicaoBem(data) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.aquisicao_bem}`, data)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addAquisicaoBem(data) {
        return new Promise((resolve, reject) => {
            this.apiService.Gravar(`${environment.add_aquisicao_bem}`, data)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    editAquisicaoBem(data) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.edit_aquisicao_bem}`, data.IdAquisicaoBem, data)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    removeAquisicaoBem(IdAquisicaoBem) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.remove_aquisicao_bem}`, IdAquisicaoBem, {})
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    setDocumentosBem(dados: any) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bem_documento_set}`, dados)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosBem(dados: any) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bem_documento_get}`, dados)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addArquivoBem(data) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bem_documento_add}`, data)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentosAnalistaBem(filtro) {
        return new Promise((resolve, reject) => {
            this.apiService.BuscarComFiltroSemEmpresa(`${environment.bem_documento_analista_get}`, filtro)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    inativaAnexoBem(IdDocumentoBem) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.bem_documento_remove_anexo}`, IdDocumentoBem, {})
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    statusEtapa(id, situacao) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast(`${environment.bem_atualiza_status_etapa}`, id, situacao)
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    inativarCotaBem(id) {
        return new Promise((resolve, reject) => {
            this.apiService.AtualizarSemToast3(`${environment.remove_cota_vinculada_bem}`, id, {})
                .then((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    adicionarConjuge(dados) {
        return new Promise((resolve, reject) => {
            this.apiService.GravarSemToast(`${environment.pessoas_add_Conjuge}`, dados)
                .then(response => {
                    resolve(response);
                });
        });
    }

    unsubscribeServices(){
        this.onPaginatorCotasAnalistaLoadData.unsubscribe();
    } 





}//END_CLASS
