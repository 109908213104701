import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private _matSnackBar: MatSnackBar
  ) { }

  Gravar(rota: string, dados): any {
    return this.http.post(`${environment.api_url}${rota}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data['success']) {
          this.msg('Dados adicionados com sucesso!', 5000);
          return data;
        } else {
          switch (data['code']) {
            case '78cb26b8-d927-4452-b283-13adeda9ca48':
            case '9d6c8b87-290c-4b6f-beb3-7299af891a42':
              this.msg(data['details'], 5000);
              break;
            default:
              this.msg('Não foi possível salvar, tente novamente!', 5000);
              break;
          }

          return data;
        }
      });
  }

  GravarAnexo(rota: string, dados, idDoc): any {
    return this.http.post(`${environment.api_url}${rota}`+idDoc, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data['success']) {
          this.msg('Arquivo adicionado com sucesso!', 5000);
          return data;
        } else {
          if(data == 'Você não enviou nenhum Documento!'){
            //Nenhum documento no anexo
          } else {
            switch (data['code']) {
              case '78cb26b8-d927-4452-b283-13adeda9ca48':
              case '9d6c8b87-290c-4b6f-beb3-7299af891a42':
                this.msg(data['details'], 5000);
                break;
              default:
                this.msg('Não foi possível salvar, tente novamente!', 5000);
                break;
            }
          }

          return data;
        }
      });
  }

  GravarSemToast(rota: string, dados): any {
    return this.http.post(`${environment.api_url}${rota}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        return data;
      });
  }

  uploadImagem(rota: string, id, file): any {
    return this.http.post(`${environment.api_url}${rota}${id}`, file,
      { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data) {
          return true;
        } else {
          return data;
        }
      });
  }

  uploadImagemSemToast(rota: string, id, file): any {
    return this.http.post(`${environment.api_url}${rota}${id}`, file,
      {headers: environment.api_headers})
    .toPromise()
    .then(data => {
      if (data) {
        return true;
      } else {
        return data;
      }
    });
  }

  VerificaImagemExiste(rota: string, nomeFile: string) {
    return this.http.get<any>(`${environment.api_url}${rota}${nomeFile}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  BuscarTodos(rota: string): any {
    return this.http.get<any>(`${environment.api_url}${rota}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  BuscarTodosPorEmpresa(rota: string, idEmpresa: number): any {
    return this.http.get<any>(`${environment.api_url}${rota}${idEmpresa}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  BuscarTodosPorIdCotaCanopus(rota: string, IdCotaCanopus): any {
    return this.http.post<any>(environment.api_url+rota+IdCotaCanopus,{}, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  BuscarTodosPorEmpresaEIdUsuario(rota: string, idEmpresa: number, idUsuario: number): any {
    return this.http.get<any>(`${environment.api_url}${rota}${idEmpresa}/${idUsuario}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  BuscarComFiltro(rota: string, filter, user): any {
    return this.http.post<any>(environment.api_url+rota+filter, user ,{ headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  BuscarComFiltroPorEmpresa(rota: string, filter, idEmpresa: number): any {
    return this.http.post<any>(`${environment.api_url}${rota}${idEmpresa}`,filter, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }


  BuscarComFiltroSemEmpresa(rota: string, filter): any {
    return this.http.post<any>(`${environment.api_url}${rota}`,filter, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  BuscarComFiltroPorEmpresaEIdUsuario(rota: string, filter, idEmpresa: number, idUsuario: number): any {
    return this.http.post<any>(`${environment.api_url}${rota}${idEmpresa}/${idUsuario}`, filter, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  BuscarComFiltroPorUsuario(rota: string, filter, idUsuario: string): any {
    return this.http.post<any>(`${environment.api_url}${rota}${idUsuario}`, filter, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  GetAllCotas(rota: string): any {
    return this.http.get<any>(`${environment.api_url}${rota}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }


  GetAllAnalista(rota: string): any {
    return this.http.get<any>(`${environment.api_url}${rota}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  DownloadRelatorioCota(rota: string, data){
    return this.http.post(`${environment.api_url}${rota}`,data, {
      responseType: 'blob' as 'json',
      headers: environment.api_headers
    }).toPromise();
  }

  BuscarPorId(rota: string, id: number): any {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  BuscarLinkPorId(rota: string, dados:any): any {
        
    return this.http.post<any>(`${environment.api_url}${rota}${dados.IdCotaUsuario }`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  BuscarPorCotasAnalista(rota: string, id: number, id2): any {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`+'/'+id2, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  BuscarPorId_E_Empresa(rota: string, id: number, idEmpresa: number): any {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`+'/'+idEmpresa, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  getDocumentosBem(dados: any) {
    return this.http.post<any>(`${environment.api_url}${environment.documentos_bem}`, dados, { headers: environment.api_headers });
 }  

  getDocumentosAnalistaBem(dados: any) {
    return this.http.post<any>(`${environment.api_url}${environment.documentos_analista_bem}`, dados, { headers: environment.api_headers });
  }   

  Download(rota: string, id: number, fileName: string): any {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`, { responseType: 'blob' as 'json', headers: environment.api_headers })
      .toPromise()
      .then((data) => {
        const file = new Blob([data], { type: data.type });
        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blob);
        link.remove();
      });
  }

  Atualizar(rota: string, id: number, dados): any {

    return this.http.put<any>(`${environment.api_url}${rota}${id}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados Atualizados com sucesso!', 5000);
          return data;
        } else if (data.hasOwnProperty('details') && data.details == 'Já existe um usuário cadastrado com o email informado!') {
          this.msg(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('details') && data.details == 'Já existe um usuário cadastrado com o CPF/CNPJ informado!') {
          this.msg(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('code')) {
          switch (data.code) {
            case '78cb26b8-d927-4452-b283-13adeda9ca48':
            case '9d6c8b87-290c-4b6f-beb3-7299af891a42':
            case 'fdaf5b13-1d3c-487d-b020-ceb0169a53cd':
              this.msg(data.details, 5000);
              break;
            default:
              this.msg('Não foi possível atualizar, tente novamente!', 5000);
              break;
          }

          return data;
        } else {
          this.msg('Não foi possível atualizar, tente novamente!', 5000);
          return data;
        }
      })
      .catch((resp) => {
        this.msg('Não foi possível atualizar, tente novamente!', 5000);
      });
  }

  AtualizarPorIdUsuario(rota: string, id: number, idUsuario: number, dados): any {
    return this.http.put<any>(`${environment.api_url}${rota}${id}/${idUsuario}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        return data
      })
      .catch((resp) => {
        this.msg('Não foi possível atualizar, tente novamente!', 5000);
      });
  }

  AtualizarSemToast(rota: string, id: number, dados): any {
    return this.http.put<any>(`${environment.api_url}${rota}${id}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        return data
      })
      .catch((resp) => {
        this.msg('Não foi possível atualizar, tente novamente!', 5000);
      });
  }

  AtualizarSemToast2(rota: string, id: number, dados): any {
    return this.http.put<any>(`${environment.api_url}${rota}${id}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Arquivo removido com sucesso!', 5000);
          return data;
        }
      });
  }

  consultarBemCota(filtro) {
    return this.http.post(`${environment.api_url}${environment.buscar_bem_cota}`, filtro, { headers: environment.api_headers });
  }

  getMensagensSituacaoCotaUsuario(rota: string, id: number, dados): any {
    return this.http.put<any>(`${environment.api_url}${rota}${id}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        }
      });
  }

  Excluir(rota: string, id: number): any {
    return this.http.delete<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados excluídos com sucesso!', 5000);
          return true;
        } else if (data.hasOwnProperty('details') && data.details == 'Não é possível excluir um status fixo.') {
          this.msg(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('details') && data.details == 'Não foi possível excluir, pois este item está relacionado a outros itens.') {
          this.msg(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('code')) {
          switch (data.code) {
            case 'dc132a54-44f2-4563-b4e4-57fd5da85b86':
            case '8bcd3147-46b6-4f45-9895-e66406466586':
            case '8bcd3147-46b6-4f45-9895-e66406466587':
              this.msg(data.details, 5000);
              break;
            default:
              this.msg('Não foi possível atualizar tente novamente!', 5000);
              break;
          }

          return false;
        } else {
          this.msg('Não foi possível excluir tente novamente!', 5000);
          return false;
        }
      })
      .catch((resp) => {
        if (resp.error.details.exception.match('Integrity constraint violation')) {
          this.msg('Não foi possível excluir, pois este item está relacionado a outros itens!', 8000);
        } else {
          this.msg('Não foi possível excluir tente novamente!', 5000);
        }
      });
  }

  ExcluirSemToast(rota: string, id: number): any {
    return this.http.delete<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return true;
        } else if (data.hasOwnProperty('details') && data.details == 'Não é possível excluir um status fixo.') {
          console.log(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('details') && data.details == 'Não foi possível excluir, pois este item está relacionado a outros itens.') {
          console.log(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('code')) {
          switch (data.code) {
            case 'dc132a54-44f2-4563-b4e4-57fd5da85b86':
            case '8bcd3147-46b6-4f45-9895-e66406466586':
            case '8bcd3147-46b6-4f45-9895-e66406466587':
              this.msg(data.details, 5000);
              break;
            default:
              console.log('Não foi possível atualizar tente novamente!', 5000);
              break;
          }

          return false;
        } else {
          console.log('Não foi possível excluir tente novamente!', 5000);
          return false;
        }
      })
      .catch((resp) => {
        if (resp.error.details.exception.match('Integrity constraint violation')) {
          console.log('Não foi possível excluir, pois este item está relacionado a outros itens!', 8000);
        } else {
          console.log('Não foi possível excluir tente novamente!', 5000);
        }
      });
  }

  ExcluirIdEmpresa(rota: string, id: number, idEmpresa): any {
    return this.http.delete<any>(`${environment.api_url}${rota}${id}/${idEmpresa}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados excluídos com sucesso!', 5000);
          return true;
        } else if (data.hasOwnProperty('details') && data.details == 'Não é possível excluir um status fixo.') {
          this.msg(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('details') && data.details == 'Não foi possível excluir, pois este item está relacionado a outros itens.') {
          this.msg(data.details, 5000);
          return false;
        } else if (data.hasOwnProperty('code')) {
          switch (data.code) {
            case 'dc132a54-44f2-4563-b4e4-57fd5da85b86':
            case '8bcd3147-46b6-4f45-9895-e66406466586':
            case '8bcd3147-46b6-4f45-9895-e66406466587':
              this.msg(data.details, 5000);
              break;
            default:
              // Caso não encontre nada para apagar cai aqui REMOVIDO toast
              break;
          }

          return false;
        } else {
          this.msg('Não foi possível excluir tente novamente!', 5000);
          return false;
        }
      })
      .catch((resp) => {
        if (resp.error.details.exception.match('Integrity constraint violation')) {
          this.msg('Não foi possível excluir, pois este item está relacionado a outros itens!', 8000);
        } else {
          this.msg('Não foi possível excluir tente novamente!', 5000);
        }
      });
  }

  buscaCidadePorCep(cep): any {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`)
      .toPromise()
      .then(data => {
        return data;
      }, () => {
        return false;
      });
  }

  msg(msg, temp): void {
    this._matSnackBar.open(msg, 'OK', {
      verticalPosition: 'bottom',
      duration: temp,
      panelClass: ['style-success']
    });
  }

  // Get Grupos Avalistas
  GetGruposAvalistas(rota: string) {
    return this.http.get<any>(`${environment.api_url}${rota}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {

        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }
  
  //
  GetGruposAvalistaNull(rota: string) {
    return this.http.get<any>(`${environment.api_url}${rota}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {

        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  //
  GetGruposAvalistaById(rota: string, id:any) {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {

        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  GetGruposAvalistaByGrupo(rota: string, id:any) {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {

        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  InsertGruposAvalistas(rota: string, data) {
    return this.http.post<any>(`${environment.api_url}${rota}`, data, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados adicionados com sucesso!', 5000);
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  UpdateGruposAvalistas(rota: string, data) {
    return this.http.put<any>(`${environment.api_url}${rota}`, data, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados atualizados com sucesso!', 5000);
          return data['data'][0];
        } else {
          return data;
        }
      });
  }


  // SituacoesEmpregaticias
  GetSituacoesEmpregaticias(rota: string) {
    return this.http.get<any>(`${environment.api_url}${rota}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  GetSituacoesEmpregaticiasById(rota: string, id) {
    return this.http.get<any>(`${environment.api_url}${rota}`+id, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  // SituacoesEmpregaticias
  InsertSituacaoEmpregaticia(rota: string, data) {
    return this.http.post<any>(`${environment.api_url}${rota}`, data, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados adicionados com sucesso!', 5000);
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  InsertSituacaoEmpregaticiaDoc(rota: string, data) {
    return this.http.post<any>(`${environment.api_url}${rota}`, data, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  UpdateSituacaoEmpregaticia(rota: string, data) {
    return this.http.put<any>(`${environment.api_url}${rota}`, data, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados atualizados com sucesso!', 5000);
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  GetSituacoesEmpregaticiasDoc(rota: string, Id) {

    return this.http.get<any>(`${environment.api_url}${rota}`+Id, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  DeleteSituacaoEmpregaticia(rota: string, id) {
    return this.http.delete<any>(`${environment.api_url}${rota}`+id, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados excluídos com sucesso!', 5000);
          return data['data'][0];
        } else {
          return data;
        }
      }).catch( err =>{
        console.log(err);
      });
  }

  DeleteSituacaoEmpregaticiaDoc(rota: string, id) {
    return this.http.delete<any>(`${environment.api_url}${rota}`+id, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          this.msg('Dados excluídos com sucesso!', 5000);
          return data['data'][0];
        } else {
          return data;
        }
      });
  }
  EmailContemplacao(rota: string, data) {
    return this.http.post<any>(`${environment.api_url}${rota}`, data, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data['data'][0];
        } else {
          return data;
        }
      });
  }

  getConsultarBemCota(IdAquisicaoBem) {
    return this.http.get(`${environment.api_url}${environment.buscar_bem_cota}/${IdAquisicaoBem}`,  { headers: environment.api_headers });
  }
  
  AtualizarSemToast3(rota: string, id: number, dados): any {
    return this.http.put<any>(`${environment.api_url}${rota}${id}`, dados, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        return data
      })
      .catch((resp) => {
        this.msg('Não foi possível excluir, tente novamente!', 5000);
      });
  }

  buscaCotasVincular(dados: any) {
    return this.http.post<any>(`${environment.api_url}${environment.busca_cota_vinculada_bem}`, dados, { headers: environment.api_headers });
  } 

  addCotaComplementar(dados) {
    return this.http.post<any>(`${environment.api_url}${environment.add_cota_vinculada_bem}`, dados, { headers: environment.api_headers });
  }

  ListMensagensSituacaoIdSituacao(rota: string, id: any): any {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        }
      });
  }

  getPorId(rota: string, id: any): any {
    return this.http.get<any>(`${environment.api_url}${rota}${id}`, { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        if (data.success) {
          return data;
        } else {
          return data;
        }
      });
  }

  uploadImagemDataValid(rota: string, id, file): any {
    return this.http.post(`${environment.api_url}${rota}${id}`, file,
      { headers: environment.api_headers })
      .toPromise()
      .then(data => {
        return data;
      });
  }

}