import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'app/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class FiadorService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private _matSnackBar: MatSnackBar
  ) { }

  verifyToken(token): Promise<any>
  {
    return new Promise((resolve, reject) => {
        this.apiService.BuscarTodosPorEmpresa(`${environment.verify_token}`, token)
        .then(response => {
            resolve(response);
        });
    });
  }

}