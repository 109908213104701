import { environment } from 'environments/environment';

export class CotasHistorico {
    IdUsuario: number;
    IdCotaUsuario: number;
    IdSituacao: number;
    NomeUsuario: string;
    TipoHistorico: string;
    TipoAcesso: string;
    DescricaoHistorico: string;
    IdEmpresa: number;
    DataHistorico: string;
    UrlImagemUsuario: string;

    /**
     * Constructor
     *
     * @param cota
     */
    constructor(cota) {
        {
            this.IdUsuario = cota.IdUsuario || null;
            this.IdCotaUsuario = cota.IdCotaUsuario || null;
            this.NomeUsuario = cota.NomeUsuario || '';
            this.TipoHistorico = cota.TipoHistorico || '';
            this.TipoAcesso = cota.TipoAcesso || '';
            this.DescricaoHistorico = cota.DescricaoHistorico || '';
            this.IdEmpresa = cota.IdEmpresa || null;
            this.DataHistorico = cota.DataHistorico || '';
            if(cota.UrlImagem == "assets/images/avatars/profile.jpg"){
                this.UrlImagemUsuario = null;
            }else{
                this.UrlImagemUsuario = (cota.UrlImagem !== null) ? `${environment.api_url}${cota.UrlImagem}` : '';
            }
            
        }
    }
}
