import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from './moment-date-formats';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ApiService } from '../../../../../services/api.service';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FuncoesService } from 'app/services/funcoes.service';
import './momentES';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
moment.locale('pt-BR')



@Component({
    selector: 'fiador-form-modal',
    templateUrl: './fiador-form-modal.html',
    styleUrls: ['./fiador-form-modal.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
})
export class FiadorFormModalComponent implements OnInit {

    result: any;
    mainData: any;
    action: any;
    dialogTitle: string;
    files: any = null;
    fileData: File = null;

    source: any;

    //Variáveis utilizadas para manipular listas no formcontrol
    colecao: any;
    colecaoInput = '';
    //-----------------------

    socioProprietario: any;

    categoria: any;

    financiado = '';

    cartaoDeCredito = "";

    form: FormGroup;

    estados = [
        "",
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PI",
        "PE",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
    ];

    ocupaCargoCPF = '';
    ocupaCargoCNPJ: any;

    exibeDocumento = false;
    exibeCampos = false;

    maskCpf = '000.000.000-00';
    maskCnpj = '00.000.000/0000-00';
    maskCEP = '00000-000';
    mailPattern = '^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$';
    textPatern = '[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ_ ]+$';
    numberPatern = '^[1-9][0-9]*$';
    addressPatern = '^[1-9A-Za-z][0-9A-Za-z]*$';
    maskCpfCnpj = '';
    maskTel_Cel = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _apiService: ApiService,
        public matDialogRef: MatDialogRef<FiadorFormModalComponent>,
        private _adapter: DateAdapter<any>,
        private _formBuilder: FormBuilder,
        public datepipe: DatePipe,
    ) {

        this.action = _data.action;
        this.mainData = _data.mainData;
        this.source = _data.source;

        switch (this.mainData) {

            //MODAIS PESSOA FÍSICA

            case "dependentes":
               
                if (this.action == 'create')
                {
                  this.dialogTitle = 'Novo Dependente';
                  this.form = this.createFormControl({
                      Nome: ['', Validators.required],
                      IdPessoaFisica: [this.source],
                  });
                }
                else
                    if (this.action == 'update')
                    {
                      this.dialogTitle = 'Atualizar Dependente';
                      this.form = this.createFormControl({
                          Nome: [this.source.Nome, Validators.required],
                          IdPessoaFisica: [this.source.IdPessoaFisica],
                          IdDependentes: [this.source.IdDependentes]
      
                      });
                    }
                    else
                    {
                      this.dialogTitle = 'Excluir Dependente';
                      this.form = this.createFormControl({
                          id:                     [this.source.IdDependentes]
                      });
                    }
                break;

            case "outrosRendimentos":
                if (this.action == 'create')
                {
                    this.dialogTitle = 'Novo Rendimento';
                    this.form = this.createFormControl({
                      Origem:   ['', Validators.required],
                      Valor:    ['', Validators.required],
                      IdRenda:  [this.source, Validators.required]
  
                  });
                }
                else
                    if (this.action == 'update')
                    {
                        this.dialogTitle = 'Atualizar Rendimento';
                        this.form = this.createFormControl({
                          Origem:               [this.source.Origem, Validators.required],
                          Valor:                [this.source.Valor, Validators.required],
                          IdRenda:              [this.source.IdRenda, Validators.required],
                          IdOutros_rendimentos: [this.source.IdOutros_rendimentos, Validators.required]
      
                      });
                    }
                    else
                    {
                        console.log(this.source);
                        this.dialogTitle = 'Excluir Rendimento';
                        this.form = this.createFormControl({
                          id:   [this.source.IdOutros_rendimentos, Validators.required]
      
                      });
                    }

                break;

            case "patrimonio":
                if (this.action == 'create')
                {
                  this.dialogTitle = 'Novo Patrimônio';
                  this.form = this.createFormControl({
                    Categoria:            ['', Validators.required],
                    Descricao:            [''],
                    Marca:                [''],
                    Modelo:               [''],
                    Ano:                  ['', [Validators.required, Validators.pattern(this.numberPatern)]],
                    ValorAtual:           ['', Validators.required],
                    Onus:                 ['', Validators.required],
                    IdEmpresa:            [''],
                    IdPessoaFisica:       [this.source],
                  });
                }
                else
                    if (this.action == 'update')
                    {
                        this.dialogTitle = 'Atualizar Patrimônio';
                        this.form = this.createFormControl({
                          Categoria:      [this.source.Categoria, Validators.required],
                          Descricao:      [this.source.DescricaoPatrimonio],
                          Marca:          [this.source.Marca],
                          Modelo:         [this.source.Modelo],
                          Ano:            [this.source.Ano],
                          ValorAtual:     [this.source.ValorAtual, Validators.required],
                          Onus:           [this.source.Onus, Validators.required],
                          IdEmpresa:      [this.source.IdEmpresa],
                          IdPessoaFisica: [this.source.IdPessoaFisica],
                          IdPatrimonio:   [this.source.IdPatrimonio]
                        });
                    }
                    else
                    {
                        this.dialogTitle = 'Excluir Patrimônio';
                        this.form = this.createFormControl({
                          id:      [this.source.IdPatrimonio, Validators.required]
                        });
                    }

                break;

            case "referenciasBancarias":
              if(this.action == 'create')
                  {
                    this.dialogTitle = 'Nova Referência Bancária';
                    this.form = this.createFormControl({
                      Banco:                  ['', Validators.required],
                      Agencia:                ['', Validators.required],
                      Numero:                 ['', [Validators.required, 
                                                    Validators.pattern(this.numberPatern)]],
                      Conta:                  ['', Validators.required],
                      IdEmpresa:              ['1'],
                      IdPessoaFisica:         [this.source]
                    });
                  }
                  else
                    if(this.action == 'update')
                    {
                      this.dialogTitle = 'Atualizar Referência Bancária';
                        
                      this.form = this.createFormControl({
                        Banco:                  [this.source.Banco, Validators.required],
                        Agencia:                [this.source.Agencia, Validators.required],
                        Numero:                 [this.source.Numero, [Validators.required, 
                                                                        Validators.pattern(this.numberPatern)]],
                        Conta:                  [this.source.Conta, Validators.required],
                        IdReferenciaBancaria:   [this.source.IdReferenciaBancaria],
                        IdEmpresa:              [this.source.IdEmpresa],
                        IdPessoaFisica:         [this.source.IdPessoaFisica]
                      });
                    }
                    else  
                    {
                      this.dialogTitle = 'Excluir Referência Bancária';
                      this.form = this.createFormControl({
                        id:                     [this.source.IdReferenciaBancaria]
                      });
                    }
            break;

            case "referenciasPessoais":
                if (this.action == 'create')
                {
                    this.dialogTitle = 'Nova Referência Pessoal';
                    this.form = this.createFormControl({
                      Nome:           ['', Validators.required],
                      Endereco:       ['', Validators.required],
                      Telefone:       ['', Validators.required],
                      IdEmpresa:      ['1'],
                      IdPessoaFisica: [this.source]
  
                    });
                }
                else
                    if (this.action == 'update')
                    {
                        this.dialogTitle = 'Atualizar Referência Pessoal';
                        this.form = this.createFormControl({
                          Nome:                 [this.source.Nome, Validators.required],
                          Endereco:             [this.source.Endereco, Validators.required],
                          Telefone:             [this.source.Telefone, Validators.required],
                          IdEmpresa:            [this.source.IdEmpresa],
                          IdPessoaFisica:       [this.source.IdPessoaFisica],
                          IdReferenciaPessoal:  [this.source.IdReferenciaPessoal]
      
                        });
                    }
                    else
                    {
                        this.dialogTitle = 'Excluir Referência Pessoal';
                        this.form = this.createFormControl({
                          id:     [this.source.IdReferenciaPessoal]
      
                        });
                    }

                break;

            case "referenciasComerciais":
                if (this.action == 'create')
                {
                  this.dialogTitle = 'Nova Referência Comercial';
                  this.form = this.createFormControl({
                    ContatoSetor:   ['', Validators.required],
                    Cidade:         ['', [Validators.required, Validators.pattern(this.textPatern)]],
                    Telefone:       ['', Validators.required],
                    Empresa:        ['', Validators.required],
                    IdEmpresa:      ['1'],
                    IdPessoaFisica: [this.source]

  
                  });
                }
                else
                    if (this.action == 'update')
                    {
                      this.dialogTitle = 'Atualizar Referência Comercial';
                      this.form = this.createFormControl({
                        ContatoSetor:           [this.source.ContatoSetor, Validators.required],
                        Cidade:                 [this.source.Cidade, [Validators.required, Validators.pattern(this.textPatern)]],
                        Telefone:               [this.source.Telefone, Validators.required],
                        Empresa:                [this.source.Empresa, Validators.required],
                        IdEmpresa:              [this.source.IdEmpresa],
                        IdPessoaFisica:         [this.source.IdPessoaFisica],
                        IdReferenciaComercial:  [this.source.IdReferenciaComercial]
      
                      });
                    }
                    else
                    {
                      this.dialogTitle = 'Excluir Referência Comercial';
                      this.form = this.createFormControl({
                        id:             [this.source.IdReferenciaComercial]
      
                      });
                    }

                break;

            case "pretensao":
                if (this.action == 'create')
                {
                    this.dialogTitle = 'Nova Pretensão de Compra';
                    this.form = this.createFormControl({
                      Categoria:          ['', Validators.required],
                      Descricao:          [''],
                      Marca:              [''],
                      Modelo:             [''],
                      Ano:                ['',[Validators.pattern(this.numberPatern)]],
                      Valor:              ['', Validators.required],
                      Estado:             [''],
                      IdEmpresa:          [this.source.IdEmpresa],
                      IdPessoaFisica:     [this.source.IdPessoaFisica]
  
                  });
                }
                else
                    if (this.action == 'update')
                    {
                        this.dialogTitle = 'Atualizar Pretensão de Compra';
                        this.form = this.createFormControl({
                          Categoria:          [this.source.Categoria, Validators.required],
                          Descricao:          [this.source.Descricao],
                          Marca:              [this.source.Marca],
                          Modelo:             [this.source.Modelo],
                          Ano:                [this.source.Ano,Validators.pattern(this.numberPatern)],
                          Valor:              [this.source.Valor, Validators.required],
                          Estado:             [this.source.Estado],
                          IdEmpresa:          [this.source.IdEmpresa],
                          IdPessoaFisica:     [this.source.IdPessoaFisica],
                          IdPretencaoCompra:  [this.source.IdPretencaoCompra]
      
                      });
                    }
                    else
                    {
                        this.dialogTitle = 'Excluir Pretensão de Compra';
                        this.form = this.createFormControl({
                          id:         [this.source.IdPretencaoCompra],      
                      });
                    }

                break;

                case 'validation':
                  if(this.action == 'invalid')
                        this.exibeCampos = true;
                      
                  this.dialogTitle = 'Validação dos Dados';

                break;

            default:
                matDialogRef.close();
        }
    }

    ngOnInit(): void {
        this._adapter.setLocale('pt-BR');
        this.colecao = [];
    }

    cpfcnpj(value) {
      this.clearMask();
      value.length === 11 ? this.maskCpfCnpj = '000.000.000-00' : this.maskCpfCnpj = '00.000.000/0000-00';
    }

    clearMask(){
      this.maskCpfCnpj = '';
    }

    isFixo(): boolean {
        return this.form.value.Telefone == null ? true : this.form.value.Telefone.length < 11 ? true : false;
    }

    getTelefoneMask(): string {
        return this.isFixo() ? '(00) 0000-00009' : '(00) 00000-0000';
    }

    createFormControl(form: Object): FormGroup {
        return this._formBuilder.group(form);
    }

    pickDate(value, item) {

        if (item == 'nscto')
            this.form.controls.DataNascimento.setValue(value);
        else if (item == 'abertura')
            this.form.controls.DataFundacao.setValue(value);
        else if (item == 'admissao')
            this.form.controls.DataAdmissao.setValue(value);
        else if (item == 'conta')
            this.form.controls.DataAbertura.setValue(value);

    }

    changeCEP(cep) {
        if (cep.length == 8)
            this._apiService.buscaCidadePorCep(cep).then(resp => {
                if (!resp.erro)
                {
                  this.form.controls.Cidade.setValue(resp.localidade);
                  this.form.controls.UF.setValue(resp.uf);
                }
                else{
                  this.form.controls.Cidade.setValue('');
                  this.form.controls.UF.setValue('');
                }       
            });
        else{
            this.form.controls.Cidade.setValue('');
            this.form.controls.UF.setValue('');
        }
    }

    changeCEPComplementar(cep) {
        if (cep.length == 8)
            this._apiService.buscaCidadePorCep(cep).then(resp => {
                if (!resp.erro){
                    this.form.controls.CidadeComplementar.setValue(resp.localidade);
                    this.form.controls.UFComplementar.setValue(resp.uf);
                } 
                else{
                    this.form.controls.CidadeComplementar.setValue('');
                    this.form.controls.UFComplementar.setValue('');
                }
            });
        else{
            this.form.controls.CidadeComplementar.setValue('');
            this.form.controls.UFComplementar.setValue('');
        }
    }

    changeCEPAnterior(cep) {
        if (cep.length == 8)
            this._apiService.buscaCidadePorCep(cep).then(resp => {
                if (!resp.erro){
                    this.form.controls.CidadeAnterior.setValue(resp.localidade);
                    this.form.controls.UFAnterior.setValue(resp.uf);
                }  
                else{
                    this.form.controls.CidadeAnterior.setValue('');
                    this.form.controls.UFAnterior.setValue('');
                } 
            });
        else{
            this.form.controls.CidadeAnterior.setValue('');
            this.form.controls.UFAnterior.setValue('');
        }
    }

    fileProgress(fileInput: any): void {
      this.fileData = fileInput.target.files[0] as File;
      this.preview();
  }

  preview(): void {
      // Show preview 
      const mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
          return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
          this.source.UrlImagem = reader.result;
      };

      const formData = new FormData();
      formData.append('UrlImagem', this.fileData);
      this.files = formData;
  }

    async setValidators(opcao) {

        if (opcao === 'S') {
            await this.form.controls.Financeira.setValidators(Validators.required);
            await this.form.controls.Valor.setValidators(Validators.required);
            await this.form.controls.PrestacaoPagas.setValidators(Validators.pattern(this.numberPatern));
            await this.form.controls.PrestacaoAVencer.setValidators(Validators.pattern(this.numberPatern));
        }
        else {
            await this.form.controls.Financeira.setValidators([]);
            await this.form.controls.Valor.setValidators([]);
            await this.form.controls.PrestacaoPagas.setValidators([]);
            await this.form.controls.PrestacaoAVencer.setValidators([]);
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsRendaConjuge(opcao) {

        if (opcao === 'S') {
            await this.form.controls.TipoVinculo.setValidators(Validators.required);
            await this.form.controls.SocioProprietario.setValidators(Validators.required);
            await this.form.controls.EmpresaConjuge.setValidators(Validators.required);
            await this.form.controls.CnpjConjuge.setValidators(Validators.required);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators(Validators.required);
            await this.form.controls.RendaMensal.setValidators(Validators.required);
            await this.form.controls.TelefoneComercial.setValidators(Validators.required);
        }
        else {
            await this.form.controls.TipoVinculo.setValidators([]);
            await this.form.controls.SocioProprietario.setValidators([]);
            await this.form.controls.EmpresaConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CargoConjuge.setValidators([]);
            await this.form.controls.DataAdmissao.setValidators([]);
            await this.form.controls.RendaMensal.setValidators([]);
            await this.form.controls.TelefoneComercial.setValidators([]);

            await this.form.controls.TipoVinculo.setValue('');
            await this.form.controls.SocioProprietario.setValue('');
            await this.form.controls.EmpresaConjuge.setValue('');
            await this.form.controls.CnpjConjuge.setValue('');
            await this.form.controls.CargoConjuge.setValue('');
            await this.form.controls.DataAdmissao.setValue('');
            await this.form.controls.RendaMensal.setValue('');
            await this.form.controls.TelefoneComercial.setValue('');
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsVinculoConjuge(opcao) {

        if (opcao === 'C') {
            await this.form.controls.SocioProprietario.setValidators(Validators.required);
            await this.form.controls.EmpresaConjuge.setValidators(Validators.required);
            await this.form.controls.CnpjConjuge.setValidators(Validators.required);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators([Validators.required, FuncoesService.isDateValid]);
            await this.form.controls.RendaMensal.setValidators,[Validators.required, Validators.pattern(this.numberPatern)];
            await this.form.controls.TelefoneComercial.setValidators,[Validators.required, Validators.pattern(this.maskTel_Cel)];
        }
        else {
            await this.form.controls.SocioProprietario.setValidators([]);
            await this.form.controls.EmpresaConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators([]);
            await this.form.controls.RendaMensal.setValidators,[Validators.required, Validators.pattern(this.numberPatern)];
            await this.form.controls.TelefoneComercial.setValidators,[Validators.required, Validators.pattern(this.maskTel_Cel)];

            await this.form.controls.SocioProprietario.setValue('');
            await this.form.controls.EmpresaConjuge.setValue('');
            await this.form.controls.CnpjConjuge.setValue('');
            await this.form.controls.DataAdmissao.setValue('');
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsLimite(opcao) {

      if (opcao != 'P')
          await this.form.controls.Limite.setValidators(Validators.required);

      else
      {
          await this.form.controls.Limite.setValidators([]);
          await this.form.controls.Limite.setValue('');
      }

      Object.keys(this.form.controls).forEach(field => {
          let control = this.form.get(field);
          control.updateValueAndValidity();
      });

    }


    async setValidatorsCartao(opcao) {

        if (opcao === 'S') {
            await this.form.controls.LimiteCartao.setValidators(Validators.required);
            await this.form.controls.ApresentouFatura.setValidators(Validators.required);

        }
        else if(opcao === 'N'){
            await this.form.controls.LimiteCartao.setValidators([]);
            await this.form.controls.ApresentouFatura.setValidators([]);

            await this.form.controls.LimiteCartao.setValue('');
            await this.form.controls.ApresentouFatura.setValue('');

        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsResidenciaAtual(opcao) {

        if (opcao === 'A' || opcao === 'F')
            await this.form.controls.ValorAluguel.setValidators([Validators.required, Validators.min(0.01)]);
            

        else
        {
            await this.form.controls.ValorAluguel.setValidators([]);
            await this.form.controls.ValorAluguel.setValue('');
        }


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsRenda(opcao) {

        if (opcao === 'S')
            await this.form.controls.CNPJ.setValidators([Validators.required, FuncoesService.ValidaCPFCNPJ]);

        else
            await this.form.controls.CNPJ.setValidators([]);


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsConjuge(opcao) {

        if (opcao === 'S')
            await this.form.controls.CnpjConjuge.setValidators([Validators.required]);

        else
        {
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValue('');
        }


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsPatrimonio(opcao) {

        if (opcao === 'I' ) {
            await this.form.controls.Descricao.setValidators([Validators.required]);
            await this.form.controls.Marca.setValidators([]);
            await this.form.controls.Modelo.setValidators([]);
            await this.form.controls.Ano.setValidators([]);

        }

        else if (opcao === 'V') {
            await this.form.controls.Descricao.setValidators([]);
            await this.form.controls.Marca.setValidators(Validators.required);
            await this.form.controls.Modelo.setValidators(Validators.required);
            await this.form.controls.Ano.setValidators([Validators.required, Validators.pattern(this.numberPatern)]);

        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsHipoteca(opcao) {

        if (opcao === 'S')
            await this.form.controls.ValorPrestacao.setValidators(Validators.required);

        else
        {
            await this.form.controls.ValorPrestacao.setValidators([]);
            await this.form.controls.ValorPrestacao.setValue('');

        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsEndereco(opcao) {

        if (opcao < 12) {
            await this.form.controls.EnderecoAnterior.setValidators(Validators.required);
            await this.form.controls.NumeroAnterior.setValidators([Validators.required, Validators.pattern(this.addressPatern)]);
            await this.form.controls.BairroAnterior.setValidators(Validators.required);
            await this.form.controls.CidadeAnterior.setValidators([Validators.required, Validators.pattern(this.textPatern)]);
            await this.form.controls.CepAnterior.setValidators(Validators.required);
            await this.form.controls.UFAnterior.setValidators(Validators.required);
            await this.form.controls.TempoResidenciaAnterior.setValidators([Validators.required, Validators.pattern(this.numberPatern)]);
        }

        else {
            await this.form.controls.EnderecoAnterior.setValidators([]);
            await this.form.controls.NumeroAnterior.setValidators([]);
            await this.form.controls.BairroAnterior.setValidators([]);
            await this.form.controls.CidadeAnterior.setValidators([]);
            await this.form.controls.CepAnterior.setValidators([]);
            await this.form.controls.UFAnterior.setValidators([]);
            await this.form.controls.TempoResidenciaAnterior.setValidators([]);
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsPretensao(opcao) {

        if (opcao === 'Maquinas' || opcao === 'BMM') {
            await this.form.controls.Marca.setValidators([]);
            await this.form.controls.Modelo.setValidators([]);
            await this.form.controls.Ano.setValidators([]);
            await this.form.controls.Estado.setValidators(Validators.required);

        }
        else if (opcao === 'Automoveis') {
            await this.form.controls.Estado.setValidators(Validators.required);
            await this.form.controls.Modelo.setValidators(Validators.required);
            await this.form.controls.Ano.setValidators([Validators.required, Validators.pattern(this.numberPatern)]);
            await this.form.controls.Marca.setValidators(Validators.required);

        }
        else if (opcao === 'Servicos' || opcao === 'Energia') {
            await this.form.controls.Marca.setValidators([]);
            await this.form.controls.Modelo.setValidators([]);
            await this.form.controls.Ano.setValidators([]);
            await this.form.controls.Estado.setValidators([]);

        }


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    cancel() {
        this.matDialogRef.close();
    }

    formatData(text){
      var ano = text.substring(0,4);
      var mes = text.substring(5,7);
      var dia = text.substring(8,10);
    
      return dia+mes+ano;
    }

    delete(form) {
        this.matDialogRef.close(form);
    }

    save(form) {
        this.matDialogRef.close(form);
    }

    title(value){
      return value.split('-',1);
    }
    text(value){
      return value.split('-',2)[1];
    }

    async setValidatorsPretensaoCNPJ(opcao){

      if(opcao === 'Maquinas')
      {
        await this.form.controls.Marca.setValidators([]);
        await this.form.controls.Modelo.setValidators([]);
        await this.form.controls.Ano.setValidators([]);
        await this.form.controls.Estado.setValidators(Validators.required);

        await this.form.controls.Marca.setValue('');
        await this.form.controls.Modelo.setValue('');
        await this.form.controls.Ano.setValue('');
        
      }
      else if(opcao === 'Moveis')
      {
        await this.form.controls.Estado.setValidators(Validators.required);
        await this.form.controls.Modelo.setValidators(Validators.required);
        await this.form.controls.Ano.setValidators([Validators.required, Validators.pattern(this.numberPatern)]);
        await this.form.controls.Marca.setValidators(Validators.required);

      }
      else if(opcao === 'BMM')
      {
        await this.form.controls.Estado.setValidators(Validators.required);
        await this.form.controls.Modelo.setValidators(Validators.required);
        await this.form.controls.Ano.setValidators([]);
        await this.form.controls.Marca.setValidators(Validators.required);
      }
      else if(opcao === 'Servicos' || opcao === 'Energia' )
      {
        await this.form.controls.Marca.setValidators([]);
        await this.form.controls.Modelo.setValidators([]);
        await this.form.controls.Ano.setValidators([]);
        await this.form.controls.Estado.setValidators([]);

        await this.form.controls.Marca.setValue('');
        await this.form.controls.Modelo.setValue('');
        await this.form.controls.Ano.setValue('');
        await this.form.controls.Estado.setValue('');

      }

      Object.keys(this.form.controls).forEach(field => {
        let control = this.form.get(field);
        control.updateValueAndValidity();
      });

    }

    convertPercent(value, control){

      switch (control){

        case 'PorcentagemParticipacao':
          this.form.controls.PorcentagemParticipacao.setValue(Number(value));
          break;

        case 'Participacao':
          this.form.controls.Participacao.setValue(Number(value));
          break;

        case 'Empregados':
          this.form.controls.NumeroEmpregados.setValue(Number(value));
      }
      
    }

}