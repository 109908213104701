export class CotasConsorciado {
    IdCotaCanopus: number;
    IdCotaUsuario: number;
    CodigoGrupo: number;
    CodigoCota: number;
    IdSituacao: number;
    Consorciado: string;
    CreditoAtualizado: string;
    Situacao: string;
    NomeProduto: string;
    IdEmpresa: number;
    RevisaoConsorciado: string;
    RevisaoAnalista: string;
    Ordem: number;
    Versao: number;
    PrimeiraVez: string;
    DataEntregaBem: string;
    DataContemplacao: string;
    ParcelasPagas: number;
    UltimaAtualizacao: string;
    ValorBem: string;
    ValorCreditoAtualizado: string;
    ValorCreditoOriginal: string;
    ValorBemEntregue: string;
    ValorBemReajuste: string;
    ConcluidoDados: string;
    ConcluidoDocumentos: string;
    SegundoAcesso: string;
    DataHistorico: string;

    /**
     * Constructor
     *
     * @param cota
     */
    constructor(cota) {
        {
            this.IdCotaCanopus = cota.IdCotaCanopus || null;
            this.IdCotaUsuario = cota.IdCotaUsuario || null;
            this.CodigoGrupo = cota.CodigoGrupo || null;
            this.CodigoCota = cota.CodigoCota || null;
            this.IdSituacao = cota.IdSituacao || null;
            this.Consorciado = cota.Consorciado || '';
            this.CreditoAtualizado = cota.CreditoAtualizado || '';
            this.UltimaAtualizacao = cota.UltimaAtualizacao || '';
            this.Situacao = cota.Situacao || '';
            this.NomeProduto = cota.NomeProduto || '';
            this.IdEmpresa = cota.IdEmpresa || null;
            this.Ordem = cota.Ordem || null;
            this.Versao = cota.Versao;
            this.RevisaoConsorciado = cota.RevisaoConsorciado || '';
            this.RevisaoAnalista = cota.RevisaoAnalista || '';
            this.DataEntregaBem = cota.DataEntregaBem || '';
            this.DataContemplacao = cota.DataContemplacao || '';
            this.ParcelasPagas = cota.ParcelasPagas || null;
            this.ValorBem = cota.ValorBem !== undefined ? cota.ValorBem : null;
            this.ValorCreditoAtualizado = cota.ValorCreditoAtualizado !== undefined ? cota.ValorCreditoAtualizado : null;
            this.ValorCreditoOriginal = cota.ValorCreditoOriginal !== undefined ? cota.ValorCreditoOriginal : null;
            this.ValorBemEntregue = cota.ValorBemEntregue !== undefined ? cota.ValorBemEntregue : null;
            this.ValorBemReajuste = cota.ValorBemReajuste !== undefined ? cota.ValorBemReajuste : null;
            this.ConcluidoDados = cota.ConcluidoDados;
            this.ConcluidoDocumentos = cota.ConcluidoDocumentos;
            this.SegundoAcesso = cota.SegundoAcesso;
            this.DataHistorico = cota.DataHistorico;
        }
    }
}
