import { element } from 'protractor';

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { environment } from 'environments/environment';



interface FoodNode {
  name: string;
  children?: FoodNode[];
}
@Component({
  selector: 'app-fiador-documentos',
  templateUrl: './fiador-form-documentos.component.html',
  styleUrls: ['./fiador-form-documentos.component.scss']
})
export class FiadorFormDocumentosComponent implements OnInit, AfterViewInit {

  // IN
  @Input() DocumentosObrigatorios: any;
  @Input() DocumentosAnalista: any;
  @Input() DocumentosFiador: any;
  @Input() ArquivosAnexados: any;
  @Input() ArquivosAnexadosAnalista: any;
  @Input() ArquivosAnexadosFiador: any;
  @Input() getFolderSelected: any;
  @Input() Editar: boolean;

  // OUT
  @Output() inputFile = new EventEmitter<any>()
  @Output() deleteFile = new EventEmitter<any>()
  @Output() folderSelected = new EventEmitter<any>()

  //
  _DocumentosObrigatorios: FoodNode;
  _DocumentosAnalista: FoodNode;
  _DocumentosFiador: FoodNode;
  _DataAnexos = [];
  _childremArray:[]
  _openFolder: boolean = false
  _isAvalista:boolean = false;
  _host = environment.api_url;
  _lastFolderOpened: any;

  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  dataSource1 = new MatTreeNestedDataSource<FoodNode>();
  imagePath='/assets/icons/icons/'
  estado: string = "";
  constructor() {
    // this.dataSource1.data = TREE_DATA
  }

  hasChild = (_: number, node: FoodNode) => !!node.children && node.children?.length > 0;

  ngOnInit(): void {
    this._lastFolderOpened = this.getFolderSelected?.doc+this.getFolderSelected?.state
  }

  ngAfterViewInit(): void {


    this._DocumentosObrigatorios = {
      name: 'DOCUMENTOS OBRIGATÓRIOS:',
      children: this.DocumentosObrigatorios
    }

    this._DocumentosAnalista = {
      name: 'DOCUMENTOS EXIGIDOS PELO ANALISTA:',
      children: this.DocumentosAnalista
    }

    this._DocumentosFiador = {
      name: 'DOCUMENTOS DO FIADOR:',
      children: this.DocumentosFiador
   
    }

    this.dataSource.data = this.hasDocuments(this._DocumentosObrigatorios, this._DocumentosAnalista, this._DocumentosFiador )
  }
  

  hasDocuments = (obrigatorio: FoodNode , analista: FoodNode , avalista: FoodNode ) => {

        let  dataSource = []

        if(obrigatorio.children != undefined){
          dataSource.push(obrigatorio);
          this._DataAnexos = this.ArquivosAnexados;
          this.estado="Obrigatorios"
        }

        if (analista.children != undefined) {
          dataSource.push(analista);
          this._DataAnexos = this.ArquivosAnexadosAnalista;
          this.estado="Analista" 
        }

        if (avalista.children != undefined) {
          dataSource.push(avalista);
          this._DataAnexos = this.ArquivosAnexadosFiador
          this._isAvalista = true;
          this.estado="Avalista"
        }
        return dataSource;
  }


  inputFileChange(event:any, documento:any, estado:string){

    let doc = documento.NomeDocumento;
    let state = estado;

    this.folderSelected.emit({doc, state});

    this.inputFile.emit({event, documento})
  }

  deletarFile(idFile,documento:any, estado:string){

    let doc = documento.NomeDocumento;
    let state = estado;

    this.folderSelected.emit({doc, state});
    this.deleteFile.emit({idFile})
  }

  openFolder(value: any, args: any){

    let id = `${value}${args}`
    let folder = document.getElementById(id)
    let arquivos = document.getElementById('documento-' + id)
    let elementX = document.getElementById(id).innerText
      
      if(elementX == 'folder') {
        folder.innerText = 'folder_open';
        arquivos.style.display = 'block';
      }else{
        folder.innerText = 'folder';
        arquivos.style.display = 'none';
      }
  }

  onOpenFile(url){
    window.open(url);
  }
}
