import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseAlertDialogComponent } from '@fuse/components/alert-dialog/alert-dialog.component';
import { FuncoesService } from 'app/services/funcoes.service';
import { Md5 } from 'md5-typescript';
@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ChangePasswordComponent implements OnInit {
    loginForm: FormGroup;
    changePasswordForm: FormGroup;
    button = 'ALTERAR';
    disabled = false;

    // mask
    usuario: number;
    senhaUsuario: number;

    dialogRef: any;
    alertDialogRef: MatDialogRef<FuseAlertDialogComponent>;
    parametros: {usuario: string,  sequencia: number, codigoAcesso: string, validaEmail: number, validadeDias: number};
    codigoUsuario: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private funcoes: FuncoesService,
        private activeRoute: ActivatedRoute,
        private matSnackBar: MatSnackBar,
        public _matDialog: MatDialog
    ) {
        this.activeRoute.params.subscribe(params => {
            this.parametros = {
                usuario: params['Us'], 
                sequencia: params['Sq'], 
                codigoAcesso: params['Ac'], 
                validaEmail: params['vE'], 
                validadeDias: params['vS']
            };
        });
        
        // if (!this.router.getCurrentNavigation().extras.hasOwnProperty("state")) {
        //     this.router.navigate(['/apps/home']);
        // } else {
        //     this.usuario = this.router.getCurrentNavigation().extras.state.Usuario;
        //     this.senhaUsuario = this.router.getCurrentNavigation().extras.state.SenhaUsuario;
        // }

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.changePasswordForm = this.createLoginForm();
    }

    /**
     * Create changePasswordForm form
     *
     * @returns {FormGroup}
     */
    createLoginForm(): FormGroup {
        return this._formBuilder.group({
            Usuario: this.usuario,
            NovaSenha: ['', [Validators.required, Validators.minLength(6)]],
            ConfirmaSenha: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    async ngOnInit() {
        this.authService.redirectHome();

        this.loginForm = this._formBuilder.group({
            Usuario: ['', [Validators.required]],
            Senha: ['', [Validators.required, Validators.minLength(6)]],
            Ip: [''],
            Browser: [''],
            Acesso: ['']
        });
    }

    async onSubmit() {
        if (this.changePasswordForm.valid) {
            if (this.changePasswordForm.controls.NovaSenha.value != this.changePasswordForm.controls.ConfirmaSenha.value) {
                this.authService.msg('A Nova Senha e Repita a nova senha devem ser iguais.', 5000);
            } else {
                this.changeButton(true, 'AGUARDE...');
                
                const dataToSend = {
                    Usuario: this.parametros.usuario,
                    CodigoUsuario: this.codigoUsuario,
                    Sequencia: this.parametros.sequencia,
                    CodigoAcesso: this.parametros.codigoAcesso,
                    Valida_Email_Recuperacao_Senha: this.parametros.validaEmail,
                    Dias_Expira_Senha: this.parametros.validadeDias,
                    NovaSenha: this.changePasswordForm.value.NovaSenha
                };

                await this.authService.alterarSenha(dataToSend)
                    .then((data) => {
                        if (data.hasOwnProperty('success') && data.success) {
                            this.alertDialog('Senha alterada com sucesso.', 'Ok', false);
                            const login = this.authService.checkLogin(
                                this.usuario,
                                Md5.init(this.changePasswordForm.controls.NovaSenha.value)
                            );

                            if (login) {
                                this.router.navigate(['/']);
                            } else {
                                this.dadosCliente();
                            }
                        } else if (data.hasOwnProperty("details") && data.details != null) {
                            this.authService.msg(data.details, 5000);
                            this.changeButton(false, 'ALTERAR');
                        } else {
                            this.authService.msg('Não foi possível alterar a senha!', 5000);
                            this.changeButton(false, 'ALTERAR');
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.changeButton(false, 'ALTERAR');
                    });
            }
        }
    }

    changeButton(disabled: boolean, message: string) {
        this.disabled = disabled;
        this.button = message;
    }


    dadosCliente(): any {
        this.authService.getIp()
            .then((result) => {
                this.loginForm.value.Ip = result['ip'];
                this.loginForm.value.Browser = this.funcoes.browserName();
                this.gravar();
            }).catch((err) => {
                console.log(err);
            });
    }

    gravar(): any {
        this.loginForm.value.Usuario = this.parametros.usuario;
        this.loginForm.value.Senha = this.changePasswordForm.controls.NovaSenha.value;
        this.loginForm.value.Acesso = 'P';
        this.authService.login(this.loginForm.value)
            .then((data) => {
                if (data) {
                    this.button = 'ALTERAR';
                    this.router.navigate(['/login']);
                } else {
                    this.button = 'ALTERAR';
                    this.authService.msg('Não foi possível realizar o Login, Entre em contato com o suporte!', 5000);
                    this.disabled = false;
                }
            });
    }

    /**
     * alertDialog
     */
    alertDialog(alertMessage: string, buttonMessage: string, alert: boolean): void {
        this.alertDialogRef = this._matDialog.open(FuseAlertDialogComponent, {
            disableClose: false
        });

        this.alertDialogRef.componentInstance.alertMessage = alertMessage;
        this.alertDialogRef.componentInstance.buttonMessage = buttonMessage;
        this.alertDialogRef.componentInstance.alert = alert;

        this.alertDialogRef.afterClosed().subscribe(result => {
            if (result) { }
            this.alertDialogRef = null;
        });
    }

    ngAfterViewInit() {
        if (this.parametros.usuario !== undefined 
            && this.parametros.sequencia !== undefined 
            && this.parametros.codigoAcesso !== undefined 
            && this.parametros.validaEmail !== undefined 
            && this.parametros.validadeDias !== undefined
        ) {
            this.consultarSolicitacaoRecuperacao();
        } else {
            this.matSnackBar.open("Não foi possível identificar uma solicitação para a recuperação de senha!", '', {
                duration: 10000,
            });
            this.router.navigate(['/login']);
        }
    }

    async consultarSolicitacaoRecuperacao() {
        await this.authService.consultarSolicitacaoRecuperacao(this.parametros).then(
        async data => {
            if (data !== null && data.hasOwnProperty('Codigo_Usuario')) {
                this.codigoUsuario = data.Codigo_Usuario;
            }
        });
    }
}
