import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-biometria',
  templateUrl: './info-biometria.component.html',
  styleUrls: ['./info-biometria.component.scss']
})
export class InfoBiometriaComponent implements OnInit {

  progress: boolean = false;

  constructor(
    public matDialogRef: MatDialogRef<InfoBiometriaComponent>,
  ) { }

  ngOnInit() {
    this.progress = true; 
  }

  scroll() {
    let el = document.getElementById('1');
    el.scrollIntoView(true);
  }

}
