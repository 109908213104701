export const routes = {

	// timeStamp que é apresentado no consolelog utilizado para o build no servidor, não deixar preenchido
	timeStamp: '09/05/2024 07:14:32 PM -03',

	// Rotas da Esteira de Crédito

	// auth
	auth_enter: '/auth/enterPlataforma',
	auth_enter_Consorciado: '/auth/enterPlataformaConsorciado',
	auth_out: '/auth/outPlataforma/',
	auth_recovery: '/auth/recoveryAnalista',
	auth_consorciado: '/auth/recoveryConsorciado',
	auth_change_password: '/auth/changePassword',
	auth_get_solicita_recuperar_senha: '/auth/consultarSolicitacaoRecuperacao',
	auth_alterar_senha: '/auth/alterarSenhaAnalista',

	//Cotas
	listCotas: '/cotas/listCotas',
	cotasBySituacao: '/cotas/cotasBySituacao',
	cotasBySituacaoAndId: '/cotas/cotasBySituacao/',
	get_cota_credito_aprovado: '/cotas/getCotaCreditoAprovado/',
	get_cota_idCotaCanopus: '/cotas/getCotaByIdCanopus/',
	get_cota: '/cotas/getCotaByIdCanopus/',
	edit_cota: '/cotas/editCota/',
	get_all_cotas: '/cotas/get',
	download_relatorio: '/cotas/relatorio/download',
	email_contemplacao: '/cotas/emailContemplacao',
	get_email_contemplacao: '/cotas/getEmailContemplacao',
	edit_conclusao: '/cotas/atualizaConclusaoDadosDocumentos/', 

	// Análise de Dados Pessoais
	analise_dados_pessoais_add: '/analise_dados_pessoais/addAnaliseDadosPessoais',
	list_analise_dados_pessoais: '/analise_dados_pessoais/listAnaliseDadosPessoais/',
	tipos_documentos_cota_delete: '/analise_dados_pessoais/deleteTiposDocumentosIdTipoDocumento/',
	mensagens_cotas_delete: '/analise_dados_pessoais/deleteMensagensCotasIdMensagem/',
	mensagens_cotas_delete_IdAnaliseDadosPessoais: '/analise_dados_pessoais/deleteMensageIdAnaliseDadosPessoais/',
	tipos_documentos_addModelo: '/analise_dados_pessoais/addModelo/',
	tipos_documentos_delModelo: '/analise_dados_pessoais/delModelo/',
	arquivos_addFile: '/analise_dados_pessoais/addFile/',
	update_data_servidor_newcon: '/analise_dados_pessoais/updateDataServidorNewcon/',
	list_dados_cota: '/analise_dados_pessoais/listDadosCota/',
	enviar_documentos_newcon: '/analise_dados_pessoais/enviarDocumentosNewcon',
	inativar_anexos_documentos: '/analise_dados_pessoais/inativarAnexosDocumentos/',

	//Historico
	listHistorico: '/cotasHistorico/listHistorico/',
	cotasHistorico_add: '/cotasHistorico/addHistorico',
	historico_addimagem: '/cotasHistorico/addimagem/',

	// auth ChatBot
	auth_chatbot_acesso_cpfcnpj: '/authChatBot/acessoCpfCnpj',
	auth_chatbot_recovery: '/authChatBot/recovery',
	auth_chatbot_change_password: '/authChatBot/changeSenha/',
	auth_chatbot_get: '/authChatBot/getDadosAutenticacao',

	// cotas Analista
	cotas_analista_list: '/cotas_analista/list/',
	cotas_analista_list_chunked: '/cotas_analista/listChunked/',
	cotas_analista_filter: '/cotas_analista/filter',
	cotas_analista_arquivar: '/cotas_analista/arquivar/',
	cotas_analista_filter_cpfCnpj: '/cotas_analista/filterCpfCnpj',
	cotas_analista_getCota: '/cotas_analista/getCota/',
	verifyByAnalista: '/cotas_analista/verify/',

	//tipos_documentos
	tipos_documentos_list: '/tipos_documentos/list/',
	tipos_documentos_Consorciado: '/tipos_documentos/getDocumentos',
	tipos_documentos_getBySituacao: '/tipos_documentos/getBySituacao/',
	tipos_documentos_getByCota: '/tipos_documentos/getByCota/',
	tipos_documentos_add: '/tipos_documentos/add',
	tipos_documentos_edit: '/tipos_documentos/edit/',
	tipos_documentos_delete: '/tipos_documentos/delete/',
	tipos_documentos_addFile: '/tipos_documentos/addFile/',

	// Menu Plataforma
	menu_plataforma_list: '/menu/listModulosPlataforma/',
	menu_plataforma_list_consorciado: '/menu/listModulosPlataformaConsorciado/',
	menu_plataforma_edit_atalhos: '/menu/editAtalhos/',
	menu_atalhos_list: '/menu/listAtalhosMenu/',
	//

	// Usuário plataforma
	usuarios_plataforma_add: '/usuarios_plataforma/add',
	usuarios_plataforma_addImagem: '/usuarios_plataforma/addimagem/',
	usuarios_plataforma_exists_imagem: '/usuarios_plataforma/existsImagem/',
	usuarios_plataforma_block: '/usuarios_plataforma/block/',
	usuarios_plataforma_delete: '/usuarios_plataforma/delete/',
	usuarios_plataforma_edit: '/usuarios_plataforma/edit/',
	usuarios_plataforma_get: '/usuarios_plataforma/get/',
	usuarios_plataforma_list: '/usuarios_plataforma/list/',
	usuarios_plataforma_filter: '/usuarios_plataforma/filter/',
	usuarios_plataforma_get_publicadores: '/usuarios_plataforma/getPublicadores/',
	usuarios_plataforma_deleteImagem: '/usuarios_plataforma/deleteImagem/',
	usuarios_plataforma_editDados: '/usuarios_plataforma/editDados/',
	listar_analistas: '/usuarios_plataforma/getAnalista',

	// privilegio plataforma
	privileges_list: '/privileges/list_plataforma/',
	privileges_edit: '/privileges/edit_plataforma/',

	// empresas
	empresas_list: '/empresas/listEmpresas/',
	empresas_list_filter: '/empresas/filter/',
	empresas_add: '/empresas/add',
	empresas_edit: '/empresas/edit/',
	empresas_delete: '/empresas/delete/',

	//Pessoa
	pessoas_dadosConsorciado: '/pessoas/consultarDadosConsorciado',
	pessoas_getByCpfCnpj: '/pessoas/getPessoaBycpfCNPJ/',
	pessoas_getRamos: '/pessoas/getRamos',
	pessoas_get_Endereco: '/pessoas/getEnderecoByCodPessoa/',
	pessoas_editPessoaJuridica: '/pessoas/editPessoaJuridica/',
	pessoas_editPessoaFisica: '/pessoas/editPessoaFisica/',
	pessoas_addImagem: '/pessoas/addImagem/',
	pessoas_edit_Endereco: '/pessoas/editEndereco/',
	pessoas_plataforma_addImagem: '/pessoas/addimagem/',

	pessoas_edit_PpeCNPJ: '/pessoas/editPpeCNPJ/',
	pessoas_edit_contato: '/pessoas/editContato/',
	pessoas_edit_contatoPJ: '/pessoas/editContatoPJ/',
	pessoas_edit_contatoPF: '/pessoas/editContatoPF/',
	pessoas_add_Socios: '/pessoas/addSocios/',
	pessoas_delete_Socios: '/pessoas/deleteSocios/',
	pessoas_get_SociosByPpe: '/pessoas/getSociosByPpe/',
	pessoas_edit_SocioPpe: '/pessoas/editSociosByPpe/',

	pessoas_get_Parentes: '/pessoas/getParentePpe/',
	pessoas_add_Parentes: '/pessoas/addParentePpe',
	pessoas_edit_Parentes: '/pessoas/editParentePpe/',
	pessoas_delete_Parente: '/pessoas/deleteParentePpe/',

	pessoas_get_SociosCotistas: '/pessoas/sociosCotistas/',
	pessoas_add_SociosCotistas: '/pessoas/addSociosCotistas',
	pessoas_edit_SociosCotistas: '/pessoas/editSociosCotistas/',
	pessoas_delete_SociosCotistas: '/pessoas/deleteSociosCotistas/',

	pessoas_get_EmpresasColigadas: '/pessoas/empresasColigadas/',
	pessoas_add_EmpresasColigadas: '/pessoas/addEmpresasColigadas',
	pessoas_edit_EmpresasColigadas: '/pessoas/editEmpresasColigadas/',
	pessoas_delete_EmpresasColigadas: '/pessoas/deleteEmpresasColigadas/',

	pessoas_get_ProdutosServicos: '/pessoas/produtosServicos/',
	pessoas_add_ProdutosServicos: '/pessoas/addProdutosServicos',
	pessoas_edit_ProdutoServico: '/pessoas/editProdutosServicos/',
	pessoas_delete_ProdutosServicos: '/pessoas/deleteProdutosServicos/',

	pessoas_get_ContasBancarias: '/pessoas/contasBancarias/',
	pessoas_add_ContasBancarias: '/pessoas/addContasBancarias/',
	pessoas_add_ContasBancarias_Avalista: '/pessoas/addContasBancariasAvalista',
	pessoas_edit_ContasBancarias: '/pessoas/editContasBancarias/',
	pessoas_delete_ContasBancarias: '/pessoas/deleteContasBancarias/',
	pessoas_delete_ContasBancariasByIdPessoa: '/pessoas/deleteContasBancariasByIdPessoa/',

	pessoas_get_ReferenciasPessoais: '/pessoas/referenciaPessoal/',
	pessoas_add_ReferenciasPessoais: '/pessoas/addReferenciaPessoal/',
	pessoas_add_ReferenciasPessoais_Avalista: '/pessoas/addReferenciaPessoalAvalista',
	pessoas_edit_ReferenciasPessoais: '/pessoas/editReferenciaPessoal/',
	pessoas_delete_ReferenciasPessoais: '/pessoas/deleteReferenciaPessoal/',
	pessoas_delete_ReferenciasPessoaisByIdPessoa: '/pessoas/deleteReferenciaPessoalByIdPessoa/',

	pessoas_get_ReferenciasComerciais: '/pessoas/referenciaComercial/',
	pessoas_add_ReferenciasComerciais: '/pessoas/addReferenciaComercial/',
	pessoas_add_ReferenciasComerciais_Avalista: '/pessoas/addReferenciaComercialAvalista',
	pessoas_edit_ReferenciasComerciais: '/pessoas/editReferenciaComercial/',
	pessoas_delete_ReferenciasComerciais: '/pessoas/deleteReferenciaComercial/',
	pessoas_delete_ReferenciasComerciaisByIdPessoa: '/pessoas/deleteReferenciaComercialByIdPessoa/',

	pessoas_get_PretensaoCompra: '/pessoas/pretensaoCompra/',
	pessoas_add_PretensaoCompra: '/pessoas/addPretensaoCompra/',
	pessoas_edit_PretensaoCompra: '/pessoas/editPretensaoCompra/',
	pessoas_delete_PretensaoCompra: '/pessoas/deletePretensaoCompra/',

	pessoas_get_BensImoveis: '/pessoas/bensImoveis/',
	pessoas_add_BensImoveis: '/pessoas/addBensImoveis/',
	pessoas_edit_BensImoveis: '/pessoas/editBensImoveis/',
	pessoas_delete_BensImoveis: '/pessoas/deleteBensImoveis/',

	pessoas_get_BensMoveis: '/pessoas/bensMoveis/',
	pessoas_add_BensMoveis: '/pessoas/addBensMoveis',
	pessoas_edit_BensMoveis: '/pessoas/editBensMoveis/',
	pessoas_delete_BensMoveis: '/pessoas/deleteBensMoveis/',

	pessoas_get_Dependentes: '/pessoas/dependentes/',
	pessoas_add_Dependentes:'/pessoas/addDependentes',
	pessoas_edit_Dependentes:'/pessoas/editDependentes/',
	pessoas_delete_Dependentes:'/pessoas/deleteDependentes/',
	pessoas_delete_DependentesByIdPessoa:'/pessoas/deleteDependentesByIdPessoa/',

	pessoas_edit_PpeCPF: '/pessoas/editPpeCPF/',

	pessoas_edit_Renda: '/pessoas/editRenda/',

	pessoas_save_avalista: '/pessoas/saveAvalista',

	pessoas_get_OutrosRendimentos: '/pessoas/outrosRendimentos/',
	pessoas_add_OutrosRendimentos: '/pessoas/addOutrosRendimentos',
	pessoas_edit_OutrosRendimentos: '/pessoas/editOutrosRendimentos/',
	pessoas_delete_OutrosRendimentos: '/pessoas/deleteOutrosRendimentos/',
	pessoas_delete_OutrosRendimentosByIdPessoa: '/pessoas/deleteOutrosRendimentosByIdPessoa/',

	pessoas_edit_Conjuge: '/pessoas/editConjuge/',
	pessoas_add_Conjuge: '/pessoas/addConjuge',

	pessoas_get_Patrimonio: '/pessoas/patrimonio/',
	pessoas_add_Patrimonio: '/pessoas/addPatrimonio',
	pessoas_edit_Patrimonio: '/pessoas/editPatrimonio/',
	pessoas_delete_Patrimonio: '/pessoas/deletePatrimonio/',
	pessoas_delete_PatrimonioByIdPessoa: '/pessoas/deletePatrimonioByIdPessoa/',
	pessoas_delete_DocumentoByIdPessoa: '/pessoas/deleteDocumentoByIdPessoa/',

	pessoas_set_Documentos: '/pessoas/setDocumento',
	pessoas_get_Documentos: '/pessoas/getDocumentoPessoa',
	pessoas_get_DocumentoByIdPessoa: '/pessoas/getDocumentoByIdPessoa',
	pessoas_limparAnexo: '/pessoas/limparAnexo/',
	pessoas_addFile: '/pessoas/addFile/',

	apagar_campos_form: '/pessoas/analistaEditaPessoa/',

	//Nova busca de documentos
	pessoas_getDocumentos: '/pessoas/documentos',
	pessoas_getDocumentosAnalista: '/pessoas/documentosAnalista',
	pessoas_add_Documentos: '/pessoas/add/doc',
	pessoas_delete_Documentos: '/pessoas/deleteDocumentos',
	pessoas_inativar_Documento: '/pessoas/inativaAnexo/',
	pessoas_inativar_Todos_Documento: '/pessoas/inativaTodosAnexos/',

	//Atualizar Newcon
	pessoas_atualizarNewcon: '/pessoas/atualizar-newcon',

	// avisos
	avisos_add: '/avisos/add',
	avisos_addimagem: '/avisos/addimagem/',
	avisos_exists_imagem: '/avisos/existsImagem/',
	avisos_delete: '/avisos/delete/',
	avisos_edit: '/avisos/edit/',
	avisos_get: '/avisos/get/',
	avisos_list: '/avisos/list/',
	avisos_listPlataforma: '/avisos/listPlataforma/',
	avisos_filter: '/avisos/filter/',
	avisos_filter_inicio: '/avisos/filterInicio/',
	envia_notificacao: '/crons/enviaNotificacao/',

	//mensagens
	mensagens_get: '/mensagens/get/',
	mensagens_add: '/mensagens/add',
	mensagens_edit: '/mensagens/edit/',
	mensagens_delete: '/mensagens/delete/',
	mensagens_list: '/mensagens/list/',
	mensagens_situacao: '/mensagens/listSituacao/',
	mensagens_situacao_cota: '/mensagens/listMensagensSituacaoIdSituacao/',
	mensagens_situacao_cota_usuario: '/mensagens/listMensagensSituacaoUsuario/',

	// calendario
	calendario_add_evento: '/calendario/add_evento',
	calendario_edit_evento: '/calendario/edit_evento/',
	calendario_list_evento: '/calendario/list_evento/',
	calendario_delete_evento: '/calendario/delete_evento/',

	// modulos
	modulos_list: '/modulos_plataforma/list/',

	// parametros
	parametros_edit: '/parametros/edit_plataforma/',
	parametros_edit_aniversariantes: '/parametros/edit_aniversariantes/',
	parametros_list_aniversariantes: '/parametros/list_aniversariantes/',

	// dashboard
	dashboard: '/dashboard/',
	analise_credito: '/dashboard/analista',
	analise_faturamento: '/dashboard/faturamento',
	relatorio_analitico: '/dashboard/relatorioAnalitico',

	// Arquivos
	arquivos_add: '/arquivos/add',
	//arquivos_addFile: '/arquivos/addFile/',
	arquivos_downloadFile: '/arquivos/downloadFile/',
	arquivos_exists_file: '/arquivos/existsFile/',
	arquivos_delete: '/arquivos/delete/',
	arquivos_edit: '/arquivos/edit/',
	arquivos_get: '/arquivos/get/',
	arquivos_list: '/arquivos/list/',
	arquivos_filter: '/arquivos/filter/',

	// AtalhosWeb
	atalhos_web_add: '/atalhos_web/add',
	atalhos_web_edit: '/atalhos_web/edit/',
	atalhos_web_get: '/atalhos_web/get/',
	atalhos_web_list: '/atalhos_web/list/',
	atalhos_web_list_categorias: '/atalhos_web/listCategorias/',
	atalhos_web_filter: '/atalhos_web/filter/',
	atalhos_web_delete: '/atalhos_web/delete/',

	// Categorias
	categorias_web_add: '/atalhos_web/addCategoria',
	categorias_web_edit: '/atalhos_web/editCategoria/',
	categorias_web_get: '/atalhos_web/getCategoria/',
	categorias_web_list: '/atalhos_web/listCategoria/',
	categorias_web_filter: '/atalhos_web/filterCategoria/',
	categorias_web_delete: '/atalhos_web/deleteCategoria/',

	//timeline
	timeline_list: '/timeline/list/',
	timeline_add_publicacao: '/timeline/add',
	timeline_add_imagem: '/timeline/addImagem/',
	timeline_edit_publicacao: '/timeline/edit/',
	timeline_delete_publicacao: '/timeline/delete/',
	timeline_like_post: '/timeline/likePost/',

	// AFVCanpus
	afv_canopus: '/afvcanopus/plataforma',

	//Situações
	situacao_list_by_id: '/situacao/listSituacaoCotaById/',
	situacao_list: '/situacao/listSituacao/',
	situacao_list_cota: '/situacao/listSituacaoCota/',
	situacao_edit: '/situacao/edit/',
	situacao_edit_email: '/situacao/editEmailSituacao/',
	situacao_edit_notificacao: '/situacao/editSituacaoNotificacao/',
	situacao_get_email: '/situacao/getSituacaoEmailById/',
	situacao_get_notificacao: '/situacao/getSituacoesNotificacaoById/',

	// Configurações -> Parametros
	parametros_add: '/parametros/add',
	parametros_get: '/parametros/get/',

	//Analista
	gera_link_avalista: '/cotas_analista/gerarLinkAvalista/',
	verify_token: '/avalista/verifyToken/',
	get_link_avalista: '/cotas_analista/getLinkAvalista/',
	get_dados_avalista: '/cotas_analista/getDadosAvalista/',
	enviar_link: '/cotas_analista/enviarLink',
	pessoas_enviaFormulario: '/avalista/enviaFormulario/',
	remover_fiador: '/cotas_analista/removerFiador/',

	//Situações Empregaticias
	situacao_empregaticia_list: '/situacoes_empregaticias/list/',
	adicionar_situacao_empregaticia: '/situacoes_empregaticias/add',
	editar_situacao_empregaticia: '/situacoes_empregaticias/edit',
	deletar_situacao_empregaticia: '/situacoes_empregaticias/delete/',
	listar_situacao_empregaticia: '/situacoes_empregaticias',
	situacoes_empregaticias: '/situacoesEmpregaticias',
	get_situacao_empregaticia_byId: '/situacoes_empregaticias/', 

	//Grupos Avalistas
	grupos_avalista_list: '/grupos_avalista/list',
	grupos_avalista_getGrupos: '/grupos_avalista/getGrupos',
	grupos_avalista_getGruposById: '/grupos_avalista/getGrupoById/',
	grupos_avalista_getGruposByCodigo: '/grupos_avalista/getGrupoByCodigo/',
	adicionar_grupo_avalista: '/grupos_avalista/insertGrupoAvalista',
	editar_grupo_avalista: '/grupos_avalista/editarGrupoAvalista',
	listar_grupos_avalistas: '/grupos_avalista',
	deletar_grupo_avalista:'/grupos_avalista/deletar/',

	//Situações Parametros Empregaticias
	adicionar_situacao_empregaticia_doc: '/situacoes_empregaticias/add/doc',
	deletar_situacao_empregaticia_doc: '/situacoes_empregaticias/delete/doc/',
	get_situacao_empregaticia_docs: '/situacoes_empregaticias/docs/',

	// Bens Pagamentos
	bens_list_by_cota: '/bens/listBensPagamentosCota',
	bens_get: '/bens/getBensPagamentosCota',
	bens_list_fabricantes: '/bens/listFabricantes',
	bens_list_situacoes_alienacao: '/bens/listSituacoesAlienacao',
	bens_list_combustiveis: '/bens/listCombustiveis',
	bens_list_cidades: '/bens/listCidades',
	bens_list_tipos_pagamentos: '/bens/listTiposPagamentos',
	bens_list_fornecedores: '/bens/listFornecedores',

	// Bem
	aquisicao_bem: '/bem/aquisicaoBem',
	add_aquisicao_bem: '/bem/addAquisicaoBem',
	edit_aquisicao_bem: '/bem/editAquisicaoBem/',
	remove_aquisicao_bem: '/bem/inativarAquisicaoBem/',
	bem_documento_set: '/bem/setDocumentoBem',
	bem_documento_get: '/bem/documentosBem',
	bem_documento_add: '/bem/add/documentoBem',
	bem_documento_analista_get: '/bem/documentosAnalistaBem',
	bem_documento_remove_anexo: '/bem/inativaAnexoBem/',
	atualizar_status_etapa: '/bem/atualizarStatusEtapa',
	documentos_bem: '/bem/documentosBem',
	buscar_bem_cota: '/bem/buscaBemCota',
	bem_atualiza_status_etapa: '/bem/atualizarStatusEtapa/',
	documentos_analista_bem: '/bem/documentosAnalistaBem',
	newcon_atualizar_bens: '/bens/integrarBensPagamentos',
	newcon_enviar_documentos_bem: '/analise_dados_pessoais/enviarDocumentosBemNewcon',
	remove_cota_vinculada_bem: '/bem/inativarCotaBem/',
	busca_cota_vinculada_bem: '/cotas/listaCotasVincular',
	add_cota_vinculada_bem: '/bem/addBemCota',
	apagar_campos_form_bem: '/bem/analistaEditaBem/',
	inativar_documentos_bem: '/bem/inativarAnexosDocumentos/',
	edit_conclusao_bem: '/bem/atualizaConclusaoDadosDocumentos/',

};