import { Component, OnInit, ViewEncapsulation, LOCALE_ID, ElementRef, AfterViewInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuncoesService } from 'app/services/funcoes.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../../../../services/api.service';
import { isArray, isNull } from 'lodash';
import { FiadorFormModalComponent } from './fiador-form-modal/fiador-form-modal.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from './moment-date-formats';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import './momentES';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
moment.locale('pt-BR')
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FiadorService } from './fiador.service';
import { CotasConsorciadoService } from 'app/main/apps/cotas/cotas-consorciado/cotasConsorciado.service';
import { environment } from 'environments/environment';
import { InfoBiometriaComponent } from 'app/main/apps/cotas/cotas-consorciado/cotas-consorciado-detalhes/cotas-consorciado-modal/info-biometria/info-biometria.component';



@Component({
    selector: 'fiador-form',
    templateUrl: './fiador-form.component.html',
    styleUrls: ['./fiador-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
})
export class FiadorFormComponent implements OnInit, AfterViewInit {
    form: FormGroup;

    token: any;

    IdPessoaFisica = '';
    IdEndereco = '';
    IdRenda = '';
    IdConjugue = '';


  
    folderSelected: any;
    editar: boolean = true;

    situacoesEmpregaticias: any[] = [];

    emailAvalita = "";

    fileData: File = null;
    files: any = null;
    UrlImagem: any = '../../../../../assets/images/avatars/profile.jpg';
    dialogRef: any;

    mensagem: boolean = false;
    erroDV: any = '';
    feedback = ' De acordo com a documentação oficial do DataValid, a imagem não deve estar borrada, danificada e com uma boa iluminação, além da resolução do rosto ser de no mínimo 250x250 pixels.'


    apiURL = environment.api_url;
    host = environment.api_url;

    dataSourceOutrosRendimentos = [];
    dataSourcePatrimonio = [];
    dataSourceReferenciasPessoais = [];
    dataSourceContaBancaria = [];
    dataSourceReferenciasComerciais = [];
    dataSourceDependentes = [];

    maskCpf = '000.000.000-00';
    maskCnpj = '00.000.000/0000-00';
    maskCEP = '00000-000';
    mailPattern = '^[A-Za-z0-9._%+-]{3,}@[a-zA-Z-0-9]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})$';
    textPatern = '[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ_ ]+$';
    numberPatern = '^[1-9][0-9]*$';
    addressPatern = '^[1-9A-Za-z][0-9A-Za-z]*$';
    maskCpfCnpj = '';
    maskTel_Cel = '';

    formValid = false;
    formSended = false;
    formInvalid = true;
    loading = false;

    doc: any;
    documentos: any;
    documentosAnexo: any;
    documentosAnalista: any;
    documentosForm: any;

    isReady: any;

    idCota: any;
    idPessoaFisica: any;
    PessoaFisicaCpf: any;

    estados = [
        "", "AC", "AL", "AM", "AP", "BA",
        "CE", "DF", "ES", "GO", "MA", "MG",
        "MS", "MT", "PA", "PB", "PI", "PE",
        "PR", "RJ", "RN", "RO", "RR", "RS",
        "SC", "SE", "SP", "TO",
    ];

    displayedColumnsDependentes: string[] = ['id', 'nome', 'remove'];
    displayedColumnsImoveis: string[] = ['id', 'descricao', 'valor', 'onus', 'prestacao', 'remove'];
    displayedColumnsMoveis: string[] = ['id', 'marca', 'modelo', 'ano', 'valor', 'remove'];
    displayedColumnsAcionistas: string[] = ['idsocios', 'nome', 'cargo', 'participacao', 'remove'];
    displayedColumnsEmpresasColigadas: string[] = ['id', 'nome', 'socioParticipante', 'participacao', 'remove']
    displayedColumnsContas: string[] = ['id', 'banco', 'agencia', 'numero', 'conta', 'remove'];
    displayedColumnsPessoais: string[] = ['id', 'nome', 'endereco', 'telefone', 'remove'];
    displayedColumnsComerciais: string[] = ['id', 'empresa', 'contato', 'telefone', 'remove'];
    displayedColumnsProdutosServicos: string[] = ['id', 'descricao', 'participacao', 'remove'];
    displayedColumnsPretensao: string[] = ['id', 'categoria', 'descricao', 'valor', 'remove'];
    displayedColumnsOutrosRendimentos: string[] = ['id', 'origem', 'valor', 'remove'];
    displayedColumnsPatrimonio: string[] = ['id', 'categoria', 'valor', 'onus', 'remove'];

    constructor(
        private _fuseConfigService: FuseConfigService,
        public _cotasConsorciadoService: CotasConsorciadoService,
        private _adapter: DateAdapter<any>,
        private _apiService: ApiService,
        public _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private router: Router,
        private apiService: ApiService,
        private matSnackBar: MatSnackBar,
        private funcoes: FuncoesService,
        private authService: AuthService,
        public datepipe: DatePipe,
        private fiadorService: FiadorService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.form = this.createFormControl({

            //identificacao
            IdPessoaFisica: [''],
            NomePessoa: ['', Validators.required],
            DataNascimento: ['', [Validators.required, FuncoesService.isDateValid]],
            Cpf: ['', [Validators.required, FuncoesService.ValidaCPFCNPJ]],
            RG: ['', Validators.required],
            OrgaoEmissor: ['', Validators.required],
            Sexo: ['', Validators.required],
            Nacionalidade: ['', Validators.required],
            Naturalidade: ['', Validators.required],
            EstadoCivil: ['', Validators.required],
            NomePai: ['', Validators.required],
            NomeMae: ['', Validators.required],
            IdEmpresa: [''],


            //endereco
            IdEndereco: [''],
            Endereco: ['', Validators.required],
            Numero: ['', [Validators.required, Validators.pattern(this.addressPatern)]],
            Bairro: ['', Validators.required],
            Complemento: [''],
            Cep: ['', Validators.required],
            Cidade: ['', [Validators.required, Validators.pattern(this.textPatern)]],
            UF: ['', Validators.required],
            EnderecoComplementar: [''],
            NumeroComplementar: ['', Validators.pattern(this.addressPatern)],
            BairroComplementar: [''],
            ComplementoComplementar: [''],
            CepComplementar: [''],
            CidadeComplementar: ['', Validators.pattern(this.textPatern)],
            UFComplementar: [''],
            ResidenciaAtual: [''],
            ValorAluguel: [''],
            TempoResidencia: ['', [Validators.pattern('^[0-9]*(?:,)?[0-9]?[0-9]$')]],
            EnderecoAnterior: [''],
            NumeroAnterior: [''],
            BairroAnterior: [''],
            ComplementoAnterior: [''],
            CidadeAnterior: ['', [Validators.pattern(this.textPatern)]],
            CepAnterior: [''],
            UFAnterior: [''],
            TempoResidenciaAnterior: [''],

            //contato
            Email: ['', [Validators.required, Validators.pattern(this.mailPattern)]],
            Telefone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11), FuncoesService.phoneValidation]],

            //renda
            IdRenda: [''],
            RendaSocioProprietario: ['', Validators.required],
            RendaEmpresa: ['', Validators.required],
            RendaCNPJ: ['', [FuncoesService.ValidaCPFCNPJ]],
            RendaEnderecoComercial: ['', Validators.required],
            RendaNumero: ['', [Validators.required, Validators.pattern(this.addressPatern)]],
            RendaBairro: ['', Validators.required],
            RendaComplemento: [''],
            RendaCEP: ['', Validators.required],
            RendaCidade: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(this.textPatern)]],
            RendaUF: [{ value: '', disabled: true }, Validators.required],
            RendaTelefoneFixo: ['', [Validators.required, FuncoesService.phoneValidation]],
            RendaCargo: ['', Validators.required],
            RendaDataAdmissao: ['', [Validators.required, FuncoesService.isDateValid]],
            RendaMensal: ['', Validators.required],
            PPE: ['', Validators.required],
            SituacaoEmpregaticia: ['', Validators.required],

            //conjuge
            NomeConjuge: ['', Validators.required],
            CpfConjuge: ['', [Validators.required, FuncoesService.ValidaCPFCNPJ]],
            RgConjuge: ['', Validators.required],
            EmpresaConjuge: [''],
            SocioProprietario: [''],
            CnpjConjuge: [''],
            CargoConjuge: [''],
            DataAdmissao: [''],
            RendaMensalConjuge: [''],
            TelefoneComercial: ['', [Validators.minLength(10), Validators.maxLength(11), FuncoesService.phoneValidation]],
            PossuiRenda: ['', Validators.required],
            TipoVinculo: [''],
            IdConjuge: ['']



        });

    }

    formatTooltip(value) {

        switch (value) {

            case 'P':
                return 'Poupança';
                break;

            case 'C':
                return 'Corrente';
                break;

            case 'I':
                return 'Imóvel';
                break;

            case 'V':
                return 'Veículo';
                break;

            case 'S':
                return 'Sim';
                break;

            case 'N':
                return 'Não';
                break;

            case 'Moveis':
                return 'Móveis';
                break;

            case 'Servicos':
                return 'Serviços';
                break;

            case 'Maquinas':
                return 'Máquinas e Equipamentos';
                break;

            case 'BMM':
                return 'Bens Móveis Múltiplos';
                break;

            case 'Energia':
                return 'Kit de Energia Fotovoltaica';
                break;

            case 'Conjuge':
                return 'Cônjuge';
                break;

            case 'Companheiro':
                return 'Companheiro(a)';
                break;

            case 'Enteado':
                return 'Enteado(a)';
                break;

            case 'Pais':
                return 'Pai/Mãe';
                break;

            case 'Irmao':
                return 'Irmão(ã)';
                break;

            default:
                return value;
                break;
        }

    }

    async ngOnInit() {

        this.loadSituacoesEmpregaticias();

        await this._adapter.setLocale('pt-BR');

        await this.activatedRoute.queryParams.subscribe(params => {
            this.token = params['token'];

            this.fiadorService.verifyToken(this.token).then((response: any) => {
                
                this.idCota = response.IdCota;
                this.idPessoaFisica = response.IdPessoaFisica;
                this.PessoaFisicaCpf = response.Cpf;
                this._cotasConsorciadoService.deletePatrimonioByIdPessoa(this.idPessoaFisica);
                this._cotasConsorciadoService.deleteContasBancariasByIdPessoa(this.idPessoaFisica);
                this._cotasConsorciadoService.deleteReferenciasPessoaisByIdPessoa(this.idPessoaFisica);
                this._cotasConsorciadoService.deleteReferenciasComerciaisByIdPessoa(this.idPessoaFisica);
                this._cotasConsorciadoService.deleteOutrosRendimentosByIdPessoa(this.idPessoaFisica);
                this._cotasConsorciadoService.deleteDependentesByIdPessoa(this.idPessoaFisica);

                // Inativa todos os anexos de documentos ao carregar a pagina
                this._cotasConsorciadoService.inativaTodosAnexos(this.idPessoaFisica);

                if (response.success == undefined) {

                    if (response.Enviado == 'S') {
                        this.formValid = false;
                        this.formInvalid = false;
                        this.formSended = true;
                    }
                    else {
                        this.IdPessoaFisica = response.IdPessoaFisica;
                        this.IdRenda = response.IdRenda;
                        this.IdConjugue = response.IdConjugue;
                        this.IdEndereco = response.IdEndereco;
                        this.formValid = true;
                        this.formInvalid = false;
                        this.emailAvalita = response.Email;

                        this._cotasConsorciadoService.getDependentes(this.IdPessoaFisica).then(resp => {
                            if (resp.data[0][0] != null) {
                                this.dataSourceDependentes = [];
                                resp.data[0][0].forEach(element => {
                                    this.dataSourceDependentes.push({
                                        IdDependentes: element.IdDependentes,
                                        Nome: element.Nome, IdPessoaFisica: element.IdPessoaFisica
                                    });
                                });
                            }
                        });

                        this._cotasConsorciadoService.getOutrosRendimentos(this.IdRenda).then(resp => {
                            this.dataSourceOutrosRendimentos = [];
                            if (resp.data[0][0] != null)
                                resp.data[0][0].forEach(element => {
                                    this.dataSourceOutrosRendimentos.push({
                                        IdOutros_rendimentos: element.IdOutros_rendimentos,
                                        Origem: element.Origem, Valor: element.Valor
                                    });
                                });
                        });

                        this._cotasConsorciadoService.getPatrimonio(this.IdPessoaFisica).then(resp => {
                            this.dataSourcePatrimonio = [];
                            if (resp.data[0] != '' && resp.data[0] != null)
                                resp.data[0][0].forEach(element => {
                                    this.dataSourcePatrimonio.push({
                                        IdPatrimonio: element.IdPatrimonio, Ano: element.Ano, Categoria: element.Categoria,
                                        Descricao: element.Descricao, DescricaoPatrimonio: element.DescricaoPatrimonio,
                                        IdEmpresa: element.IdEmpresa, IdPessoaFisica: element.IdPessoaFisica,
                                        Marca: element.Marca, Modelo: element.Modelo, Onus: element.Onus, ValorAtual: element.ValorAtual
                                    });
                                });


                        });

                        this._cotasConsorciadoService.getContasBancarias(this.IdPessoaFisica).then(resp => {
                            this.dataSourceContaBancaria = [];
                            if (resp != null)
                                resp.forEach(element => {
                                    this.dataSourceContaBancaria.push({
                                        IdReferenciaBancaria: element.IdReferenciaBancaria,
                                        Banco: element.Banco,
                                        Agencia: element.Agencia,
                                        Numero: element.Numero,
                                        Conta: element.Conta,
                                        IdEmpresa: element.IdEmpresa,
                                        IdPessoaFisica: element.IdPessoaFisica
                                    });
                                });
                        });

                        this._cotasConsorciadoService.getReferenciasPessoais(this.IdPessoaFisica).then(resp => {
                            this.dataSourceReferenciasPessoais = [];
                            if (resp.data[0][0] != null)
                                resp.data[0][0].forEach(element => {
                                    this.dataSourceReferenciasPessoais.push({
                                        IdReferenciaPessoal: element.IdReferenciaPessoal, Nome: element.Nome,
                                        Telefone: element.Telefone, Endereco: element.Endereco,
                                        IdEmpresa: element.IdEmpresa,
                                        IdPessoaFisica: element.IdPessoaFisica
                                    });
                                });
                        });

                        this._cotasConsorciadoService.getReferenciasComerciais(this.IdPessoaFisica).then(resp => {
                            this.dataSourceReferenciasComerciais = [];
                            if (resp.data[0] != '' && resp.data[0] != null)
                                resp.data[0][0].forEach(element => {
                                    this.dataSourceReferenciasComerciais.push({
                                        IdReferenciaComercial: element.IdReferenciaComercial,
                                        Empresa: element.Empresa, Cidade: element.Cidade,
                                        ContatoSetor: element.ContatoSetor, Telefone: element.Telefone,
                                        IdEmpresa: element.IdEmpresa,
                                        IdPessoaFisica: element.IdPessoaFisica
                                    });
                                });
                        });

                    }
                    // this.isReady = false;
                    this.pesquisaDocumentos();

                }
                else
                    this.formValid = false;

                this.loading = true;
            });

        });


        // await this.setDocumentos().then((r: any) => {
        //     this.isReady = true;
        // });

        setInterval(() => {
            Object.keys(this.form.controls).forEach(key => {

                const controlErrors: any = this.form.get(key).errors;
                if (controlErrors) {
                    Object.keys(controlErrors).forEach(keyError => {
                        // console.log({
                        //   'control': key,
                        //   'error': keyError,
                        //   'value': controlErrors[keyError]
                        // });
                    });
                }
            });
        }, 10000)
    }

    // async setDocumentos(){

    // await this._cotasConsorciadoService.getDocumentosFromSituacao(1).then(resp =>{
    //     if(resp[0] != null && resp[0] != undefined)
    //     {
    //   this.documentos = [];
    //   this.doc = resp;
    //   this.doc.forEach(element => {
    //     let data = {
    //       NomeDocumento: element.Descricao,
    //       UrlDocumento: null,
    //       IdPessoaFisica: this.idPessoaFisica,
    //       IdPessoaJuridica: null,
    //       Size: null,
    //       IdTipoDocumento: element.IdTipoDocumento,
    //       ExigeModelo: element.AnaliseAnalista,
    //       Url_Modelo: element.Url_Modelo,
    //       IdCotaUsuario: this.idCota
    //     };

    //     this._cotasConsorciadoService.setDocumentos(data).then(result =>{
    //       let resposta: any;
    //       resposta = result;
    //       resposta = resposta.data;
    //       if(isArray(resposta[0][0]))
    //         resposta.forEach(element => {
    //           let data = {
    //             IdDocumentoPessoa: element[0][0].IdDocumentoPessoa,
    //             NomeDocumento: element[0][0].NomeDocumento,
    //             UrlDocumento: element[0][0].UrlDocumento,
    //             IdPessoaFisica: this.idPessoaFisica,
    //             IdPessoaJuridica: null,
    //             Size: null,
    //             IdTipoDocumento: element[0][0].IdTipoDocumento,
    //             Url_Modelo: element[0][0].Url_Modelo,
    //             ExigeModelo: element[0][0].ExigeModelo,
    //             IdCotaUsuario: this.idCota
    //           }
    //           this.documentos.push(data);
    //           var resArr = [];
    //           this.documentos.forEach(function(item){
    //           var i = resArr.findIndex(x => x.IdDocumentoPessoa == item.IdDocumentoPessoa);
    //           if(i <= -1){
    //               resArr.push(item);
    //           }
    //           });
    //           this.documentos = resArr;
    //         }); 
    //       else
    //         resposta.forEach(element => {
    //           let data = {
    //             IdDocumentoPessoa: element[0].IdDocumentoPessoa,
    //             NomeDocumento: element[0].NomeDocumento,
    //             UrlDocumento: element[0].UrlDocumento,
    //             IdPessoaFisica: this.idPessoaFisica,
    //             IdPessoaJuridica: null,
    //             Size: null,
    //             IdTipoDocumento: element[0].IdTipoDocumento,
    //             Url_Modelo: element[0].Url_Modelo,
    //             ExigeModelo: element[0].ExigeModelo,
    //             IdCotaUsuario: this.idCota
    //           }
    //           this.documentos.push(data);
    //           var resArr = [];
    //           this.documentos.forEach(function(item){
    //             var i = resArr.findIndex(x => x.IdDocumentoPessoa == item.IdDocumentoPessoa);
    //             if(i <= -1){
    //               resArr.push(item);
    //             }
    //           });
    //           this.documentos = resArr;
    //         }); 
    //     });
    //   });
    // }
    // });
    // if (this.form.controls.SituacaoEmpregaticia.value) {
    //     this._cotasConsorciadoService.getDocumentosFromEmprego(this.form.controls.SituacaoEmpregaticia.value).then((resp:any) =>{
    //     if(isArray(resp.data[0][0])){
    //         resp.data[0][0].forEach(element => {
    //         let dataEmprego = {
    //             NomeDocumento: element.Descricao,
    //             UrlDocumento: null,
    //             IdPessoaFisica: this.idPessoaFisica,
    //             IdPessoaJuridica: null,
    //             Size: null,
    //             IdTipoDocumento: element.IdTipoDocumento,
    //             ExigeModelo: element.AnaliseAnalista,
    //             Url_Modelo: element.Url_Modelo,
    //             IdCotaUsuario: this.idCota
    //         };
    //         this._cotasConsorciadoService.setDocumentos(dataEmprego).then(result =>{
    //             let resposta: any;
    //             resposta = result;
    //             resposta = resposta.data;
    //             if(isArray(resposta[0][0]))
    //             resposta.forEach(element => {
    //                 let data = {
    //                 IdDocumentoPessoa: element[0][0].IdDocumentoPessoa,
    //                 NomeDocumento: element[0][0].NomeDocumento,
    //                 UrlDocumento: element[0][0].UrlDocumento,
    //                 IdPessoaFisica: this.idPessoaFisica,
    //                 IdPessoaJuridica: null,
    //                 Size: null,
    //                 IdTipoDocumento: element[0][0].IdTipoDocumento,
    //                 Url_Modelo: element[0][0].Url_Modelo,
    //                 ExigeModelo: element[0][0].ExigeModelo,
    //                 IdCotaUsuario: this.idCota
    //                 }
    //                 this.documentos.push(data);
    //                 var resArr = [];
    //                 this.documentos.forEach(function(item){
    //                 var i = resArr.findIndex(x => x.IdDocumentoPessoa == item.IdDocumentoPessoa);
    //                 if(i <= -1){
    //                     resArr.push(item);
    //                 }
    //                 });
    //                 this.documentos = resArr;
    //             });  
    //         });
    //         });
    //     }
    //     });
    // }

    // }

    async changeEmprego() {

        await this._cotasConsorciadoService.getDocumentosFromSituacao(1).then(resp => {
            if (resp[0] != null && resp[0] != undefined) {
                this.documentos = [];
                this.doc = resp;
                this.doc.forEach(element => {
                    let data = {
                        NomeDocumento: element.Descricao,
                        UrlDocumento: null,
                        IdPessoaFisica: this.idPessoaFisica,
                        IdPessoaJuridica: null,
                        Size: null,
                        IdTipoDocumento: element.IdTipoDocumento,
                        ExigeModelo: element.AnaliseAnalista,
                        Url_Modelo: element.Url_Modelo,
                        IdCotaUsuario: this.idCota
                    };

                    this._cotasConsorciadoService.setDocumentos(data).then(result => {
                        let resposta: any;
                        resposta = result;
                        resposta = resposta.data;
                        if (isArray(resposta[0][0]))
                            resposta.forEach(element => {
                                let data = {
                                    IdDocumentoPessoa: element[0][0].IdDocumentoPessoa,
                                    NomeDocumento: element[0][0].NomeDocumento,
                                    UrlDocumento: element[0][0].UrlDocumento,
                                    IdPessoaFisica: this.idPessoaFisica,
                                    IdPessoaJuridica: null,
                                    Size: null,
                                    IdTipoDocumento: element[0][0].IdTipoDocumento,
                                    Url_Modelo: element[0][0].Url_Modelo,
                                    ExigeModelo: element[0][0].ExigeModelo,
                                    IdCotaUsuario: this.idCota
                                }
                                this.documentos.push(data);
                                var resArr = [];
                                this.documentos.forEach(function (item) {
                                    var i = resArr.findIndex(x => x.IdDocumentoPessoa == item.IdDocumentoPessoa);
                                    if (i <= -1) {
                                        resArr.push(item);
                                    }
                                });
                                this.documentos = resArr;
                            });
                        else
                            resposta.forEach(element => {
                                let data = {
                                    IdDocumentoPessoa: element[0].IdDocumentoPessoa,
                                    NomeDocumento: element[0].NomeDocumento,
                                    UrlDocumento: element[0].UrlDocumento,
                                    IdPessoaFisica: this.idPessoaFisica,
                                    IdPessoaJuridica: null,
                                    Size: null,
                                    IdTipoDocumento: element[0].IdTipoDocumento,
                                    Url_Modelo: element[0].Url_Modelo,
                                    ExigeModelo: element[0].ExigeModelo,
                                    IdCotaUsuario: this.idCota
                                }
                                this.documentos.push(data);
                                var resArr = [];
                                this.documentos.forEach(function (item) {
                                    var i = resArr.findIndex(x => x.IdDocumentoPessoa == item.IdDocumentoPessoa);
                                    if (i <= -1) {
                                        resArr.push(item);
                                    }
                                });
                                this.documentos = resArr;
                            });
                    });
                });
            }
        });

        // this._cotasConsorciadoService.getDocumentosFromEmprego(this.form.controls.SituacaoEmpregaticia.value).then((resp: any) => {
        // if (isArray(resp.data[0][0])) {
        //     resp.data[0][0].forEach(element => {
        //         let dataEmprego = {
        //             NomeDocumento: element.Descricao,
        //             UrlDocumento: null,
        //             IdPessoaFisica: this.idPessoaFisica,
        //             IdPessoaJuridica: null,
        //             Size: null,
        //             IdTipoDocumento: element.IdTipoDocumento,
        //             ExigeModelo: element.AnaliseAnalista,
        //             Url_Modelo: element.Url_Modelo,
        //             IdCotaUsuario: this.idCota
        //         };
        //         this._cotasConsorciadoService.setDocumentos(dataEmprego).then(result => {
        //             let resposta: any;
        //             resposta = result;
        //             resposta = resposta.data;
        //             if (isArray(resposta[0][0]))
        //                 resposta.forEach(element => {
        //                     let data = {
        //                         IdDocumentoPessoa: element[0][0].IdDocumentoPessoa,
        //                         NomeDocumento: element[0][0].NomeDocumento,
        //                         UrlDocumento: element[0][0].UrlDocumento,
        //                         IdPessoaFisica: this.idPessoaFisica,
        //                         IdPessoaJuridica: null,
        //                         Size: null,
        //                         IdTipoDocumento: element[0][0].IdTipoDocumento,
        //                         Url_Modelo: element[0][0].Url_Modelo,
        //                         ExigeModelo: element[0][0].ExigeModelo,
        //                         IdCotaUsuario: this.idCota
        //                     }
        //                     this.documentos.push(data);
        //                     var resArr = [];
        //                     this.documentos.forEach(function (item) {
        //                         var i = resArr.findIndex(x => x.IdDocumentoPessoa == item.IdDocumentoPessoa);
        //                         if (i <= -1) {
        //                             resArr.push(item);
        //                         }
        //                     });
        //                     this.documentos = resArr;
        //                 });
        //         });
        //     });
        // }
        // });

    }

    save() {
        this.form.controls.IdPessoaFisica.setValue(this.IdPessoaFisica);
        this.form.controls.IdEndereco.setValue(this.IdEndereco);
        this.form.controls.IdRenda.setValue(this.IdRenda);
        this.form.controls.IdConjuge.setValue(this.IdConjugue);

        this.createModal('validation', 'question', this.form.getRawValue());
    }

    validateTables(form) {
        let validations = [];

        //documentos
        this.documentos.forEach(element => {
            if (element.UrlDocumento == '' || element.UrlDocumento == null)
                validations.push('Documentos-' + element.NomeDocumento);
        });

        //validar 
        if (this.files == null)
            validations.push('Adicionar Imagem');

        if (this.dataSourcePatrimonio.length == 0)
            validations.push('Patrimônio');

        if (this.dataSourceContaBancaria.length == 0)
            validations.push('Referências Bancárias');

        if (this.dataSourceReferenciasPessoais.length == 0)
            validations.push('Referências Pessoais');

        if (this.dataSourceReferenciasComerciais.length == 0)
            validations.push('Referências Comerciais');

        if (validations.length == 0)
            this.saveForm(form);
        else
            this.createModal('validation', 'invalid', validations)

    }

    saveForm(form) {
        form = this.form.getRawValue();
        var data = form.DataNascimento;
        var dataR = form.RendaDataAdmissao;
        var dataC = form.DataAdmissao;

        var ano = data.substring(4, 8);
        var mes = data.substring(2, 4);
        var dia = data.substring(0, 2);
        form.DataNascimento = ano + '-' + mes + '-' + dia;

        var ano = dataR.substring(4, 8);
        var mes = dataR.substring(2, 4);
        var dia = dataR.substring(0, 2);
        form.RendaDataAdmissao = ano + '-' + mes + '-' + dia;

        var ano = dataC.substring(4, 8);
        var mes = dataC.substring(2, 4);
        var dia = dataC.substring(0, 2);
        form.DataAdmissao = ano + '-' + mes + '-' + dia;

        form.TempoResidencia = form.TempoResidencia.split(',').join('.');
        form.TempoResidenciaAnterior = form.TempoResidenciaAnterior.split(',').join('.');

        this._cotasConsorciadoService.saveAvalista(form).then((resp: any) => {
            if (resp.success)
                this.loading = false;
            this._cotasConsorciadoService.enviaFormulario(this.token).then((resp: any) => {
                this.loading = true;
                this.formInvalid = false;
                this.formValid = false;
                this.formSended = true;
            })
        });

        document.getElementById('login').scrollIntoView(true);

    }

    ngAfterViewInit() {


    }

    onSubmit() {

    }

    async ocupaCargo(value) {
        if (value == 'S') {
            await this.form.controls.CargoOcupado.setValidators(Validators.required);
        }
        else {
            await this.form.controls.CargoOcupado.setValidators([]);
            await this.form.controls.CargoOcupado.setValue('');
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    fileProgress(fileInput: any): void {
        this.fileData = fileInput.target.files[0] as File;
        this.preview();
    }

    preview(): void {
        // Show preview 
        const mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }else{

                const reader = new FileReader();
                reader.readAsDataURL(this.fileData);
                reader.onload = (_event) => {
                    this.UrlImagem = reader.result;
                };
        
                const formData = new FormData();
                formData.append('UrlImagem', this.fileData);
                this.files = formData;

                this.form.controls.IdPessoaFisica.setValue(this.IdPessoaFisica);
                var formFiador = this.form.getRawValue();
                var data = formFiador.DataNascimento;
                var ano = data.substring(4, 8);
                var mes = data.substring(2, 4);
                var dia = data.substring(0, 2);
                formFiador.DataNascimento = ano + '-' + mes + '-' + dia;

                this._cotasConsorciadoService.uploadFotoAvalista(this.IdPessoaFisica, this.files, formFiador).then((resp: any) => {
                    !resp.success ? this.erroDV = resp : this.erroDV = '';
                });
                // this._cotasConsorciadoService.uploadFotoAvalista(this.IdPessoaFisica, this.files).then((resp: any) => {
                // });
            }
        }


    modifyTelefone(value): string {
        if (value == null)
            return '';

        return this.isFixoTable(value) ? '(' + value.slice(0, 2) + ') ' + value.slice(2, 6) + '-' + value.slice(6, 11) :
            '(' + value.slice(0, 2) + ') ' + value.slice(2, 7) + '-' + value.slice(7, 11);
    }

    confirmarDados() {
        this.createModal('validation', 'question', null);
    }

    modifyCurrency(value): string {

        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',

        });

        value = formatter.format(value);

        return value;

    }

    isFixo(): boolean {
        return this.form.value.Telefone == null ? true : this.form.value.Telefone.length < 11 ? true : false;
    }

    isFixoTable(value): boolean {
        return value == null ? true : value.length < 11 ? true : false;
    }

    getTelefoneMask(): string {
        return this.isFixo() ? '(00) 0000-00009' : '(00) 00000-0000';
    }

    isFixoRenda(): boolean {
        return this.form.value.RendaTelefoneFixo == null ? true : this.form.value.RendaTelefoneFixo.length < 11 ? true : false;
    }

    isFixoConjuge(): boolean {
        return this.form.value.TelefoneComercial == null ? true : this.form.value.TelefoneComercial.length < 11 ? true : false;
    }

    getConjugeTelefoneMask(): string {
        return this.isFixoConjuge() ? '(00) 0000-00009' : '(00) 00000-0000';
    }


    getRendaTelefoneMask(): string {
        return this.isFixoRenda() ? '(00) 0000-00009' : '(00) 00000-0000';
    }


    changeCEP(cep, target) {

        switch (target) {

            case 'endereco':
                if (cep.length == 8)
                    this._apiService.buscaCidadePorCep(cep).then(resp => {
                        if (!resp.erro) {
                            this.form.controls.Cidade.setValue(resp.localidade);
                            this.form.controls.Cidade.disable();
                            this.form.controls.UF.setValue(resp.uf);
                            this.form.controls.UF.disable();
                        }
                        else {
                            this._apiService.msg('CEP não encontrado, favor inserir os dados manualmente!', 5000);
                            this.form.controls.Cidade.enable();
                            this.form.controls.UF.enable();
                        }
                    });

                else {
                    this.form.controls.Cidade.setValue('');
                    this.form.controls.UF.setValue('');
                }
                break;

            case 'complementar':
                if (cep.length == 8)
                    this._apiService.buscaCidadePorCep(cep).then(resp => {
                        if (!resp.erro) {
                            this.form.controls.CidadeComplementar.setValue(resp.localidade);
                            this.form.controls.CidadeComplementar.disable();
                            this.form.controls.UFComplementar.setValue(resp.uf);
                            this.form.controls.UFComplementar.disable();
                        }
                        else {
                            this._apiService.msg('CEP não encontrado, favor inserir os dados manualmente!', 5000);
                            this.form.controls.CidadeComplementar.enable();
                            this.form.controls.UFComplementar.enable();
                        }
                    });
                else {
                    this.form.controls.CidadeComplementar.setValue('');
                    this.form.controls.UFComplementar.setValue('');
                }
                break;

            case 'anterior':
                if (cep.length == 8)
                    this._apiService.buscaCidadePorCep(cep).then(resp => {
                        if (!resp.erro) {
                            this.form.controls.CidadeAnterior.setValue(resp.localidade);
                            this.form.controls.CidadeAnterior.disable();
                            this.form.controls.UFAnterior.setValue(resp.uf);
                            this.form.controls.UFAnterior.disable();
                        }
                        else {
                            this._apiService.msg('CEP não encontrado, favor inserir os dados manualmente!', 5000);
                            this.form.controls.CidadeAnterior.enable();
                            this.form.controls.UFAnterior.enable();
                        }
                    });
                else {
                    this.form.controls.CidadeAnterior.setValue('');
                    this.form.controls.UFAnterior.setValue('');
                }
                break;

            case 'renda':
                if (cep.length == 8)
                    this._apiService.buscaCidadePorCep(cep).then(resp => {
                        if (!resp.erro) {
                            this.form.controls.RendaCidade.setValue(resp.localidade);
                            this.form.controls.RendaCidade.disable();
                            this.form.controls.RendaUF.setValue(resp.uf);
                            this.form.controls.RendaUF.disable();
                        }
                        else {
                            this._apiService.msg('CEP não encontrado, favor inserir os dados manualmente!', 5000);
                            this.form.controls.RendaCidade.enable();
                            this.form.controls.RendaUF.enable();
                        }
                    });
                else {
                    this.form.controls.RendaCidade.setValue('');
                    this.form.controls.RendaUF.setValue('');
                }
                break;
        }


    }

    async setValidators(opcao) {

        if (opcao === 'S') {
            await this.form.controls.Financeira.setValidators(Validators.required);
            await this.form.controls.Valor.setValidators(Validators.required);
            await this.form.controls.PrestacaoPagas.setValidators(Validators.pattern(this.numberPatern));
            await this.form.controls.PrestacaoAVencer.setValidators(Validators.pattern(this.numberPatern));
        }
        else {
            await this.form.controls.Financeira.setValidators([]);
            await this.form.controls.Valor.setValidators([]);
            await this.form.controls.PrestacaoPagas.setValidators([]);
            await this.form.controls.PrestacaoAVencer.setValidators([]);
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async loadSituacoesEmpregaticias() {
        await this.apiService.GetSituacoesEmpregaticias(`${environment.situacoes_empregaticias}`)
            .then((response: any) => {
                this.situacoesEmpregaticias = response[0];
            });
    }


    async setValidatorsRendaConjuge(opcao) {

        if (opcao === 'S') {
            await this.form.controls.TipoVinculo.setValidators(Validators.required);
            await this.form.controls.SocioProprietario.setValidators(Validators.required);
            await this.form.controls.EmpresaConjuge.setValidators(Validators.required);
            await this.form.controls.CnpjConjuge.setValidators([Validators.required, FuncoesService.ValidaCPFCNPJ]);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators(Validators.required);
            await this.form.controls.RendaMensalConjuge.setValidators(Validators.required);
            await this.form.controls.TelefoneComercial.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(11)]);
        }
        else {
            await this.form.controls.TipoVinculo.setValidators([]);
            await this.form.controls.SocioProprietario.setValidators([]);
            await this.form.controls.EmpresaConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CargoConjuge.setValidators([]);
            await this.form.controls.DataAdmissao.setValidators([]);
            await this.form.controls.RendaMensalConjuge.setValidators([]);
            await this.form.controls.TelefoneComercial.setValidators([]);

            await this.form.controls.TipoVinculo.setValue('');
            await this.form.controls.SocioProprietario.setValue('');
            await this.form.controls.EmpresaConjuge.setValue('');
            await this.form.controls.CnpjConjuge.setValue('');
            await this.form.controls.CargoConjuge.setValue('');
            await this.form.controls.DataAdmissao.setValue('');
            await this.form.controls.RendaMensalConjuge.setValue('');
            await this.form.controls.TelefoneComercial.setValue('');
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsVinculoConjuge(opcao) {

        if (opcao === 'C') {
            await this.form.controls.SocioProprietario.setValidators(Validators.required);
            await this.form.controls.EmpresaConjuge.setValidators(Validators.required);
            await this.form.controls.CnpjConjuge.setValidators([Validators.required, FuncoesService.ValidaCPFCNPJ]);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators([Validators.required, FuncoesService.isDateValid]);
            await this.form.controls.RendaMensalConjuge.setValidators([Validators.required]);
            await this.form.controls.TelefoneComercial.setValidators, [Validators.required, Validators.pattern(this.maskTel_Cel)];
        }
        else {
            await this.form.controls.SocioProprietario.setValidators([]);
            await this.form.controls.EmpresaConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators([]);
            await this.form.controls.RendaMensalConjuge.setValidators([Validators.required]);
            await this.form.controls.TelefoneComercial.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(11)]);

            await this.form.controls.SocioProprietario.setValue('');
            await this.form.controls.EmpresaConjuge.setValue('');
            await this.form.controls.CnpjConjuge.setValue('');
            await this.form.controls.DataAdmissao.setValue('');
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsLimite(opcao) {

        if (opcao != 'P')
            await this.form.controls.Limite.setValidators(Validators.required);

        else {
            await this.form.controls.Limite.setValidators([]);
            await this.form.controls.Limite.setValue('');
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }


    async setValidatorsCartao(opcao) {

        if (opcao === 'S') {
            await this.form.controls.LimiteCartao.setValidators(Validators.required);
            await this.form.controls.ApresentouFatura.setValidators(Validators.required);

        }
        else if (opcao === 'N') {
            await this.form.controls.LimiteCartao.setValidators([]);
            await this.form.controls.ApresentouFatura.setValidators([]);

            await this.form.controls.LimiteCartao.setValue('');
            await this.form.controls.ApresentouFatura.setValue('');

        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsResidenciaAtual(opcao) {

        if (opcao === 'A' || opcao === 'F')
            await this.form.controls.ValorAluguel.setValidators([Validators.required, Validators.min(0.01)]);


        else {
            await this.form.controls.ValorAluguel.setValidators([]);
            await this.form.controls.ValorAluguel.setValue('');
        }


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsRenda(opcao) {
        if (opcao == 'S') {
            await this.form.controls.RendaCNPJ.setValidators([Validators.required]);
        } else {
            await this.form.controls.RendaCNPJ.clearValidators();
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });
    }

    async setValidatorsConjuge(opcao) {

        if (opcao === 'S')
            await this.form.controls.CnpjConjuge.setValidators([Validators.required, FuncoesService.ValidaCPFCNPJ]);

        else {
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValue('');
        }


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsPatrimonio(opcao) {

        if (opcao === 'I') {
            await this.form.controls.Descricao.setValidators([Validators.required]);
            await this.form.controls.Marca.setValidators([]);
            await this.form.controls.Modelo.setValidators([]);
            await this.form.controls.Ano.setValidators([]);

        }

        else if (opcao === 'V') {
            await this.form.controls.Descricao.setValidators([]);
            await this.form.controls.Marca.setValidators(Validators.required);
            await this.form.controls.Modelo.setValidators(Validators.required);
            await this.form.controls.Ano.setValidators([Validators.required, Validators.pattern(this.numberPatern)]);

        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsHipoteca(opcao) {

        if (opcao === 'S')
            await this.form.controls.ValorPrestacao.setValidators(Validators.required);

        else {
            await this.form.controls.ValorPrestacao.setValidators([]);
            await this.form.controls.ValorPrestacao.setValue('');

        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }


    async setValidatorsEndereco(opcao) {

        if (opcao.includes('0,')) {
            await this.form.controls.EnderecoAnterior.setValidators(Validators.required);
            await this.form.controls.NumeroAnterior.setValidators([Validators.required, Validators.pattern(this.addressPatern)]);
            await this.form.controls.BairroAnterior.setValidators(Validators.required);
            await this.form.controls.CidadeAnterior.setValidators([Validators.required, Validators.pattern(this.textPatern)]);
            await this.form.controls.CepAnterior.setValidators(Validators.required);
            await this.form.controls.UFAnterior.setValidators(Validators.required);
            await this.form.controls.TempoResidenciaAnterior.setValidators([Validators.required, Validators.pattern('^[0-9]*(?:,)?[0-9]?[0-9]$')]);
        }

        else {
            await this.form.controls.EnderecoAnterior.setValidators([]);
            await this.form.controls.NumeroAnterior.setValidators([]);
            await this.form.controls.BairroAnterior.setValidators([]);
            await this.form.controls.CidadeAnterior.setValidators([]);
            await this.form.controls.CepAnterior.setValidators([]);
            await this.form.controls.UFAnterior.setValidators([]);
            await this.form.controls.TempoResidenciaAnterior.setValidators([]);
        }

        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    async setValidatorsPretensao(opcao) {

        if (opcao === 'Maquinas' || opcao === 'BMM') {
            await this.form.controls.Marca.setValidators([]);
            await this.form.controls.Modelo.setValidators([]);
            await this.form.controls.Ano.setValidators([]);
            await this.form.controls.Estado.setValidators(Validators.required);

        }
        else if (opcao === 'Automoveis') {
            await this.form.controls.Estado.setValidators(Validators.required);
            await this.form.controls.Modelo.setValidators(Validators.required);
            await this.form.controls.Ano.setValidators([Validators.required, Validators.pattern(this.numberPatern)]);
            await this.form.controls.Marca.setValidators(Validators.required);

        }
        else if (opcao === 'Servicos' || opcao === 'Energia') {
            await this.form.controls.Marca.setValidators([]);
            await this.form.controls.Modelo.setValidators([]);
            await this.form.controls.Ano.setValidators([]);
            await this.form.controls.Estado.setValidators([]);

        }


        Object.keys(this.form.controls).forEach(field => {
            let control = this.form.get(field);
            control.updateValueAndValidity();
        });

    }

    createFormControl(form: Object): FormGroup {
        return this._formBuilder.group(form);
    }

    createModal(mainData, action, source) {
        this.dialogRef = this._matDialog.open(FiadorFormModalComponent,
            {
                data: {
                    action: action,
                    mainData: mainData,
                    source: source,
                }

            });

        this.dialogRef.afterClosed().subscribe((response) => {

            switch (mainData) {

                case 'outrosRendimentos':

                    if (action == 'create')
                        this._cotasConsorciadoService.addOutrosRendimentos(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getOutrosRendimentos(this.IdRenda).then(resp => {
                                this.dataSourceOutrosRendimentos = [];
                                if (resp.data[0][0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceOutrosRendimentos.push({
                                            IdOutros_rendimentos: element.IdOutros_rendimentos,
                                            Origem: element.Origem, 
                                            Valor: element.Valor,
                                            IdRenda:this.IdRenda
                                        });
                                    });
                                // console.log(this.dataSourceOutrosRendimentos);
                            });
                        });

                    else if (action == 'update')
                        this._cotasConsorciadoService.editOutrosRendimentos(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getOutrosRendimentos(this.IdRenda).then(resp => {
                                this.dataSourceOutrosRendimentos = [];
                                if (resp.data[0][0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceOutrosRendimentos.push({
                                            IdOutros_rendimentos: element.IdOutros_rendimentos,
                                            Origem: element.Origem, 
                                            Valor: element.Valor,
                                            IdRenda:this.IdRenda
                                        });
                                    });
                                // console.log(this.dataSourceOutrosRendimentos);
                            });

                        });

                    else {
                        this._cotasConsorciadoService.deleteOutrosRendimentos(response.getRawValue().id).then(a => {

                            this._cotasConsorciadoService.getOutrosRendimentos(this.IdRenda).then(resp => {
                                this.dataSourceOutrosRendimentos = [];
                                if (resp.data[0][0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceOutrosRendimentos.push({
                                            IdOutros_rendimentos: element.IdOutros_rendimentos,
                                            Origem: element.Origem, Valor: element.Valor
                                        });
                                    });
                                // console.log(this.dataSourceOutrosRendimentos);
                            });
                        });
                    }

                    break;

                case 'dependentes':

                    if (action == 'create')
                        this._cotasConsorciadoService.addDependentes(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getDependentes(this.IdPessoaFisica).then(resp => {
                                if (resp.data[0][0] != null) {
                                    this.dataSourceDependentes = [];
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceDependentes.push({
                                            IdDependentes: element.IdDependentes,
                                            Nome: element.Nome, IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                                }
                            });
                        });

                    else if (action == 'update')
                        this._cotasConsorciadoService.editDependentes(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getDependentes(this.IdPessoaFisica).then(resp => {
                                if (resp.data[0][0] != null) {
                                    this.dataSourceDependentes = [];
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceDependentes.push({
                                            IdDependentes: element.IdDependentes,
                                            Nome: element.Nome, IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                                }
                            });
                        });

                    else {
                        this._cotasConsorciadoService.deleteDependentes(response.getRawValue().id).then(a => {

                            this.dataSourceDependentes = [];
                            this._cotasConsorciadoService.getDependentes(this.IdPessoaFisica).then(resp => {
                                if (resp.data[0][0] != null) {
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceDependentes.push({
                                            IdDependentes: element.IdDependentes,
                                            Nome: element.Nome, IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                                }
                            });
                        });
                    }

                    break;

                case 'patrimonio':

                    if (action == 'create') {
                        this._cotasConsorciadoService.addPatrimonio(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getPatrimonio(this.IdPessoaFisica).then(resp => {
                                this.dataSourcePatrimonio = [];
                                if (resp.data[0] != '' && resp.data[0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourcePatrimonio.push({
                                            IdPatrimonio: element.IdPatrimonio, Ano: element.Ano, Categoria: element.Categoria,
                                            Descricao: element.Descricao, DescricaoPatrimonio: element.DescricaoPatrimonio,
                                            IdEmpresa: element.IdEmpresa, IdPessoaFisica: element.IdPessoaFisica,
                                            Marca: element.Marca, Modelo: element.Modelo, Onus: element.Onus, ValorAtual: element.ValorAtual
                                        });
                                    });
                            });
                        });
                    }

                    else if (action == 'update')
                        this._cotasConsorciadoService.editPatrimonio(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getPatrimonio(this.IdPessoaFisica).then(resp => {
                                this.dataSourcePatrimonio = [];
                                resp.data[0][0].forEach(element => {
                                    this.dataSourcePatrimonio.push({
                                        IdPatrimonio: element.IdPatrimonio, Ano: element.Ano, Categoria: element.Categoria,
                                        Descricao: element.Descricao, DescricaoPatrimonio: element.DescricaoPatrimonio,
                                        IdEmpresa: element.IdEmpresa, IdPessoaFisica: element.IdPessoaFisica,
                                        Marca: element.Marca, Modelo: element.Modelo, Onus: element.Onus, ValorAtual: element.ValorAtual
                                    });
                                });
                            });
                        });

                    else {
                        this._cotasConsorciadoService.deletePatrimonio(response.getRawValue().id).then(a => {
                            this._cotasConsorciadoService.getPatrimonio(this.IdPessoaFisica).then(resp => {
                                this.dataSourcePatrimonio = [];
                                resp.data[0][0].forEach(element => {
                                    this.dataSourcePatrimonio.push({
                                        IdPatrimonio: element.IdPatrimonio, Ano: element.Ano, Categoria: element.Categoria,
                                        Descricao: element.Descricao, DescricaoPatrimonio: element.DescricaoPatrimonio,
                                        IdEmpresa: element.IdEmpresa, IdPessoaFisica: element.IdPessoaFisica,
                                        Marca: element.Marca, Modelo: element.Modelo, Onus: element.Onus, ValorAtual: element.ValorAtual
                                    });
                                });
                            });
                        });
                    }

                    break;

                case 'referenciasBancarias':

                    if (action == 'create') {

                        let data = response.getRawValue();

                        this._cotasConsorciadoService.addContasBancariasAvalista(data).then(a => {
                            this._cotasConsorciadoService.getContasBancarias(this.IdPessoaFisica).then(resp => {
                                this.dataSourceContaBancaria = [];
                                if (resp != null)
                                    resp.forEach(element => {
                                        this.dataSourceContaBancaria.push({
                                            IdReferenciaBancaria: element.IdReferenciaBancaria,
                                            Banco: element.Banco,
                                            Agencia: element.Agencia,
                                            Numero: element.Numero,
                                            Conta: element.Conta,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });
                        });
                    }

                    else if (action == 'update') {

                        let data = response.getRawValue();

                        this._cotasConsorciadoService.editContasBancarias(data).then(a => {

                            this._cotasConsorciadoService.getContasBancarias(this.IdPessoaFisica).then(resp => {
                                this.dataSourceContaBancaria = [];
                                if (resp != null)
                                    resp.forEach(element => {
                                        this.dataSourceContaBancaria.push({
                                            IdReferenciaBancaria: element.IdReferenciaBancaria,
                                            Banco: element.Banco,
                                            Agencia: element.Agencia,
                                            Numero: element.Numero,
                                            Conta: element.Conta,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });
                        });
                    }

                    else {
                        this._cotasConsorciadoService.deleteContasBancarias(response.getRawValue().id).then(a => {

                            this._cotasConsorciadoService.getContasBancarias(this.IdPessoaFisica).then(resp => {
                                this.dataSourceContaBancaria = [];
                                if (resp != null)
                                    resp.forEach(element => {
                                        this.dataSourceContaBancaria.push({
                                            IdReferenciaBancaria: element.IdReferenciaBancaria,
                                            Banco: element.Banco,
                                            Agencia: element.Agencia,
                                            Numero: element.Numero,
                                            Conta: element.Conta,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });
                        });
                    }

                    break;

                case 'referenciasPessoais':

                    if (action == 'create') {
                        this._cotasConsorciadoService.addReferenciasPessoaisAvalista(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getReferenciasPessoais(this.IdPessoaFisica).then(resp => {
                                this.dataSourceReferenciasPessoais = [];
                                if (resp.data[0][0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceReferenciasPessoais.push({
                                            IdReferenciaPessoal: element.IdReferenciaPessoal, Nome: element.Nome,
                                            Telefone: element.Telefone, Endereco: element.Endereco,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });
                        });
                    }

                    else if (action == 'update')
                        this._cotasConsorciadoService.editReferenciasPessoais(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getReferenciasPessoais(this.IdPessoaFisica).then(resp => {
                                this.dataSourceReferenciasPessoais = [];
                                if (resp.data[0][0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceReferenciasPessoais.push({
                                            IdReferenciaPessoal: element.IdReferenciaPessoal, Nome: element.Nome,
                                            Telefone: element.Telefone, Endereco: element.Endereco,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });
                        });

                    else {
                        this._cotasConsorciadoService.deleteReferenciasPessoais(response.getRawValue().id).then(a => {

                            this._cotasConsorciadoService.getReferenciasPessoais(this.IdPessoaFisica).then(resp => {
                                this.dataSourceReferenciasPessoais = [];
                                if (resp.data[0][0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceReferenciasPessoais.push({
                                            IdReferenciaPessoal: element.IdReferenciaPessoal, Nome: element.Nome,
                                            Telefone: element.Telefone, Endereco: element.Endereco,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });
                        });
                    }

                    break;

                case 'referenciasComerciais':

                    if (action == 'create') {
                        this._cotasConsorciadoService.addReferenciasComerciaisAvalista(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getReferenciasComerciais(this.IdPessoaFisica).then(resp => {
                                this.dataSourceReferenciasComerciais = [];
                                if (resp.data[0] != '' && resp.data[0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceReferenciasComerciais.push({
                                            IdReferenciaComercial: element.IdReferenciaComercial,
                                            Empresa: element.Empresa, Cidade: element.Cidade,
                                            ContatoSetor: element.ContatoSetor, Telefone: element.Telefone,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });

                        });
                    }
                    else if (action == 'update') {

                        this._cotasConsorciadoService.editReferenciasComerciais(response.getRawValue()).then(a => {

                            this._cotasConsorciadoService.getReferenciasComerciais(this.IdPessoaFisica).then(resp => {
                                this.dataSourceReferenciasComerciais = [];
                                if (resp.data[0] != '' && resp.data[0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceReferenciasComerciais.push({
                                            IdReferenciaComercial: element.IdReferenciaComercial,
                                            Empresa: element.Empresa, Cidade: element.Cidade,
                                            ContatoSetor: element.ContatoSetor, Telefone: element.Telefone,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });

                        });

                    }

                    else {

                        this._cotasConsorciadoService.deleteReferenciasComerciais(response.getRawValue().id).then(a => {

                            this._cotasConsorciadoService.getReferenciasComerciais(this.IdPessoaFisica).then(resp => {
                                this.dataSourceReferenciasComerciais = [];
                                if (resp.data[0] != '' && resp.data[0] != null)
                                    resp.data[0][0].forEach(element => {
                                        this.dataSourceReferenciasComerciais.push({
                                            IdReferenciaComercial: element.IdReferenciaComercial,
                                            Empresa: element.Empresa, Cidade: element.Cidade,
                                            ContatoSetor: element.ContatoSetor, Telefone: element.Telefone,
                                            IdEmpresa: element.IdEmpresa,
                                            IdPessoaFisica: element.IdPessoaFisica
                                        });
                                    });
                            });

                        });

                    }

                    break;

                case 'validation':
                    if (response) {
                        this.form.markAllAsTouched();
                        this.validateTables(source);
                    }

            }

        });

    }

    inputFileChange(event, document) {

        this.isReady = false;
        if (event.target.files && event.target.files[0].size <= 21000000) {

            const doc = event.target.files[0];

            const formData = new FormData();
            formData.append('UrlDocumento', doc);
            formData.append('NomeDocumento', document.NomeDocumento);
            formData.append('NomeOriginal', doc.name);
            // formData.append('IdPessoaFisica', document.IdPessoaFisica);
            // formData.append('IdPessoaJuridica', document.IdPessoaJuridica);
            formData.append('Size', doc.Size);
            formData.append('IdTipoDocumento', document.IdTipoDocumento);
            formData.append('IdCotaUsuario', this.idCota);
            formData.append('ExigeModelo', document.ExigeModelo);

            document.IdPessoaFisica == null ? formData.append('IdPessoaFisica', '') : formData.append('IdPessoaFisica', document.IdPessoaFisica);
            document.IdPessoaJuridica == null ? formData.append('IdPessoaJuridica', '') : formData.append('IdPessoaJuridica', document.IdPessoaJuridica);
            document.Url_Modelo === null ? formData.append('Url_Modelo', '') : formData.append('Url_Modelo', document.Url_Modelo);

            this._cotasConsorciadoService.addArquivo(formData).then((resp: any) => {
                if (resp.success) {
                    if (resp.data[0][0] != null) {
                        this.pesquisaDocumentos();
                    }

                }

            });

        }else{

            this.apiService.msg('Tamanho máximo permitido é de 20MB!', 3000)
            this.pesquisaDocumentos();
            // console.log("Tamanho máximo permitido é de 20MB");
            
        }
    }

    clearAnexo(cod) {
        this._cotasConsorciadoService.limparAnexo(cod).then(resp => {

            this.documentos.forEach(element => {
                if (element.IdDocumentoPessoa == cod)
                    element.UrlDocumento = '';
            });

            this.documentosAnalista.forEach(element => {
                if (element.IdDocumentoPessoa == cod)
                    element.UrlDocumento = '';
            });

        });

    }

    nomeAnexo(value) {
        return value.toUpperCase().replace(" ", "_") + "_FIADOR";
    }

    async verifyEstadoCivil(value) {
        if (value != 'CAS' && value != 'UNE' && value != 'CON' && value != 'DUE' && value != 'OUE' && value != 'SUE' && value != 'VUE') {

            await this.form.controls.TipoVinculo.setValidators([]);
            await this.form.controls.SocioProprietario.setValidators([]);
            await this.form.controls.EmpresaConjuge.setValidators([]);
            await this.form.controls.CnpjConjuge.setValidators([]);
            await this.form.controls.CargoConjuge.setValidators([]);
            await this.form.controls.DataAdmissao.setValidators([]);
            await this.form.controls.RendaMensalConjuge.setValidators([]);
            await this.form.controls.TelefoneComercial.setValidators([]);
            await this.form.controls.NomeConjuge.setValidators([]);
            await this.form.controls.CpfConjuge.setValidators([FuncoesService.ValidaCPFCNPJ]);
            await this.form.controls.RgConjuge.setValidators([]);
            await this.form.controls.PossuiRenda.setValidators([]);
            await this.form.controls.IdConjuge.setValidators([]);

            await this.form.controls.TipoVinculo.setValue('');
            await this.form.controls.SocioProprietario.setValue('');
            await this.form.controls.EmpresaConjuge.setValue('');
            await this.form.controls.CnpjConjuge.setValue('');
            await this.form.controls.CargoConjuge.setValue('');
            await this.form.controls.DataAdmissao.setValue('');
            await this.form.controls.RendaMensalConjuge.setValue('');
            await this.form.controls.TelefoneComercial.setValue('');
            await this.form.controls.NomeConjuge.setValue('');
            await this.form.controls.CpfConjuge.setValue('');
            await this.form.controls.RgConjuge.setValue('');
            await this.form.controls.PossuiRenda.setValue('');
            await this.form.controls.IdConjuge.setValue('');
        }
        else {

            await this.form.controls.TipoVinculo.setValidators(Validators.required);
            await this.form.controls.SocioProprietario.setValidators(Validators.required);
            await this.form.controls.EmpresaConjuge.setValidators(Validators.required);
            await this.form.controls.CnpjConjuge.setValidators([Validators.required, FuncoesService.ValidaCPFCNPJ]);
            await this.form.controls.CargoConjuge.setValidators(Validators.required);
            await this.form.controls.DataAdmissao.setValidators(Validators.required);
            await this.form.controls.RendaMensalConjuge.setValidators(Validators.required);
            await this.form.controls.TelefoneComercial.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(11)]);
            await this.form.controls.NomeConjuge.setValidators(Validators.required);
            await this.form.controls.CpfConjuge.setValidators([Validators.required, FuncoesService.ValidaCPFCNPJ]);
            await this.form.controls.RgConjuge.setValidators(Validators.required);
            await this.form.controls.PossuiRenda.setValidators(Validators.required);
            await this.form.controls.IdConjuge.setValidators(Validators.required);

        }
    }

    pickDate(value, item) {

        if (item == 'nscto')
            this.form.controls.DataNascimento.setValue(value);
        else if (item == 'abertura')
            this.form.controls.DataFundacao.setValue(value);
        else if (item == 'admissao')
            this.form.controls.RendaDataAdmissao.setValue(value);
        else if (item == 'admissaoConjuge')
            this.form.controls.DataAdmissao.setValue(value);
        else if (item == 'conta')
            this.form.controls.DataAbertura.setValue(value);

    }

    pesquisaDocumentos() {
       
        let SituacaoEmpregaticia = this.form.controls.SituacaoEmpregaticia.value == "" ? null : this.form.controls.SituacaoEmpregaticia.value;

        // this.validation= [];
        this.documentos = [];
        this.documentosAnexo = [];
        length = 0;

        let dados = {
            IdCotaUsuario: this.idCota,
            Situacao: 1,
            SituacaoEmpregaticia: SituacaoEmpregaticia,
            IdPessoa: this.idPessoaFisica, 
            CpfCnpj: null
            
        };

        this._cotasConsorciadoService.getDocumentosObrigatorios(dados).then((resultado: any) => {
            // this.documentosUsuario = resultado.data[0];
            let anexos = [];

            if (resultado.success) {
                if (resultado.data[0][0] != null) {
                    resultado.data[0].forEach(element => {
                        let dados = {
                            NomeDocumento: element.NomeDocumento,
                            IdTipoDocumento: element.IdTipoDocumento,
                            IdPessoaFisica: this.idPessoaFisica,
                            ExigeModelo: element.ExigeModelo,
                            Url_Modelo: element.Url_Modelo,
                            IdPessoaJuridica: null,
                            UrlDocumento: element.UrlDocumento
                        }

                        for (let i = 0; i < element.IdDocumentoPessoa.length; i++) {
                            anexos[length + i] = {
                                IdDocumentoPessoa: element.IdDocumentoPessoa[i],
                                UrlDocumento: element.UrlDocumento[i],
                                Size: element.Size[i],
                                NomeOriginal: element.NomeOriginal[i],
                                NomeDocumento: dados.NomeDocumento,
                                IdTipoDocumento: dados.IdTipoDocumento,
                                IdPessoaFisica: this.idPessoaFisica,
                                IdPessoaJuridica: null,
                                ExigeModelo: dados.ExigeModelo,
                                Url_Modelo: dados.Url_Modelo,
                                IdCotaUsuario: this.idCota
                            }
                            this.documentosAnexo.push(anexos[i]);
                        }
                        this.documentos.push(dados);
                    });
                    
                    if (this.documentos.length == resultado.data[0].length) {
                        setTimeout(() => {
                            this.isReady = true;
                        }, 3000);
                    }
                }
                else {
                    setTimeout(() => {
                        this.isReady = true;
                    }, 3000);
                }
            }
        })
    }

    inativaArquivo(IdDocumentoPessoa) {
        this.isReady = false;

        this._cotasConsorciadoService.inativaAnexo(IdDocumentoPessoa).then((res: any) => {
            if (res.success) {
                if (res.data[0] != null) {
                    this.pesquisaDocumentos();

                }
            }
        }, (error: any) => {
            console.log('erro anexo renover', error);

        });
    }


    fotoMensagem(){
        this.form.controls.Cpf.valid == false ?  this.mensagem = true :  this.mensagem = false;
    }

    infoBiometria(){
        this.dialogRef = this._matDialog.open(InfoBiometriaComponent,
          {});
      
        this.dialogRef.afterClosed();
      }

         
    getFolderSelected(event:any){
        this.folderSelected = event;
    }

    
}
