import { FuseUtils } from '@fuse/utils';
import { environment } from 'environments/environment';

export class Parametros
{
    EnviarEmail: string;
    EnviarApp: string;
    Smtp: string;
    EmailFrom: string;
    Usuario: string;
    Senha: string;
    Porta: string;
    Tipo: string;
    SimilaridadeDataValid: string;
    ProbabilidadeDataValid: string;
    LogDataValid: string;
    NotaCrivo: number;
    AtualizaDadosCadastrais: string;
    AgendarPagamentos: string;
    integracaoAutomatica: string;
    GuardiaoDigital: string;
    Detran: string;
    ExpiracaoCredito: number;
    NomeInstitucional: string;
    AssuntoAvalista: string;
    TextoAvalista: string;
    AssuntoVistoria: string;
    TextoVistoria: string;
    Dekra: string;
    TempoEnvioPush: number;
    TempoDuracaoPush: number;

    /**
     * Constructor
     *
     * @param parametros
     */
    constructor(parametros)
    {
        // Dados parametros-email        
            this.EnviarEmail = parametros.EnviarEmail || 'N';
            this.EnviarApp = parametros.EnviarApp || 'N';   
            this.Smtp = parametros.Smtp || '';
            this.EmailFrom = parametros.EmailFrom || '';
            this.Usuario = parametros.Usuario || '';
            this.Senha = parametros.Senha || '';
            this.Porta = parametros.Porta || '';
            this.Tipo = parametros.Tipo || 'TLS';
        // ---

        // Dados parametros-processos
            this.NotaCrivo = parametros.NotaCrivo || null;
            this.AtualizaDadosCadastrais = parametros.AtualizaDadosCadastrais || 'A';
            this.GuardiaoDigital = parametros.GuardiaoDigital || 'A';
            this.AgendarPagamentos = parametros.AgendarPagamentos || 'A';
            this.Detran = parametros.Detran || 'A';
            this.Dekra = parametros.Dekra || '';
            this.ExpiracaoCredito = parametros.ExpiracaoCredito || null;
            this.NomeInstitucional = parametros.NomeInstitucional || '';
            this.AssuntoAvalista = parametros.AssuntoAvalista || '';
            this.TextoAvalista = parametros.TextoAvalista || '';
            this.AssuntoVistoria = parametros.AssuntoVistoria || '';
            this.TextoVistoria = parametros.TextoVistoria || '';
            this.SimilaridadeDataValid = parametros.SimilaridadeDataValid || '';
            this.ProbabilidadeDataValid = parametros.ProbabilidadeDataValid || 'B';
            this.LogDataValid = parametros.LogDataValid || '';
            this.TempoEnvioPush = parametros.TempoEnvioPush || null;
            this.TempoDuracaoPush = parametros.TempoDuracaoPush || null;
        // ---
    }
}