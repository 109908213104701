// ATENÇÃO: O projeto sempre é buildado com --configuration=ambiente nos ambientes, atualizar os mesmo e testar localmente com os  comandos
// ng build --configuration=teste
// ng build --configuration=hom
// ng build --configuration=prod
// Somente `ng build` irá pegar o environment.ts

import { routes } from './routes';

export const environment = {
  production: true,
  hmr: false,

  api_url: 'https://teste-api-esteiracredito.bsntecnologia.com.br',

  api_headers: {
    'token': 'f57ee7fb-04c7-4472-ac43-c712bda5092f',
    'secret': 'd1cd9059-a341-44a3-a4fd-a45a55a5c1c4',
    'Access-Control-Allow-Origin': '*',
    'Accept': '*/*',
    'TokenLogin': ""
  },

  ...routes,

};